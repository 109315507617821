import React, { Component, Fragment } from 'react';
import TableFilter from './../TableFilter';
import ClubService from "../../../../../services/api/club";
import GhinTable from "../../../../shared/GhinTable";
import Modal from "react-modal";
import { connect } from "react-redux";
import GolferService from '../../../../../services/api/golfer';
import HandicapReviewDetailsModal from '../../../../Modals/HandicapReviewDetailsModal';
import { getPermission } from '../../../../../permissionManager';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import ConfirmationModal from '../../../../Modals/ConfirmationModal';
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import moment from 'moment'
import CountUp from 'react-countup';
import ExportDataModal from '../../../../Modals/ExportDataModal';
import { addAlert } from '../../../../shared/actions';

class FullResultsTable extends Component {
    constructor() {
        super();

        this.state = {
            tableFilterIsHidden: false,
            filters: [
                { id: "name", value: "" },
                { id: "gender", value: "" },
                { id: "status", value: "" }
            ],
            sorted: [{ id: "recommendation_applied", desc: true }],
            isAllSelected: false,
            golfers: [],
            selectedGolfers: [],
            selectedHandicapReviews: [],
            page: 0,
            perPage: 10,
            total: 10,
            pages: 1,
            handicapReviews: [],
            handicapReviewHistory: null,
            handicapReviewDetailsModalIsOpen: false,
            selectedHandicapReview: null,
            golferId: null,
            golfer: null,
            isExportDataOpened: false,
            numberOfReviewsDone: 0,
            numberOfReviewsRunning: 0,
            handicapReviewConfirmationModalIsOpen: false,
            handicapReviewAction: '',
            loading: true,
            isStatusRefreshing: true,
            completedHRsPercentage: 0
        };

        this.openHandicapReviewDetailsModal = this.openHandicapReviewDetailsModal.bind(this);
        this.applySelectedHandicapReviews = this.applySelectedHandicapReviews.bind(this);
        this.denySelectedHandicapReviews = this.denySelectedHandicapReviews.bind(this);
    }

    componentDidMount() {
        this.reloadHandicapReviews();
    }

    componentDidUpdate(prevProps) {
        if (this.props.updateRosterList !== prevProps.updateRosterList) {
            this.reloadHandicapReviews();
        }
    }

    golferPageUrl(golferId) {
        return `${this.props.match.url.split('/').slice(0, -3).join('/')}/golfer/${golferId}`
    }

    openHandicapReviewDetailsModal(handicapReview) {
        GolferService.showGolfer(this.props.club.id, handicapReview.golfer_id)
            .then(
                (golfer_data) => {
                    this.setState({
                        handicapReviewDetailsModalIsOpen: true,
                        selectedHandicapReview: handicapReview,
                        golferId: handicapReview.golfer_id,
                        golfer: golfer_data['golfers'],
                    })
                }
            )
    }

    openHandicapReviewConfirmationModal(action) {
        this.setState({
            handicapReviewConfirmationModalIsOpen: true,
            handicapReviewAction: action
        })
    }

    closeModal(reload) {
        this.setState({
            handicapReviewDetailsModalIsOpen: false,
            handicapReviewConfirmationModalIsOpen: false,
            handicapReviewAction: ''
        })

        if (reload) this.reloadHandicapReviews();
    }

    reloadHandicapReviews() {
        this.setState({
            loading: true,
            isStatusRefreshing: true,
            handicapReviews: [],
            selectedHandicapReviews: [],
            isAllSelected: false
        });

        const params = this.getRequestParams();

        ClubService.getHandicapReviews(this.props.club.id, params).then((data) => {
            const runningHrsCount = data['handicap_review'].filter((review) => review.status === 'Running').length
            const doneHrsCount = data['handicap_review'].filter((review) => review.status === 'Done').length

            this.setState({
                loading: false,
                numberOfReviewsRunning: runningHrsCount,
                numberOfReviewsDone: doneHrsCount,
                handicapReviews: data['handicap_review'],
                total: parseInt(data['total-count'], 10),
                perPage: parseInt(data['per-page'], 10),
                pages: parseInt(data['total-pages'], 10),
            });
        });

        ClubService.getHandicapReviewHistory(this.props.club.id, this.props.match.params.handicap_review_history_id, params).then((data) => {
            this.setState({
                isStatusRefreshing: false,
                handicapReviewHistory: data['handicap_review_history'],
                completedHRsPercentage: data['handicap_review_history']['percentage_handicap_review_done']
            });
        });
    }

    getRequestParams() {
        let params = {
            page: (this.state.page + 1),
            perPage: this.state.perPage
        };

        if (this.state.sorted.length > 0) {
            params['sorting_criteria'] = this.state.sorted[0].id;
            params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
        }

        this.state.filters.forEach((filter) => {
            if (Array.isArray(filter.value)) {
                const values = filter.value.map(value => value.value).join(",");
                params[filter.id] = values;
            } else if (filter.value.constructor.name === "Object") {
                params[filter.id] = filter.value.value;
            } else {
                params[filter.id] = filter.value;
            }
        });

        if (this.props.match.params.handicap_review_history_id !== null) {
            params['handicap_review_history_id'] = this.props.match.params.handicap_review_history_id
        }

        return params;
    }

    toggleTableFilter = () => this.setState({ tableFilterIsHidden: !this.state.tableFilterIsHidden });

    onSelectRow(row) {
        let selectedHandicapReviews = [...this.state.selectedHandicapReviews];
        let isAllSelected = this.state.isAllSelected;

        let isSelected = true;

        if (selectedHandicapReviews.includes(row.id)) {
            selectedHandicapReviews.splice(selectedHandicapReviews.indexOf(row.id), 1);
            isSelected = false;
        } else {
            selectedHandicapReviews.push(row.id);
        }

        let handicapReviews = [...this.state.handicapReviews].map((i) => {
            if (i.id === row.id) {
                return {
                    ...i,
                    selected: isSelected
                }
            } else {
                return i;
            }
        });

        isAllSelected = selectedHandicapReviews.length === handicapReviews.length

        // Get all uniq golfer IDs
        let selectedGolfers = [...new Set(selectedHandicapReviews.map((hr) => hr.golfer_id))]

        this.setState({
            selectedGolfers: selectedGolfers,
            handicapReviews: handicapReviews,
            selectedHandicapReviews: selectedHandicapReviews,
            isAllSelected: isAllSelected
        });
    }

    onSelectAll() {
        const isAllSelected = !this.state.isAllSelected;
        const handicapReviews = [...this.state.handicapReviews].map((hr) => ({ ...hr, selected: isAllSelected }));

        this.setState({
            selectedGolfers: [],
            selectedHandicapReviews: [],
            handicapReviews: handicapReviews,
            isAllSelected: isAllSelected,
        })
    }

    onSelectPage() {
        let handicapReviews = [...this.state.handicapReviews];
        let selectedHandicapReviews = [...this.state.selectedHandicapReviews];
        let isAllSelected = this.state.isAllSelected;

        const golfersAlreadySelected = handicapReviews.filter((hr) => selectedHandicapReviews.includes(hr.id));

        if (golfersAlreadySelected.length === this.state.handicapReviews.length && golfersAlreadySelected.length > 0) {
            selectedHandicapReviews = selectedHandicapReviews.filter((selectedGolferId) => !handicapReviews.map((c) => c.id).includes(selectedGolferId));
        } else {
            selectedHandicapReviews = [...new Set([...this.state.selectedHandicapReviews, ...handicapReviews.map((hr) => hr.id)])];
        }

        handicapReviews = handicapReviews.map((hr) => ({ ...hr, selected: selectedHandicapReviews.includes(hr.id) }));

        isAllSelected = selectedHandicapReviews.length === handicapReviews.length

        // Get all uniq golfer IDs
        let selectedGolfers = [...new Set(selectedHandicapReviews.map((hr) => hr.golfer_id))]

        this.setState({
            selectedGolfers: selectedGolfers,
            handicapReviews: handicapReviews,
            selectedHandicapReviews: selectedHandicapReviews,
            isAllSelected: isAllSelected
        });
    }

    applySelectedHandicapReviews(golfers) {
        const selectedGolfersIds = (golfers === undefined || golfers.length === 0) ? this.getSelectedGolfersIds() : golfers

        ClubService.applyHandicapReviewRecommendationForClub(
            this.props.club.id, selectedGolfersIds, {}
        ).then(() => {
            this.closeModal()
            this.reloadHandicapReviews();
        })
    }

    denySelectedHandicapReviews(golfers) {
        const selectedGolfersIds = (golfers === undefined || golfers.length === 0) ? this.getSelectedGolfersIds() : golfers

        ClubService.denyHandicapReviewRecommendationForClub(
            this.props.club.id, selectedGolfersIds, {}
        ).then(() => {
            this.closeModal()
            this.reloadHandicapReviews();
        })
    }

    getSelectedGolfersIds() {
        return this.state.handicapReviews.map((hr) => (this.state.selectedHandicapReviews.includes(hr.id) && hr.recommendation_applied == null) ? hr.golfer_id : null)
            .filter((golfer_id) => golfer_id != null)
    }

    formatDate(dateString) {
        const date = new Date(dateString);

        const dateOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };

        const timeOptions = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        };

        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        return [formattedDate, formattedTime]
    }

    formattedReviewPeriod(handicapReviewHistory) {
        let startDate = moment(handicapReviewHistory.review_start_date).format('MM/DD/YYYY')
        let endDate = moment(handicapReviewHistory.review_end_date).format('MM/DD/YYYY')
        return `${startDate} - ${endDate}`;
    }

    isAnySelected() {
        if (this.state.isAllSelected) return true
        return this.state.selectedHandicapReviews.length !== 0
    }

    showExportModal = () => {
		this.setState({
			isExportDataOpened: true
		})
	};

    parseRecommendedAdjustment(review)
    {
        if(!review.is_eligible) return '';

        return (
            review.recommended_adjustment > 0 ?
            '+ ' + review.recommended_adjustment :
            review.recommended_adjustment < 0 ?
                '- ' + Math.abs(review.recommended_adjustment) :
                review.recommended_adjustment
        )
    }

    parseStatus(review)
    {
        if(review.is_expired) return 'Expired'
        else if(review.is_eligible === false) return 'Ineligible'
        else
        {
            if(review.recommendation_applied === null) return 'Pending'
            if(review.recommendation_applied === false && review.recommended_adjustment === 0) return 'No Adj.'
            if(review.recommendation_applied === true) return 'Applied'
            if(review.recommendation_applied === false) return 'Denied'
        }
    }

    reviewHeader()
    {
        return(
            <div style={{ "marginTop": "30px", "marginBottom": "30px" }}>
                <NavLink className="btn lnk" style={{ "fontSize": "12px", "color": "rgba(0, 54, 95, 1)", "marginBottom": "10px" }} to={`${this.props.match.url.split('/').slice(0, -2).join('/')}`}>
                    <span>&#8592;</span> History
                </NavLink>
                {
                    this.state.handicapReviewHistory &&
                    <div style={{ "display": "flex", "flexDirection": "row", alignItems:'center'}}>
                        <div style={{ "fontSize": "28px", "marginBottom": "10px" }}>
                            Handicap Review Results
                        </div>
                        <div style={{ borderLeft: "1px solid rgba(172, 172, 172, 1)", height: "50px", marginRight: "15px", marginLeft: "15px" }}></div>
                        <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "space-around", gap:'11px'}}>
                            <div style={{ "display": "flex", "flexDirection": "row", "fontSize": "16px" }}>
                                <div style={{ "marginRight": "37px" }}><strong>Review Run</strong></div>
                                <div style={{ "marginRight": "10px" }}>{this.formatDate(this.state.handicapReviewHistory.review_date)[0]}</div>
                                <div>{this.formatDate(this.state.handicapReviewHistory.review_date)[1]}</div>
                            </div>
                            <div style={{ "display": "flex", "flexDirection": "row", "fontSize": "16px" }}>
                                <div style={{ "marginRight": "20px" }}><strong>Review Period</strong></div>
                                <div>{this.formattedReviewPeriod(this.state.handicapReviewHistory)}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    reviewFilters()
    {
        return(
            <TableFilter
                club_id={this.props.match.params.id}
                hideFilters={this.state.tableFilterIsHidden}
                updateFiltered={filters => this.setState({ filters, page: 0 }, () => { this.reloadHandicapReviews() })}
            />
        )
    }

    reviewTable(columns)
    {
        return(
            <Fragment>
                <div className="pre__table">
                    <div className="row">
                        <div className="col auto">
                            {getPermission(this.props.user_type, 'edit-club') &&
                                <ul className="action__list push-left">
                                    <li>
                                        <button className="btn fill green" onClick={this.showExportModal}>
                                            Export to Excel
                                        </button>
                                    </li>
                                    <li>
                                        <button className="btn fill green"
                                            disabled={!this.isAnySelected()}
                                            onClick={() => this.openHandicapReviewConfirmationModal('Apply')}>
                                            Apply
                                        </button>
                                    </li>
                                    <li>
                                        <button className="btn fill green"
                                            disabled={!this.isAnySelected()}
                                            onClick={() => this.openHandicapReviewConfirmationModal('Deny')}>
                                            Deny
                                        </button>
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="col auto push-right">
                            <div className="row">
                                {
                                    this.state.handicapReviewHistory &&
                                    <div className="col">
                                        <ul className="association__status">
                                            <li>Adjustment Recommended = {this.state.handicapReviewHistory.total_adjustments_recommended}</li>
                                            <li>No Adjustment Recommended = {this.state.handicapReviewHistory.total_no_recommendation}</li>
                                            <li>Ineligible for Review = {this.state.handicapReviewHistory.number_of_ineligible_golfers}</li>
                                        </ul>
                                    </div>
                                }
                                <div className="col auto">
                                    {!this.state.tableFilterIsHidden &&
                                        <button
                                            className="btn fill gray"
                                            onClick={this.toggleTableFilter.bind(this)} >
                                            Hide Filters <i className="material-icons-outlined">remove</i>
                                        </button>
                                    }
                                    {this.state.tableFilterIsHidden &&
                                        <button
                                            className="btn fill gray"
                                            onClick={this.toggleTableFilter.bind(this)} >
                                            Show Filters <i className="material-icons-outlined">add</i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GhinTable
                    loading={this.state.loading}
                    columns={columns}
                    pages={this.state.pages}
                    page={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.perPage}
                    isAllSelected={this.state.isAllSelected}
                    data={this.state.handicapReviews}
                    ref={(r) => this.table = r}
                    manual // - if true, all table functions like pagination, sorting, and filtering are handled manually (often server-side)
                    // hideSelect // - if present, hides the selection box 
                    onRowSelect={this.onSelectRow.bind(this)}
                    onSelectAll={this.onSelectAll.bind(this)}
                    onSelectPage={this.onSelectPage.bind(this)}
                    onPageChange={(pageIndex) => this.setState({ page: pageIndex }, () => this.reloadHandicapReviews())}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        perPage: pageSize,
                        page: pageIndex
                    }, () => this.reloadHandicapReviews())}
                    sortable={true}
                    onSortedChange={(newSorted, column, shiftKey) => this.setState({sorted: newSorted}, () => this.reloadHandicapReviews())}
                    hideCheckboxFor={{field: 'recommendation_applied', values: [false, true]}}
                />
            </Fragment>
        )
    }

    progressBar()
    {
        const data = [
            {
                name: 'completed',
                percentage: this.state.completedHRsPercentage
            }
        ];

        return(
            <Fragment>
                <div style={{fontSize: '24px', fontWeight: 700, lineHeight: '32px', textAlign: 'center'}}>
                    <CountUp
                        end={this.state.completedHRsPercentage}
                        duration={1}
                        suffix="%"
                    />
                </div>
                <div style={{width:'163px', height:'30px'}}>
                    <ResponsiveContainer>
                        <BarChart
                            width={110}
                            height={110}
                            data={data}
                            layout="vertical"
                            margin={{
                            top: 20,
                            right: 0,
                            left: 0,
                            bottom: 0
                            }}
                            barSize={10}
                        >
                            <XAxis type="number" domain={[0, 100]} hide={true} />
                            <YAxis dataKey="name" type="category" hide={true} />
                            <Bar
                            dataKey="percentage"
                            minPointSize={2}
                            radius={20}
                            background={{ fill: '#D9D9D9', radius: 20 }}
                            >
                            {
                                data.map((entry, index) => {
                                const color = entry.name === 'expected' ? '#9ECDE4' : '#0089CE';
                                return <Cell key={`cell-${index}`} fill={color}/>;
                                })
                            }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Fragment>
        )
    }

    loadingPage()
    {
        return(
            this.state.handicapReviewHistory &&
            <div style={{
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '600px'
              }}>
                <div style={{textAlign: 'center', marginBottom: "20px", width: "300px", fontSize: '16px', fontWeight: 700, lineHeight: '24px', color: "#783b15"}}>
                    Click the "Refresh Status & Finalize" button to see the Handicap Review results!
                </div>
                <div style={{height: '50px', width:'50px', marginBottom:'20px'}} className='container__loader_spinner'>
			        <span className="image__loader_spinner"></span>
		        </div>
                {/* PHASE III */}
                {/* <div style={{fontSize: '24px', fontWeight: 700, lineHeight: '32px', textAlign: 'center'}}>
                    Running Handicap Reviews on {this.state.handicapReviewHistory?.number_of_eligible_golfers} golfers...
                </div> */}
                <div style={{fontSize: '24px', fontWeight: 700, lineHeight: '32px', textAlign: 'center'}}>
                    Handicap Review in progress
                </div>
                <button
                    style={{ width: "240px", height: "44px", borderRadius: "8px", textAlign: "left", marginTop:'16px'}}
                    onClick={() => this.reloadHandicapReviews()}
                    disabled={this.state.isStatusRefreshing}
                >
                    <span style={{ fontSize: '16px', fontWeight: 700, lineHeight: '24px', color: '#344054' }}><i className="material-icons-outlined v-mid m-left small">cached</i> Refresh Status & Finalize</span>
                </button>
                {/* PHASE III */}
                {/* <div style={{fontSize: '18px', fontWeight: 500, lineHeight: '24px', textAlign: 'center', color: '#6A6C6F'}}>
                    We'll let you know when the results are ready.
                </div> */}
                <div style={{marginTop:'73px'}}>
                    <div>
                        {this.progressBar()}
                    </div>
                    <br/>
                    <div style={{fontSize: '16px', fontWeight: 500, lineHeight: '24px', textAlign: 'center', color: '#6A6C6F'}}>
                        Last updated:
                    </div>
                    <div style={{fontSize: '16px', fontWeight: 500, lineHeight: '24px', textAlign: 'center', color: '#6A6C6F'}}>
                        {moment(this.state.handicapReviewHistory.updated_at).format('hh:mm:ss a')}
                    </div>
                </div>
            </div>
        )
    }

    generateGolferName(row) {
        return row.golfer_last_name + (row.golfer_suffix ? ' ' + row.golfer_suffix + ', ' : ', ') + (row.golfer_prefix ? row.golfer_prefix + ' ' : '') + row.golfer_first_name + ' ' + (row.golfer_middle_name ? row.golfer_middle_name : '');
    };

    render() {
        let su = this.props.user_type === "super-admin"
  
        const columns = [
            {
                Header: '#',
                accessor: 'id',
                colClassName: 'col_id',
                show: false

            },
            {
                Header: 'Golfer ID',
                accessor: 'golfer_id',
                show: false
            },
            {
				Header: 'golfer_suffix',
				accessor: 'golfer_suffix',
				show: false
			},
			{
				Header: 'golfer_prefix',
				accessor: 'golfer_prefix',
				show: false
			},
			{
				Header: 'golfer_middle_name',
				accessor: 'golfer_middle_name',
				show: false
			},
			{
				Header: 'golfer_first_name',
				accessor: 'golfer_first_name',
				show: false
			},
            {
                Header: 'Golfer Name',
                accessor: 'golfer_last_name',
                Cell: props => (<Fragment>
                    {(su || !this.props.club.is_trial) ?
                        <NavLink to={this.golferPageUrl(props.original.golfer_id)}>{this.generateGolferName(props.row)}</NavLink>
                    : 
                        this.generateGolferName(props.row)
                    }
                </Fragment>),
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: <div>Recommended<br />Adjustment</div>,
                accessor: 'recommended_adjustment',
                Cell: props => (
                    this.parseRecommendedAdjustment(props.original)
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Status',
                accessor: 'recommendation_applied',
                Cell: props => (
                    this.parseStatus(props.original)
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Applied by',
                accessor: 'applied_by',
                Cell: props => (
                    (props.original.recommendation_applied == null || props.original.is_eligible === false || props.original.is_expired || props.original.recommended_adjustment === 0) ?
                        '-' : props.original.applied_by
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Date Applied',
                accessor: 'updated_at',
                Cell: props => {
                  const date_applied = this.formatDate(props.original.updated_at)
                  
                  return (props.original.recommendation_applied == null || props.original.is_eligible === false ||
                    props.original.is_expired || props.original.recommended_adjustment === 0) ?
                    '-' : `${date_applied[0]} ${date_applied[1]}`
                },
                className: 'table-align-center',
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: 'Expiration Date',
                accessor: 'expiration_date',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.expiration_date
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: 'Scores Posted',
                accessor: 'number_of_scores',
                Cell: props => (
                    props.original.number_of_scores == null ?
                        '-' : props.original.number_of_scores
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'High Flags',
                accessor: 'number_of_flags_high',
                Cell: props => (
                    props.original.number_of_flags_high == null ?
                    '-' : props.original.number_of_flags_high + ' (' + props.original.percentage_high.toFixed(1) + '%)'
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Low Flags',
                accessor: 'number_of_flags_low',
                Cell: props => (
                    props.original.number_of_flags_low == null ?
                    '-' : props.original.number_of_flags_low + ' (' + props.original.percentage_low.toFixed(1) + '%)'
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'View Details',
                Cell: props => (
                    props.original.can_show_details_button &&
                    <span className='col_edit-delete'>
                        <button
                            className={'btn lnk'}
                            onClick={() => {
                                this.openHandicapReviewDetailsModal(props.original)
                            }}
                        >
                            Details
                        </button>
                    </span>
                    ),
                sortable: false,
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
        ];

        return (
            <Fragment>
                {this.reviewHeader()}
                {this.state.completedHRsPercentage !== 100 ? 
                    this.loadingPage()
                     :
                    <Fragment>
                        {this.reviewFilters()}
                        {this.reviewTable(columns)}
                    </Fragment>
                }
                <Modal
                    isOpen={this.state.handicapReviewDetailsModalIsOpen}
                    onRequestClose={() => { this.closeModal() }}
                    contentLabel="Modal"
                    portalClassName="modal"
                    overlayClassName="modal__overlay"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={false}
                >
                    <HandicapReviewDetailsModal
                        review={this.state.selectedHandicapReview}
                        golfer={this.state.golfer}
                        applyHandicapReview={() => { this.applySelectedHandicapReviews([this.state.golferId]) }}
                        denyHandicapReview={() => { this.denySelectedHandicapReviews([this.state.golferId]) }}
                        closeModal={() => { this.closeModal() }}
                        canEditClub={getPermission(this.props.user_type, 'edit-club')}
                        url={`${this.golferPageUrl(this.state.golferId)}/handicap-management`}
                    />
                </Modal>
                <ConfirmationModal
					openModal={this.state.handicapReviewConfirmationModalIsOpen}
					onConfirmAction={() => {
                        this.state.handicapReviewAction === 'Apply' ? this.applySelectedHandicapReviews() : this.denySelectedHandicapReviews()
                    }}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={this.state.handicapReviewAction + ' Adjustment'}
                    bodyQuestion =
                        <div style={{ textAlign: 'center', color: 'black' }}>
                          Are you sure you want to {this.state.handicapReviewAction.toLowerCase()} the recommended<br />
                          adjustment for the selected golfer(s)?
                        </div>
					cancelLabel={"Cancel"}
					confirmLabel={"Confirm"}
				/>
                <ExportDataModal
					columns={columns}
					isOpen={this.state.isExportDataOpened}
					isAllSelected={this.state.isAllSelected}
					onRequestClose={() => this.setState({ isExportDataOpened: false })}
					data={this.state.golfers}
					// disableSelected={disableSelectedExport}
                    disableSelected={true}
					onDownloadClick={this.handleDownload.bind(this)}
				/>
            </Fragment>
        );
    }

    handleDownload(selectionType) {
        // Close the modal
		this.setState({ isExportDataOpened: false });

		let params = {
			...this.getRequestParams(),
		};
		delete params.page;
		delete params.perPage;

		// if (selectionType === "selected") {
		// 	if (this.state.isAllSelected) {
		// 		params['golfer_ids'] = []
		// 	} else {
		// 		params['golfer_ids'] = this.state.selectedGolfers.join(",");
		// 	}
		// }

		return ClubService.exportHandicapReviewHistoriesToExcel(
            this.props.club.id,
            this.props.match.params.handicap_review_history_id,
            params
        ).then(response => {
			window.open(response.url, "_blank")
		}).catch(error => {

        });
	}
}

function mapStateToProps(state) {
    return {
        user_type: state.user.access.user_type
    };
}

export default connect(mapStateToProps, null)(FullResultsTable);
