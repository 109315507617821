import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import moment from 'moment';
import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import Footer from '../shared/Footer';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';

import HandicapManagement from './HandicapManagement';
import ScoreMaintenance from './ScoreMaintenance';
import PostAScore from './PostAScore';
import Account from './Account/';

import AuditLog from './AuditLog/';
import GolferService from '../../services/api/golfer';
import {connect} from "react-redux";
import {getPermission, getAllAccesses, checkAccess} from "../../permissionManager";
import PageHead from "../shared/PageHead";
import ReactTooltip from "react-tooltip";
import GolferServices from "../../services/api/golfer";
import NotFound from "../NotFound/NotFound";
import Loader from "../NotFound/Loader";
import HandicapReview from './HandicapReview';


class Golfer extends Component {

    constructor(props) {

        super(props);

        this.state = {
        	golfer: null,
            AddGolferModalIsOpen: false,
            AddClubModalIsOpen: false,
            AddAssocModalIsOpen: false,
			isLoading: true
        };

        this.openGolferAddModal = this.openGolferAddModal.bind(this);
        this.openClubAddModal = this.openClubAddModal.bind(this);
        this.openAssocAddModal = this.openAssocAddModal.bind(this);

        this.closeModal = this.closeModal.bind(this);

    }

    openGolferAddModal() {
        this.setState({AddGolferModalIsOpen: true});
    }

    openClubAddModal() {
        this.setState({AddClubModalIsOpen: true});
    }

    openAssocAddModal() {
        this.setState({AddAssocModalIsOpen: true});
    }

    componentDidMount() {
		this.showGolfer();
	}

	showGolfer() {
		let ghin = false;
		if(this.props.match.params.golfer_id.match(/^[0-9]*$/)) {
			ghin = this.props.match.params.golfer_id;
		} else {
			ghin = window.atob(this.props.match.params.golfer_id);
		}
		GolferService.showGolfer(this.props.match.params.id, ghin)
			.then( res => {
				if(res.golfers){
					GolferServices.getAffiliations(ghin)
						.then( response => {
							this.setState({
								golfer: {
									...res.golfers,
									...{
										private_id: res.golfers.id,
										player_name: (res.golfers.prefix ? res.golfers.prefix : '') +
											' ' +
											res.golfers.first_name +
											' ' +
											(res.golfers.middle_name ? res.golfers.middle_name : '') +
											' ' + res.golfers.last_name +
											' ' +
											(res.golfers.suffix ? res.golfers.suffix : '')
									},
								},
								active: response.club_affiliations.filter(membership => membership.status === "Active").length > 0,
								publicView: !res.golfers.entitlement,
								isLoading: false
							})
						})
						.catch( err => {
							this.setState({
								golfer: {
									...res.golfers,
									...{
										private_id: res.golfers.id,
										player_name: (res.golfers.prefix ? res.golfers.prefix : '') +
											' ' +
											res.golfers.first_name +
											' ' +
											(res.golfers.middle_name ? res.golfers.middle_name : '') +
											' ' + res.golfers.last_name +
											' ' +
											(res.golfers.suffix ? res.golfers.suffix : '')
									}
								},
								active: false,
								publicView: !res.golfers.entitlement,
								isLoading: false
							})
							console.log(err)
						})

				}

			})
			.catch( err => {console.error(err); this.setState({isLoading: false});})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
    	if(prevProps.match.params.golfer_id !== this.props.match.params.golfer_id){
			this.showGolfer()
		}
	}

	updateGolfer(data) {
		this.setState({
			golfer: {
				...data,
				...{player_name:
						(data.prefix ? data.prefix : '') +
						' ' +
						data.first_name +
						' ' +
						(data.middle_name ? data.middle_name : '') +
						' ' +
						data.last_name +
						' ' +
						(data.suffix ? data.suffix : '')
				}
			},
			isLoading: false
		})
	}

	closeModal(data) {
		if(data && data.type === "golfer") {
			this.props.history.push(`/manage/association/${data.golfer.association_id}/club/${data.golfer.club_id}/golfer/${data.golfer.id}`);
		}
		if(data && data.type === "club"){
			this.props.history.push(`/manage/association/${data.club.club.golf_association_id}/club/${data.club.club.id}/account`);
		}
        this.setState({
            AddGolferModalIsOpen: false,
            AddClubModalIsOpen: false,
            AddAssocModalIsOpen: false
        });
    }

	render() {
		if (this.state.isLoading) return <Loader />
		if (!this.state.golfer) return <NotFound />
		if (this.state.golfer.is_trial && this.props.user_type != 'super-admin')
			return <NotFound />;
		//todo: should i trust is_minor?
		const golferAgeInYears = this.state.golfer.date_of_birth && moment().diff(moment(this.state.golfer.date_of_birth).format(), 'years')
		const isJuniorGolfer = golferAgeInYears >= 13 && golferAgeInYears <= 19;
		const golferIsMinorWithoutGuardian = this.state.golfer.is_minor &&  !this.state.golfer.has_guardians && !isJuniorGolfer

    const user_access = getAllAccesses(this.props.rawAccess);
    const canSeeAssociationName = (checkAccess(user_access, this.state.golfer.association_id, null) || this.props.user_type === 'super-admin') && !this.state.publicView;
    const canSeeClubName = (checkAccess(user_access, null, this.state.golfer.club_id) || this.props.user_type === 'super-admin') && !this.state.publicView;
    const canAccessHandicapReview = process.env.REACT_APP_TYPE !== "hv" && !this.state.publicView &&
      (getPermission(this.props.user_type, 'edit-club') || 
        getPermission(this.props.user_type, 'read-club'))
    return (
      <Fragment>
          <Header/>
          <Navigation/>
          <BreadCrumbsNav canSeeAssociationName={canSeeAssociationName}
              canSeeClubName={canSeeClubName}
              association={{name: this.state.golfer.association_name}}
              club={{club_name: this.state.golfer.club_name}}
              publicView={this.state.publicView}
              golfer_encoded_id={this.props.match.params.golfer_id}
              golfer={this.state.golfer.player_name}
          />
          <main>
            <div className="container">
							<PageHead 
                trial_golfer={this.state.golfer.is_trial} 
                title={this.state.golfer.player_name} 
                publicView={this.state.publicView} 
                showGolfer={() => this.showGolfer()} 
                association={{id:this.props.match.params.association_id}} 
                club={{id: this.props.match.params.id, club_name: this.state.golfer.club_name}}
              />
              <div className="page__sub__head">
                <ul className="golfer__data">
									<li>GHIN Number: <strong>{this.state.golfer.id}</strong></li>
									  {process.env.REACT_APP_TYPE !== "hv" && !this.state.publicView && getPermission(this.props.user_type, 'edit-club') && golferIsMinorWithoutGuardian ?
                      <li>
                        <span style={{color: '#c9243f'}}><strong>Alert:</strong> A guardian is required for minor golfers. Please add a guardian for this golfer from within the Account tab.</span>
                      </li> : null
                    }
                    { this.state.golfer.email ? <li><a href={`mailto:${this.state.golfer.email}`}>{this.state.golfer.email}</a></li> : null}
                </ul>
              </div>
              <ul className="page__tabs">
								<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/handicap-management`}>Handicap Management</NavLink></li>
								<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/score-maintenance`}>{(this.state.publicView || !this.state.active) ? 'Score List' : 'Score Maintenance'}</NavLink></li>
								{process.env.REACT_APP_TYPE !== "hv" && !this.state.publicView && this.state.active && getPermission(this.props.user_type, 'manage-scores') && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/post-a-score`}>Post a Score</NavLink></li>}
								<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/account`}>Profile</NavLink></li>
                {canAccessHandicapReview && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/handicap-review`}>Handicap Review</NavLink></li>}
								{process.env.REACT_APP_TYPE !== "hv" && !this.state.publicView && getPermission(this.props.user_type, 'edit-club') &&  <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/audit-log`}>Audit Log</NavLink></li>}
                <li className="golfer__badge">
									{
                    (this.state.golfer.hi_modified || this.state.golfer.low_hi_modified || this.state.golfer.soft_cap || this.state.golfer.hard_cap) &&
                    !this.state.golfer.hi_withdrawn &&
                    <div className={"info"}>
                      <a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='hi_modified'>
                        <i className="material-icons v-mid small red">info</i>
                      </a>
                      <ReactTooltip id='hi_modified' className="table-head__tooltip type-white" type="light" place='top' effect='float'>
                        {this.state.golfer.hi_modified && <li style={{listStyleType: "disc"}}><p><strong>H.I. Index</strong> modified {moment(this.state.golfer.hi_modified_date).format('MM/DD/YYYY')} by {this.state.golfer.hi_modified_by} (Full: {this.state.golfer.raw_hi} | Expires: {moment(this.state.golfer.hi_modified_expiration_date).format('MM/DD/YYYY')})</p></li>}
                        {this.state.golfer.low_hi_modified && <li style={{listStyleType: "disc"}}> <p><strong>Low Index</strong> modified {moment(this.state.golfer.low_hi_modified_date).format('MM/DD/YYYY')} by {this.state.golfer.low_hi_modified_by} (Full: {this.state.golfer.raw_low_hi} | Expires: {moment(this.state.golfer.low_hi_modified_expiration_date).format('MM/DD/YYYY')})</p></li>}
                        {this.state.golfer.soft_cap && !this.state.golfer.hard_cap && <li style={{listStyleType: "disc"}}> <p>A <strong>Soft Cap</strong> has been applied to this Handicap Index, which suppresses upward movement by 50% after a 3.0 stroke increase over the Low Handicap Index (in effect when the score was posted) has been reached.</p></li>}
                        {this.state.golfer.hard_cap && this.state.golfer.soft_cap && <li style={{listStyleType: "disc"}}> <p>A <strong>Hard Cap</strong> has been applied to this Handicap Index, which restricts upward movement to 5.0 strokes over the Low Handicap Index (in effect when the score was posted) after the application of the Soft Cap.</p></li>}
                      </ReactTooltip>
                    </div>
                  }
                  <span className="circle">
                    <span className="index">{this.state.golfer.IndexType} H.I.</span>
                    <span className="score">{this.state.golfer.handicap_index}</span>
                    {this.state.golfer.hi_modified && <span className="date gray">{this.state.golfer.raw_hi && "(" + this.state.golfer.raw_hi + ")"}</span>}
                  </span>
                  <span className="circle">
                    <span className="index">Low H.I.</span>
                    <span className="score gray">{this.state.golfer.low_hi}</span>
                    {this.state.golfer.low_hi_modified ?
                    (this.state.golfer.low_hi_modified_date &&
                      <span className="date gray">
                        {moment(this.state.golfer.low_hi_modified_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                      </span>) :
                    (this.state.golfer.low_hi_date &&
                      <span className="date gray">
                        {moment(this.state.golfer.low_hi_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                      </span>)}
                    </span>
                </li>
              </ul>
              <section className="page__tab-panel">
                <Switch>
                  <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/handicap-management`}/>
                  <Route path={`${this.props.match.url}/handicap-management`} component={() =>
										<HandicapManagement
                      props={this.props}
                      canEditClub={getPermission(this.props.user_type, 'edit-club')}
                      golfer={this.state.golfer}
                      publicView={this.state.publicView}
                      archived={this.state.golfer.status === "Archived" || !this.state.active}
                      updateGolfer={() => this.showGolfer()}/>
                  } />
                  {canAccessHandicapReview &&
                    <Route path={`${this.props.match.url}/handicap-review`} component={() =>
                      <HandicapReview
                        props={this.props}
                        canEditClub={getPermission(this.props.user_type, 'edit-club')}
                        golfer={this.state.golfer}
                        publicView={this.state.publicView}
                        archived={this.state.golfer.status === "Archived" || !this.state.active}
                        updateGolfer={() => this.showGolfer()}/>
                    }/>
                  }
                  <Route path={`${this.props.match.url}/score-maintenance`} component={() =>
										<ScoreMaintenance
                      canEditClub={getPermission(this.props.user_type, 'manage-scores')}
                      golfer={this.state.golfer}
                      user_type={this.props.user_type}
                      publicView={this.state.publicView}
                      archived={!this.state.active}
                      {...this.props}/>
                  } />
                  {
                    process.env.REACT_APP_TYPE !== "hv" && getPermission(this.props.user_type, 'manage-scores') &&
                    <Route path={`${this.props.match.path}/post-a-score`} component={() =>
										  <PostAScore
                        canEditClub={getPermission(this.props.user_type, 'edit-club')}
                        props={this.props}
                        publicView={this.state.publicView}
                        archived={this.state.golfer.status === "Archived" || this.state.golfer.status === "Inactive"}
                        golfer={this.state.golfer}/>
                    }/>
                  }
                  <Route path={`${this.props.match.url}/account`} component={() =>
										<Account
                      canEditClub={getPermission(this.props.user_type, 'edit-club')}
                      canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                      canDeleteAffiliations={getPermission(this.props.user_type, 'edit-associations')}
                      props={this.props}
                      golfer={{ ...this.state.golfer, active: this.state.active }}
                      updateGolfer={(data) =>this.showGolfer(data)}
                      associationId={this.props.match.params.association_id}
                      club={this.props.match.params.id}
                      archived={this.state.golfer.status === "Archived"}
                      publicView={this.state.publicView}/>
                  }/>
                  {
                    process.env.REACT_APP_TYPE !== "hv" && getPermission(this.props.user_type, 'edit-club') &&
                    <Route path={`${this.props.match.url}/audit-log`} component={() =>
									    <AuditLog canEditClub={getPermission(this.props.user_type, 'edit-club')} {...this.props}/>
                    }/>
                  }
                </Switch>
              </section>
            </div>
          </main>
        <Footer/>
      </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type,
    rawAccess: state.user.rawAccess
	};
}

export default withRouter(connect(mapStateToProps, null)(Golfer));
