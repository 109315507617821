import React, { Component, Fragment } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import TableFilter from "./TableFilter";
import GhinTable from "../../../../../shared/GhinTable";
import ItemComponent from "../../../../../shared/ItemComponent";
import TableService from "../../../../../../services/tableService";
import AssociationService from "../../../../../../services/api/association";


export default class CampaignsDetails extends Component {
  constructor() {
    super();
    this.state = {
      filtered: [],
      exportUrlForCampaign: null,
      detailsLoading: true,
      campaignDetails: [],
      sorted: [{id: "ghin_number", desc: false}],
      pages: 0,
      page: 0,
      per_page: 20,
      total: 0,
    };
  }

  getExportUrlForCampaign() {
    AssociationService.exportCampaign(this.props.campaign.golf_association_id, this.props.campaign.id)
      .then(res => {
        this.setState({exportUrlForCampaign: res.url});
      })
      .catch(err => {
          console.log(err)
      });
  }

  componentDidMount() {
    this.getExportUrlForCampaign();
  }

  loadDetails() {
    this.setState({
      detailsLoading: true,
      campaignDetails: []
    });

    const params = this.getClubRequestParams();
    params.status = params.sent === "open" ? "open" : null;
    params.sent  = params.sent === "open" ? true : params.sent;

    AssociationService.getCampaignDetails(this.props.campaign.golf_association_id, this.props.campaign.id, params)
      .then( res => {
        this.setState({
          detailsLoading: false,
          total: parseInt(res['total-count'], 10),
          per_page: parseInt(res['per-page'], 10),
          pages: parseInt(res['total-pages'], 10),
          campaignDetails: res.campaign_emails.map(campaign => {
            return {
              ...campaign,
              date_opened: campaign.date_opened  && moment(campaign.date_opened).format("MM-DD-YYYY HH:mm:ss"),
              date_sent: campaign.date_sent && moment(campaign.date_sent).format("MM-DD-YYYY HH:mm:ss")
            }
          })
        });
      })
      .catch( err => {
        console.log(err)
      })
  }

  updateFilter(filtered) {
    this.setState({
      filtered,
      page: 0
    }, () => {
      this.loadDetails();
    });
  };

  getClubRequestParams() {
    let params = {
      page: (this.state.page) + 1,
      per_page: this.state.per_page
    };

    if (this.state.sorted.length > 0) {
      params['sorting_criteria'] = this.state.sorted[0].id;
      params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
    }

    params = {
      ...params,
      ...TableService.filtersArrayToParams(this.state.filtered)
    };
    return params;
  }

  render() {
    let detailsColumns = [
      {
        Header: "GHIN Number",
        accessor: "ghin_number",
      },
      {
        Header: "Email Address",
        accessor: "email",
        colClassName: "col_golfer-name",
      },
      {
        Header: "Date/Time Sent",
        accessor: "date_sent",
        colClassName: "col_golfer-name",
        Cell: (props) =>
          moment(
            new Date(props.original.date_sent),
            "YYYY/MM/DD HH:mm:ss",
          ).format("MM/DD/YYYY HH:mm:ss"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => {
          const key = Math.random();
          return (
            <div>
              {props.value}
              {props.original.not_sent &&
                props.original.error_message && (
                  <Fragment>
                    <a
                      href="#donothing"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      data-tip
                      data-for={`status${key}`}
                    >
                      <i className="material-icons v-mid m-left dark-blue">
                        info
                      </i>
                    </a>
                    <ReactTooltip
                      id={`status${key}`}
                      className="auto-size-opaque_tooltip"
                      type="light"
                      place="top"
                      effect={"float"}
                      style={{ width: "auto" }}
                    >
                      {props.original.error_message}
                    </ReactTooltip>
                  </Fragment>
                )}
            </div>
          );
        },
      },
    ];

    let impressionsColumns = [
      {
        Header: (
          <Fragment>
            Total Image Views
            <a
              data-tip
              data-for="total_image_views"
              href="#donothing"
            >
              <i className="material-icons v-mid m-left white">
                info
              </i>
            </a>
            <ReactTooltip
              id="total_image_views"
              className="table-head__tooltip"
              type="light"
              place="top"
              effect={"float"}
            >
              <strong>Total Image Views </strong>data was added on
              03/23/2022. Historical data prior to this date is
              unavailable.
              <strong> N/A</strong> will display in the{" "}
              <strong>Total Image Views</strong> field for
              newsletters sent prior to 03/23/2022 and for
              newsletters without images.
            </ReactTooltip>
          </Fragment>
        ),
        accessor: "total_image_views",
        colClassName: "col_centered_checkbox",
        className: "table-align-center",
        thClassName: "table-align-center",
      },
      {
        Header: (
          <Fragment>
            Total Link Clicks
            <a
              data-tip
              data-for="total_link_clicks"
              href="#donothing"
            >
              <i className="material-icons v-mid m-left white">
                info
              </i>
            </a>
            <ReactTooltip
              id="total_link_clicks"
              className="table-head__tooltip"
              type="light"
              place="top"
              effect={"float"}
            >
              <strong>Total Link Clicks</strong> data was added on
              03/23/2022. Historical data prior to this date is
              unavailable.
              <strong> N/A</strong> will display in the{" "}
              <strong>Total Link Clicks</strong> field for
              newsletters sent prior to 03/23/2022.
            </ReactTooltip>
          </Fragment>
        ),
        accessor: "total_link_clicks",
        colClassName: "col_centered_checkbox",
        className: "table-align-center",
        thClassName: "table-align-center",
      },
      {
        Header: "Links",
        accessor: "link_clicks",
        colClassName: "col_golfer-name-short",
        className: "table-align-center-no-padding without_tooltip",
        thClassName: "table-align-center-no-padding",
        Cell: (props) => {
          if (props.value !== null && props.value.length > 0)
            return (
              <table className="data__table">
                <tbody>
                  {props.value.map((elem) => {
                    return (
                      <tr key={Math.random()?.toString()}>
                        <td>
                          <ItemComponent
                            key={Math.random()?.toString()}
                            id={elem.link}
                            data={elem.link}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          else return "";
        },
      },
      {
        Header: "Link Clicks",
        accessor: "link_clicks",
        colClassName: "col_centered_checkbox",
        className: "table-align-center-no-padding",
        thClassName: "table-align-center-no-padding",
        Cell: (props) => {
          if (props.value !== null && props.value.length > 0)
            return (
              <table
                className="data__table"
                style={{ border: "none" }}
              >
                <tbody>
                  {props.value.map((elem) => {
                    return (
                      <tr key={Math.random()?.toString()}>
                        <td>{elem.clicks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          else return "";
        },
      },
      {
        Header: "Clickthrough Rate",
        accessor: "link_clicks",
        colClassName: "col_centered_checkbox",
        className: "table-align-center-no-padding",
        thClassName: "table-align-center-no-padding",
        Cell: (props) => {
          if (props.value !== null && props.value.length > 0)
            return (
              <table
                className="data__table"
                style={{ border: "none" }}
              >
                <tbody>
                  {props.value.map((elem) => {
                    return (
                      <tr key={Math.random()?.toString()}>
                        <td>
                          {elem.clickthrough_rate}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          else return "";
        },
      },
    ];

    return (
      <Fragment>
        <tr>
          <td colSpan={9} style={{ textAlign: "left", backgroundColor: "white" }}>
            <div className="panel">
              <div className="panel__body">
                <div className="row" style={{ margin: 0 }}>
                  <div className="col auto push-right">
                    <a
                      href={
                        this.state.exportUrlForCampaign
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="btn fill green small"
                    >
                      Export to Excel
                    </a>
                  </div>
                </div>
                <div className="row jc-c">
                  <div className="col">
                    <p>
                      <strong>
                        Image & Link Impressions
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <GhinTable
                      idKey={"id"}
                      columns={impressionsColumns}
                      hideSelect={true}
                      data={[this.props.campaign]}
                      ref={(r) => (this.table = r)}
                      sortable={false}
                      hidePagination={true}
                    />
                  </div>
                </div>
                <div className="form__separator padding-16-y"></div>
                <div className="row jc-c">
                  <div className="col">
                    <p>
                      <strong>Golfer Search</strong>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TableFilter updateFiltered={this.updateFilter.bind(this)} hideFilters={false} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <GhinTable
                      idKey={"id"}
                      columns={detailsColumns}
                      loading={this.state.detailsLoading}
                      hideSelect={true}
                      manual
                      data={this.state.campaignDetails}
                      total={this.state.total}
                      pages={this.state.pages}
                      page={this.state.page}
                      pageSize={this.state.per_page}
                      ref={(r) => (this.table = r)}
                      onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadDetails())}
                      onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        per_page: pageSize,
                        page: pageIndex
                      }, () => this.loadDetails())}
                      defaultSorted={[{
                        id: 'SeasonName',
                        desc: false
                      }]}
                      sortable={true}
                      sorted={this.state.sorted}
                      onSortedChange={(newSorted) => {
                        this.setState({
                          sorted: newSorted,
                          page: 0
                        }, () => this.loadDetails())}
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </Fragment>
    );
  }
}
