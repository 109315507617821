import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import TableFilter from './TableFilter';
import ClubService from "../../../../services/api/club";
import GhinTable from "../../../shared/GhinTable";
import ExportDataModal from "../../../Modals/ExportDataModal";
import moment from "moment";
import SharedService from "../../../../services/api/shared";
import Modal from "react-modal";
import EditGolfersStatusModal from "../../../Modals/EditGolfersStatusModal";
import ChangeGolferMembershipModal from "../../../Modals/ChangeGolferMembershipModal";
import { connect } from "react-redux";
import SpecialUpdateModal from "../../../Modals/SpecialUpdateModal";
import GolferService from "../../../../services/api/golfer";
import ReactTooltip from "react-tooltip";
import HandicapReviewModal from '../../../Modals/HandicapReviewModal';

const generateGolferName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ? row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};

class Table extends Component {

	constructor() {
		super();
		this.state = {
			tableFilterIsHidden: false,
			associations: [],
			filters: [{ id: "status", value: "Active" }],
			sorted: [{ id: "last_name", desc: false }],
			club_groups: [],
			isAllSelected: false,
			golfers: [],
			selectedGolfers: [],
			unselectedGolfers: [],
			isExportDataOpened: false,
			page: 0,
			dataToExport: [],
			per_page: 100,
			activateModalOpened: false,
			membershipModalOpened: false,
			membershipTypes: [],
			isSpecialUpdateOpened: false,
			isHandicapReviewOpened: false
		};
	}

	componentDidMount() {
		this.reloadGolfers();
		this.getMembershipTypes();
	}

	componentDidUpdate(prevProps) {
		if (this.props.updateRosterList !== prevProps.updateRosterList) {
			this.reloadGolfers();
		}
	}

	getMembershipTypes() {
		ClubService.getMembershipTypes(this.props.club.id, { include_used_membership: 'false' })
			.then(res => {
				this.setState({
					membershipTypes: res.membership_types.map(membershipType => {
						return { label: membershipType.code + ' - ' + membershipType.description, value: membershipType.id };
					}).sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
				});
			})
			.catch(err => {
				console.error(err)
			})
	}

	reloadGolfers() {
		this.setState({
			loading: true,
			golfers: []
		});

		const params = this.getRequestParams();

		ClubService.getClubRoster(this.props.match.params.association_id, this.props.club.id, params).then((data) => {
			let [golfers, selectedGolfers] = this.updateGolferData(data);
			this.setState({
				loading: false,
				golfers,
				selectedGolfers,
				total: parseInt(data['total-count'], 10),
				per_page: parseInt(data['per-page'], 10),
				pages: parseInt(data['total-pages'], 10),
				inactive: parseInt(data['total-count'], 10) - parseInt(data.meta['active_golfers_count'], 10),
				active: parseInt(data.meta['active_golfers_count'], 10)
			});
		});
	}

	updateGolferData(data) {
		const selectedGolfers = this.state.selectedGolfers;

		const golfers = data.golfers.map((golfer) => {

			let selected = false;
			if (this.state.isAllSelected) {
				selected = !this.state.unselectedGolfers.includes(golfer.id);
			} else {
				selected = this.state.selectedGolfers.includes(golfer.id);
			}

			return {
				...golfer,
				selected,
				date_of_birth: golfer.date_of_birth ? moment(golfer.date_of_birth).format("MM/DD/YYYY") : null,
				status_date: golfer.status_date ? moment.utc(golfer.status_date).format("MM/DD/YYYY") : "",
			}
		});


		return [golfers, selectedGolfers];

	}



	getRequestParams() {
		let params = {
			page: (this.state.page + 1),
			per_page: this.state.per_page
		};

		if (this.state.sorted.length > 0) {
			params['sorting_criteria'] = this.state.sorted[0].id;
			params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		}

		this.state.filters.forEach((filter) => {
			if (Array.isArray(filter.value)) {
				const values = filter.value.map(value => value.value).join(",");
				params[filter.id] = values;
			} else if (filter.value.constructor.name === "Object") {
				params[filter.id] = filter.value.value;
			} else {
				params[filter.id] = filter.value;
			}
		});
		return params;
	}

	toggleTableFilter = () => this.setState({ tableFilterIsHidden: !this.state.tableFilterIsHidden });

	showExportModal = () => {
		this.setState({
			isExportDataOpened: true
		})
	};

	updateExportedDataRow(id, updatedState) {
		const golfers = this.state.golfers.map(golfer => { if (golfer.id === id) { golfer.selected = updatedState } return golfer });
		let selectedGolfers = this.state.selectedGolfers;
		if (updatedState) {
			selectedGolfers.push(id);
		} else {
			selectedGolfers = selectedGolfers.filter(golfer => golfer !== id);
		}

		this.setState({
			golfers,
			selectedGolfers: [...new Set(selectedGolfers)],
			isAllSelected: updatedState ? this.state.isAllSelected : false
		});
	}

	updateAllExportedData(newSelectedState) {
		let selectedGolfers = this.state.selectedGolfers;
		let golfers = this.state.golfers;
		if (newSelectedState) {
			golfers = golfers.map(golfer => { selectedGolfers.push(golfer.id); golfer.selected = true; return golfer });
		} else {
			golfers = golfers.map(golfer => { selectedGolfers = selectedGolfers.filter(g => g.id === golfer.id); golfer.selected = false; return golfer })
		}
		this.setState({
			golfers,
			selectedGolfers: [...new Set(selectedGolfers)],
			isAllSelected: newSelectedState
		});
	}

	showEditStatusModal = (type) => {
		this.setState({
			activateModalOpened: type
		});
	}

	showChangeMembershipModal() {
		this.setState({
			membershipModalOpened: true
		});
	}

	closeModal(data) {
		this.setState({
			activateModalOpened: false,
			membershipModalOpened: false,
		});
		if (data) {
			this.setState({
				selectedGolfers: [],
				isAllSelected: false
			}, () => {
				this.reloadGolfers();
			});
		}
	}

	updateSpecialUpdateModalState = (state) => {
		this.setState({ isSpecialUpdateOpened: state });
	};

	updateHandicapReviewModalState = (state) => {
		this.setState({ isHandicapReviewOpened: state });
	};

	performSpecialUpdate() {
		let golfersIds = this.state.selectedGolfers;

		if (this.state.isAllSelected) {
			golfersIds = this.state.unselectedGolfers;
		}

		this.setState({
			selectedGolfers: [],
			isAllSelected: false,
			unselectedGolfers: []
		});

		let params = {
			...this.getRequestParams()
		};

		delete params['page'];
		delete params['per_page'];

		this.reloadGolfers();
		if (this.state.isAllSelected) {
			return GolferService.specialUpdate(this.props.match.params.id, golfersIds, true, params);
		} else {
			params = {};
			return GolferService.specialUpdate(this.props.match.params.id, golfersIds, false, params);
		}
	}

	performHandicapReview() {
		this.setState({
			selectedGolfers: [],
			isAllSelected: false,
			unselectedGolfers: []
		});

		this.reloadGolfers();

		return ClubService.createHandicapReviewForClub(this.props.match.params.id, [], true, {});
	}

	getSelectedGolfersCount() {
		if (this.state.isAllSelected) return this.state.total;
		return this.state.selectedGolfers.length;
	}

	onRowSelect(row) {
		let selectedGolfers = [...this.state.selectedGolfers];
		let unselectedGolfers = [...this.state.unselectedGolfers];
		const isAllSelected = this.state.isAllSelected;
		let isSelected = true;

		if (isAllSelected) {
			if (unselectedGolfers.includes(row.id)) {
				unselectedGolfers.splice(unselectedGolfers.indexOf(row.id), 1);
			} else {
				isSelected = false;
				unselectedGolfers.push(row.id);
			}
		} else {
			if (selectedGolfers.includes(row.id)) {
				selectedGolfers.splice(selectedGolfers.indexOf(row.id), 1);
				isSelected = false;
			} else {
				selectedGolfers.push(row.id);
			}
		}

		let golfers = [...this.state.golfers].map((i) => {
			if (i.id === row.id) {
				return {
					...i,
					selected: isSelected
				}
			} else {
				return i;
			}
		});

		this.setState({
			golfers,
			selectedGolfers,
			unselectedGolfers
		});
	}

	willAutoRenewSoon = (auto_renew_date) => {
		return moment(auto_renew_date, "YYYY-MM-DD") <= moment().add(7, 'd')
	};

	getIcon = (props) => {
		if (props.original.auto_renew_failed)
			return "do_not_disturb_on";

		if (!props.value)
			return "";

		if ((props.original.status === "Inactive" || props.original.status === "Archived") && this.willAutoRenewSoon(props.original.auto_renew_date))
			return "";

		return "check_circle"
	};

	render() {
		let disableSelectedExport = true;
		let su = this.props.user_type === "super-admin"

		if (this.state.isAllSelected) {
			if (this.state.unselectedGolfers.length > 0) {
				disableSelectedExport = false;
			} else {
				disableSelectedExport = true;
			}
		} else {
			if (this.state.selectedGolfers.length > 0) {
				disableSelectedExport = false;
			} else {
				disableSelectedExport = true;
			}
		}

		const columns = [
			{
				Header: '#',
				accessor: 'id',
				colClassName: 'col_id',
				show: false

			},
			{
				accessor: 'first_name',
				show: false
			},
			{
				Header: 'Golfer Name',
				accessor: 'last_name',
				filterMethod: (filter, row) =>
					row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
				Cell: props =>
					<Fragment>
						{su || !this.props.club.is_trial ? (
							<NavLink to={`./golfer/${props.row.id}`}>{generateGolferName(props.row)}</NavLink>
						) : (
							generateGolferName(props.row)
						)}
					</Fragment>,
				colClassName: 'col_golfer-name'
			},
			{
				Header: 'H.I.',
				accessor: 'handicap_index',
				colClassName: 'col_remove'
			},
			{
				Header: 'Status',
				accessor: 'status',
				colClassName: 'col_status'
			},
			{
				Header: 'Status Date',
				accessor: 'status_date',
				colClassName: 'col_date'
			},
			{
				Header: 'GHIN #',
				accessor: 'id',
				colClassName: 'col_state'
			},
			{
				Header: 'Local #',
				accessor: 'local_number',
				colClassName: 'col_state'
			},
			{
				Header: 'Gender',
				accessor: 'gender',
				colClassName: 'col_holes'
			},
			{
				Header: 'Date Of Birth',
				accessor: 'date_of_birth',
				colClassName: 'col_date'
			},
			{
				Header: 'MC',
				accessor: 'membership_code',
				colClassName: 'col_edit',
				show: process.env.REACT_APP_TYPE !== "hv"
			},
			{
				Header: 'Digital Profile',
				accessor: 'has_digital_profile',
				Cell: props => <Fragment>
					{props.row.has_digital_profile &&
						<span className="material-icons blue" style={{ display: "table", margin: "0 auto" }}>
							account_circle
						</span>
					}
				</Fragment>,
				colClassName: 'col_date',
				className: 'without_tooltip'
			},
			{
				Header: 'Auto-Renew',
				accessor: 'auto_renew_enabled',
				show: this.props.club.jr_enabled === true,
				Cell: props => {
					return <>
						<span
							className={`material-icons ${props.original.auto_renew_failed ? "red" :
								this.willAutoRenewSoon(props.original.auto_renew_date) ? "yellow" : "green"
								}`}
							style={{ display: "table", margin: "0 auto", cursor: props.value && props.original.auto_renew_failed ? "pointer" : "" }}
							data-tip={props.original.auto_renew_failed}
							data-for={`auto-renew-${props.original.id}`}
						>
							{this.getIcon(props)}
						</span>
						{props.original.auto_renew_failed && (
							<ReactTooltip
								id={`auto-renew-${props.original.id}`}
								className="table-head__tooltip auto-width-tooltip"
								place='bottom'
								effect={'float'}
							>
								<div style={{ padding: '10px 0px' }}>
									<p style={{ fontSize: "14px", lineHeight: "20px" }}>{props.original.auto_renew_error_message}</p>
									<br />
									<p style={{ fontSize: "14px", lineHeight: "20px" }}>
										{props.original.status !== 'Active' ? 'The 2nd attempt to auto-renew this golfer failed on' : 'We will attempt to auto-renew this golfer again on'} {moment(props.original.auto_renew_inactive_date, "YYYY-MM-DD").format("MM/DD/YYYY")}.
									</p>
								</div>
							</ReactTooltip>
						)}
					</>
				}
			},
			{
				Header: 'Renewal Date',
				accessor: 'auto_renew_date',
				Cell: props => {
					let date = props.value ? moment(props.value, "YYYY-MM-DD").format("MM/DD/YYYY") : "";

					const subtract_days = process.env.REACT_APP_TYPE === "staging" && [98, 108, 211].includes(props.original.association_id) ? 2 : 8;
					if (props.original.auto_renew_failed && props.original.status !== 'Active' && props.original.auto_renew_inactive_date)
						date = moment(props.original.auto_renew_inactive_date, "YYYY-MM-DD").subtract(subtract_days, 'days').format("MM/DD/YYYY");
					return <>{date}</>
				},

				colClassName: 'col_date'
			},
			{
				Header: 'suffix',
				accessor: 'suffix',
				show: false
			},
			{
				Header: 'prefix',
				accessor: 'prefix',
				show: false
			},
			{
				Header: 'middle_name',
				accessor: 'middle_name',
				show: false
			},
			{
				Header: 'first_name',
				accessor: 'first_name',
				show: false
			},

		];

		if (this.props.hideFilters) return null;

		return (
			<Fragment>
				<TableFilter club_id={this.props.match.params.id} hideFilters={this.state.tableFilterIsHidden} membershipTypes={this.state.membershipTypes}
					autoRenewalEnabled={this.props.club.jr_enabled}
					updateFiltered={filters => this.setState({ filters, page: 0 }, () => { this.reloadGolfers() })}
					isTrial={this.props.club.is_trial}
				/>

				<div className="pre__table">
					<div className="row">

						<div className="col auto">
							<ul className="action__list push-left">
								<li><button className="btn fill green" onClick={this.showExportModal}>Export to Excel</button></li>

								{((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === "super-admin") &&
									<Fragment>
										<li><button className="btn fill green"
											disabled={(!this.state.isAllSelected && this.state.selectedGolfers.length === 0) || this.props.club.status !== 'Active'}
											onClick={() => this.showEditStatusModal('activate')}>Activate</button></li>
										<li><button className="btn fill green"
											disabled={(!this.state.isAllSelected && this.state.selectedGolfers.length === 0) || this.props.club.status !== 'Active'}
											onClick={() => this.showEditStatusModal('inactivate')}>Inactivate</button></li>
										<li><button className="btn fill green"
											disabled={(!this.state.isAllSelected && this.state.selectedGolfers.length === 0) || this.props.club.status !== 'Active'}
											onClick={() => this.showChangeMembershipModal()}>Change Membership Type</button></li>
									</Fragment>
								}
								{
									(this.props.user_type === 'super-admin' || this.props.user_type === 'association-admin') &&
									<li>
										<button className="btn fill green"
											disabled={this.props.club.status !== 'Active'}
											onClick={() => this.updateSpecialUpdateModalState(true)}>Special Update
										</button>
									</li>
								}
							</ul>
						</div>

						<div className="col auto push-right">
							<div className="row">
								<div className="col">
									<ul className="association__status">
										<li>Active = <strong>{this.state.active}</strong></li>
										<li>Inactive = <strong>{this.state.inactive}</strong></li>
										<li>Total = <strong>{this.state.total}</strong></li>
									</ul>
								</div>
								<div className="col auto">
									{!this.state.tableFilterIsHidden &&
										<button onClick={this.toggleTableFilter.bind(this)} className="btn fill gray">Hide
											Filters <i className="material-icons-outlined">remove</i></button>}
									{this.state.tableFilterIsHidden &&
										<button onClick={this.toggleTableFilter.bind(this)} className="btn fill gray">Show
											Filters <i className="material-icons-outlined">add</i></button>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col is-7-of-16'}>
						<span className={"bold"}> <span className={"red"}>Important:</span>  "Select All" includes record on all pages, not just the current page.</span>
					</div>
					<div className='col is-9-of-16' style={{ textAlign: "right" }}>
						<div>
							<span className='club-icons-legend'>
								<span className="material-icons blue v-mid item club-icons-legend-icon">
									account_circle
								</span>
								Golfer has a Digital Profile
							</span>
							{this.props.club.jr_enabled === true && <Fragment>
								<span className='club-icons-legend'>
									<span className="material-icons green v-mid club-icons-legend club-icons-legend-icon">
										check_circle
									</span>
									Auto-Renew Set
								</span>
								<span className='club-icons-legend'>
									<span className="material-icons yellow v-mid club-icons-legend club-icons-legend-icon">
										check_circle
									</span>
									Will Auto-Renew Soon
								</span>
								<span className='club-icons-legend'>
									<span className="material-icons red v-mid club-icons-legend club-icons-legend-icon">
										do_not_disturb_on
									</span>
									Auto-Renew Failed
								</span>
							</Fragment>}
						</div>
					</div>
				</div>
				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					manual
					page={this.state.page}
					total={this.state.total}
					pageSize={this.state.per_page}
					isAllSelected={this.state.isAllSelected}
					data={this.state.golfers}
					ref={(r) => this.table = r}
					onPageChange={(pageIndex) => this.setState({ page: pageIndex }, () => this.reloadGolfers())}
					onPageSizeChange={(pageSize, pageIndex) => this.setState({
						per_page: pageSize,
						page: pageIndex
					}, () => this.reloadGolfers())}
					onRowSelect={this.onRowSelect.bind(this)}
					onSelectAll={() => {
						const isAllSelected = !this.state.isAllSelected;
						const unselectedGolfers = [];
						const selectedGolfers = [];
						const golfers = [...this.state.golfers].map((i) => ({ ...i, selected: isAllSelected }));


						this.setState({
							selectedGolfers,
							isAllSelected,
							unselectedGolfers,
							golfers
						})

					}}
					onSelectPage={() => {
						let golfers = [...this.state.golfers];
						let selectedGolfers = [...this.state.selectedGolfers];
						/**
						 * if all the golfers on the page are already selected, then remove them
						 */
						const clubsAlreadySelected = golfers.filter((club) => selectedGolfers.includes(club.id));
						if (clubsAlreadySelected.length === this.state.golfers.length && clubsAlreadySelected.length > 0) {
							//remove club from selected items
							selectedGolfers = selectedGolfers.filter((selectedClub) => !golfers.map((c) => c.id).includes(selectedClub));
						} else {
							//mark all golfers as selected, even the ones that were not selected
							selectedGolfers = [...new Set([...this.state.selectedGolfers, ...golfers.map((club) => club.id)])];
						}

						golfers = golfers.map((club) => ({ ...club, selected: selectedGolfers.includes(club.id) }));
						const unselectedGolfers = [];
						this.setState({
							unselectedGolfers,
							selectedGolfers,
							golfers
						});
					}}

					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState({
							sorted: newSorted,
							page: 0
						}, () => {
							this.reloadGolfers()
						})
					}}
					filtered={this.state.filtered}
				/>
				<ExportDataModal
					columns={columns}
					isOpen={this.state.isExportDataOpened}
					isAllSelected={this.state.isAllSelected}
					onRequestClose={() => this.setState({ isExportDataOpened: false })}
					data={this.state.golfers}
					disableSelected={disableSelectedExport}
					onDownloadClick={this.handleDownload.bind(this)}
				/>

				<Modal
					isOpen={this.state.activateModalOpened ? true : false}
					onRequestClose={() => { this.closeModal() }}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditGolfersStatusModal closeModal={(data) => { this.closeModal(data) }}
						golfers={this.state.isAllSelected ? this.state.unselectedGolfers : this.state.selectedGolfers}
						assoc={this.props.match.params.association_id}
						isAllSelected={this.state.isAllSelected}
						filters={this.getRequestParams()}
						club={this.props.match.params.id}
						type={this.state.activateModalOpened} />
				</Modal>
				<Modal
					isOpen={this.state.isSpecialUpdateOpened}
					onRequestClose={() => this.updateSpecialUpdateModalState(false)}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<SpecialUpdateModal closeModal={() => { this.updateSpecialUpdateModalState(false) }}
						confirmAction={() => this.performSpecialUpdate()}
						onDownloadClick={(url) => this.specialUpdateDownload(url)}
						title={`Special Update ${this.state.selectedGolfers.length > 0 ? this.state.selectedGolfers.length + " golfers" : "all golfers"}`}
						description={this.state.selectedGolfers.length > 0 ? "You are about to perform a Special Update for the SELECTED ACTIVE GOLFERS" : "You are about to perform a Special Update for ALL ACTIVE GOLFERS in the club."}
					/>
				</Modal>
				<Modal
					isOpen={this.state.isHandicapReviewOpened}
					onRequestClose={() => this.updateHandicapReviewModalState(false)}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<HandicapReviewModal closeModal={() => { this.updateHandicapReviewModalState(false) }}
						confirmAction={() => this.performHandicapReview()}
						onDownloadClick={(url) => this.handicapReviewDownload(url)}
						title={"Handicap Review all golfers"}
						description={"Are you sure you want to run Handicap Review on"}
						selected_golfers_count={"all golfers"}
						resultsUrl={this.props.match.url}
					/>
				</Modal>
				<Modal
					isOpen={this.state.membershipModalOpened}
					onRequestClose={() => { this.closeModal() }}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ChangeGolferMembershipModal closeModal={(data) => { this.closeModal(data) }}
						golfers={this.state.isAllSelected ? this.state.unselectedGolfers : this.state.selectedGolfers}
						assoc={this.props.match.params.association_id}
						isAllSelected={this.state.isAllSelected}
						filters={this.getRequestParams()}
						club={this.props.match.params.id}
						canEditAssociation={this.props.canEditAssociation} />
				</Modal>
			</Fragment>
		);
	}

	specialUpdateDownload(url) {
		return ClubService.getSpecialUpdateCsv(this.props.club.id, url).then(response => {
			SharedService.downloadFile(response, "SpecialUpdates_" + moment().format('MM/DD/YYYY') + ".csv");
			this.setState({
				isSpecialUpdateOpened: false
			});
		});
	}

	handicapReviewDownload(url) {
		return ClubService.getSpecialUpdateCsv(this.props.club.id, url).then(response => {
			SharedService.downloadFile(response, "SpecialUpdates_" + moment().format('MM/DD/YYYY') + ".csv");
			this.setState({
				isHandicapReviewOpened: false
			});
		});
	}

	handleDownload(selectionType) {
		this.setState({
			isExportDataOpened: false
		});
		let params = {
			...this.getRequestParams(),
		};
		delete params.page;
		delete params.per_page;

		if (selectionType === "selected") {
			if (this.state.isAllSelected) {
				params['select_all'] = true;
				params['unchecked_items'] = this.state.unselectedGolfers.join(",");
			} else {
				params['golfer_ids'] = this.state.selectedGolfers.join(",");
			}
		}

		return ClubService.getClubRosterExport(this.props.match.params.association_id, this.props.club.id, params).then(response => {
			window.open(response.url, "_blank")
		});
	}
}

function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type
	};
}

export default connect(mapStateToProps, null)(Table);
