import React, {Component, Fragment} from 'react';
import ReactTable from 'react-table';
import Select from 'react-select';
import ItemComponent from "./ItemComponent";

/**
 * Component to handle tables across app
 * It currently explicitly set's checkboxes for each row
 * example implementation: src/screens/Manage/AssociationList/Table.js
 * documentation: https://github.com/tannerlinsley/react-table/tree/v6
 * Parameter for disable the checkboxes is hideSelect=true...
 * Filtering: [{id: column_name, value: array["Value1", "Value2"] or string/boolean "value"}]
 */

const NoDataComponent = (props) => {
	return (
		<tbody>
		<tr>
			<td colSpan={props.colSpan}>
				{!props.loading &&
        (props.customNoDataMessage ? <h1 style={{color: '#C9243F'}}>{props.customNoDataMessage}</h1> : <h1>No data available.</h1>)}
			</td>
		</tr>
		</tbody>
	)
}
export default class GhinTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sorted: [],
			showSelectOptions: false,
			expanded: null
		};

		this.onExpandedChange = this.onExpandedChange.bind(this);
	}

	getResolvedState() {
		return this.table.getResolvedState();
	}

	getFilteredData() {
		const internalState = this.getResolvedState();
		return [...internalState.sortedData.map((i) => i._original)]; //make sure no mutation happen
	}

	doSort(item) {
		item.toggleSort({});
		this.getFilteredData();
	}

	onExpandedChange(expandedState, index) {
		var newExpanded = {}
		for (const indexProp in expandedState) {
			if (expandedState.hasOwnProperty(indexProp)) {
				if (index?.toString() === indexProp) {
					newExpanded[indexProp] = expandedState[indexProp];
				}
				else {
					newExpanded[indexProp] = false;
				}
			}
		}

		this.setState({
			expanded: newExpanded
		});
	}

	render() {
		const idKey = this.props.idKey || 'id';
		const enabledColumns = this.props.columns.filter((i) => i.show !== false);
		const hasCustomPagination = typeof this.props.total !== 'undefined';

		return (
			<Fragment>
				<ReactTable
					defaultPageSize={ this.props.defaultPageSize ? this.props.defaultPageSize : 20}
					style={this.props.style}
        			expanded={this.state.expanded ? this.state.expanded : {[this.props.expandedIndex]: this.props.displayMembershipDetails}}
					getTableProps={() => ({
						columns: enabledColumns
					})}
        			onExpandedChange={
						(newExpanded, index, event) => this.onExpandedChange(newExpanded, index)
					}
					getTheadThProps={(state, rowInfo, column) => {
						if (column.thClassName) {
							return {
								className: column.thClassName
							}
						} else {
							return {}
						}
					}}
					getTdProps={(state, rowInfo, column, instance) => {
						if (column.highlightColumn && rowInfo.row[column.highlightConditionKey]) {
							return {
								className: 'yellow_highlight'
							}
						} else {
							return {
								className: ''
							}
						}
					}}
					getTbodyProps={props => {
						return {
							sortedDataLength: props.sortedData.length,
							enabledColumnsLength: enabledColumns.length + 1 //checkbox
						};
					}}
					TbodyComponent={
						props => props.sortedDataLength > 0 ?
							<tbody>{props.children}</tbody> :
							<NoDataComponent
								loading={this.props.loading}
								colSpan={this.props.colSpan? this.props.colSpan : props.enabledColumnsLength}
								customNoDataMessage={this.props.customNoDataMessage}
							/>
					}
					defaultFilterMethod={(filter, row, column) => {
						const id = filter.pivotId || filter.id;

						if (Array.isArray(filter.value)) {
							return row[id] !== undefined ? filter.value.indexOf(row[id]) !== -1 : true;
						}

						return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true
					}}
					// loading={this.props.loading}
					ref={(ref) => this.table = ref}
					TableComponent={props => {
						return (
							<>
							<table className="data__table">
								<colgroup>
									{!this.props.hideSelect && <col className="col_checkbox"/>}
									{
										props.columns.map((col) => {
											return <col key={Math.random()?.toString()} className={col.colClassName ? col.colClassName : ""}/>
										})
									}
								</colgroup>
								{props.children}

							</table>
							{this.props.loading &&
							<div className={'loader'}>
								<span className="data-table__loader"></span>
							</div>
							}
							</>
						)
					}}
					TheadComponent={props => <thead>{props.children}</thead>}
					TrComponent={props => <tr>{props.children}</tr>}
					ThComponent={props => 
						<th
							className={`${props.className ? props.className : ""} ${props.thClassName ? props.thClassName : ''}`}
							onClick={() => this.doSort(props)}
						>
							{props.children} {props.className.includes('-sort-desc') ?
							<i className="material-icons-outlined">arrow_drop_down</i> : null} {props.className.includes('-sort-asc') ?
							<i className="material-icons-outlined">arrow_drop_up</i> : null}
						</th>}
					NoDataComponent={props => <Fragment/>}
					TdComponent={props => {let id = Math.random()?.toString(); return <td className={props.className ? props.className : ""}><ItemComponent forceTooltip={props.className.includes("force_tooltip")} hideToolTip={props.className.includes("without_tooltip") ? true : false} key={id} id={id} data={props.children} onClick={props.onClick} className={props.className}/></td>; }}
					TrGroupComponent={props => <Fragment>{props.children}</Fragment>}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					LoadingComponent={props => <Fragment/>}
					ResizerComponent={props => <Fragment>{props.children}</Fragment>}
					PaginationComponent={props => !this.props.hidePagination ? (<div className="data__table-footer">
						<div className="table__navigation">
							<ul className="table__pagination">
								{/*go to first page*/}
								<li>
									<button className={props.page === 0 ? "disabled" : ""}
									onClick={() => props.onPageChange(0)}><i
									className="material-icons-outlined">first_page</i></button>
								</li>
								{/*back*/}
								<li>
									<button className={props.canPrevious ? "" : "disabled"}
									onClick={() => props.onPageChange(props.page - 1)}><i
									className="material-icons-outlined">chevron_left</i></button>
								</li>
								{/*nu stiu ce e asta....*/}
								{props.canPrevious &&
									<li>
										<button onClick={() => props.onPageChange(props.page - 1)}>{props.page}</button>
									</li>
								}
								{/*current*/}
								<li>
									<button className="is-active">{props.page + 1}</button>
								</li>
								{/*nu stiu ce e asta*/}
								{props.canNext &&
									<li>
										<button onClick={() => props.onPageChange(props.page + 1)}>{props.page + 2}</button>
									</li>
								}
								{/*next*/}
								<li>
									<button className={props.canNext ? "" : "disabled"}
									onClick={() => props.onPageChange(props.page + 1)}><i
									className="material-icons-outlined">chevron_right</i></button>
								</li>
								{/*last*/}
								<li>
									<button onClick={() => props.onPageChange(props.pages - 1)} className={props.page === props.pages - 1 ? "disabled" : ""}><i
									className="material-icons-outlined">last_page</i></button>
								</li>
							</ul>
							<div className="table__page-select-container">
								<Select className="react-select-container"
										value={{value: props.pageSize, label: props.pageSize}}
										classNamePrefix="react-select" options={props.pageSizeOptions.map((e) => {
									return {
										label: e,
										value: e
									}
								})} isSearchable={false} onChange={(e) => {
									props.onPageSizeChange(Number(e.value));
								}}/>
							</div>
						</div>
						<div className="table__info">{props.page + 1} of {props.pages || 1} pages
							({hasCustomPagination ? this.props.total : props.sortedData.length} items)
						</div>
					</div>): null}
					minRows={0}
					{...this.props}
					columns={
						this.props.hideSelect ?
						this.props.columns :
						[
							{
								Header: props => !this.props.hideSelectAll  ? <div className={"smart_checkbox"}>
									{/*<input*/}
									{/*	checked={this.props.isAllSelected}*/}
									{/*	type="checkbox" name="select-all"*/}
									{/*	id="select-all"*/}
									{/*	onClick={(e) => e.preventDefault()}*/}
									{/*	onChange={() => this.props.onSelectAll()}*/}
									{/*/><label htmlFor="select-all"></label>*/}
									<div
										className={`checkbox ${this.props.isAllSelected ? 'checked' : ''}`}
										onClick={()=> {
												if (this.props.isAllSelected) {this.props.onSelectAll(false)}
												else {this.setState({showSelectOptions:  true})}
											}
										}
									/>
									{this.state.showSelectOptions && <Fragment>
										<div className={"select"}>
											<div className={"option"} onClick={() => {this.setState({showSelectOptions:false}, () => this.props.onSelectPage())}}>This Page Only</div>
											{!this.props.hideSelectAllPages && <div className={"option"} onClick={() => {this.setState({showSelectOptions:false}, () => this.props.onSelectAll())}}>All Pages</div>}
										</div>
										<div className={"exit_select"} onClick={() => this.setState({showSelectOptions:false})}></div>
									</Fragment>}
								</div> : '',
								accessor: 'selected', // String-based value accessors!
								// hideCheckboxFor - Allows checkboxes to be disabled and unchecked based on accessor values
								Cell: props => <Fragment>
									<input
										type="checkbox"
										checked={props.row.selected && !((this.props.hideCheckboxFor?.values ?? []).includes(props.row[this.props.hideCheckboxFor?.field]))}
										onChange={() => this.props.onRowSelect(props.row)}
										name={props.row[idKey]}
										id={`item-${props.row[idKey]}`}
										disabled={(this.props.hideCheckboxFor?.values ?? []).includes(props.row[this.props.hideCheckboxFor?.field])}
									/>
									<label htmlFor={`item-${props.row[idKey]}`}></label>
								</Fragment>,
								headerClassName: 'table-checkbox-cell',
								resizable: false,
								sortable: false,
								filterable: false,
								colClassName: 'col_checkbox',
							},
							...this.props.columns,
						]
					}
				/>
			</Fragment>
		);
	}
}
