import React from 'react'
import Modal from 'react-modal'
import HandicapRevisionDetailsForm from '../Forms/HandicapRevisionDetailsForm'
import ExportToPdfButton from "../shared/ExportToPdfButton"

export default function HandicapRevisionDetailsModal(props) {
	const revisionDetails = props.revisionDetails;
	const divRef = React.useRef()
	return (
		<Modal
			isOpen={props.isOpen}
			onRequestClose={() => {props.closeModal()}}
			contentLabel="Modal"
			portalClassName="modal"
			overlayClassName="modal__overlay"
			className="modal__content"
			bodyOpenClassName="modal--is-open"
			htmlOpenClassName="prevent-scroll"
			shouldCloseOnOverlayClick
			shouldFocusAfterRender={false}
		>
			<div className="printable" ref={divRef}>
				<div className="modal__container ultra-wide">
					<div className="modal__head">
						<>
							<h2 className="modal__title">Handicap Revision - {revisionDetails?.revision_date}</h2>
						</>
						<button data-html2canvas-ignore={true}
							className="modal__close" onClick={() => {props.closeModal()}}>
							<i className="material-icons-outlined">clear</i>
						</button>
					</div>
					<div className="modal__body">
						<HandicapRevisionDetailsForm revisionDetails={revisionDetails} closeModal={() => props.closeModal()}/>
						<div data-html2canvas-ignore={true}>
							<ExportToPdfButton
								title={`Handicap Revision - ${revisionDetails?.golfer_name} - ${revisionDetails?.revision_date}`}
								componentRefs={[divRef]}
								scale={2}
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
