import React, {Component, Fragment} from 'react';

import "react-datepicker/dist/react-datepicker.css";
import GolferService from "../../../services/api/golfer";
import SharedService from '../../../services/api/shared';
import GhinTable from "../../shared/GhinTable";
import TableFilter from "../../shared/AuditLogsFilter";
import moment from 'moment';

export default class AuditLog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			pages: 0,
			total: 0,
			per_page: 10,
			loading: true,
			change_codes_array: [],
			golfer_updates: [],
			scores: [],
			handicap_revisions: [],
      handicap_reviews: [],
      secondary_filter_array: [],
      start_date: moment().subtract(6, 'months').format("MM/DD/YYYY"),
      end_date: moment().format("MM/DD/YYYY"),
		}
		this.updateFilter = this.updateFilter.bind(this);
		this.getLogs = this.getLogs.bind(this);
		this.getSecondaryFilterValues = this.getSecondaryFilterValues.bind(this)
    this.getLogs(true);

	}

	componentDidMount() {
		this.loadCodeChanges();
	}
	
	getSecondaryFilterValues(filter_change_codes, secondary_filter) {
		let values = secondary_filter;
		let validValues = [];
		if (values) {
			if (filter_change_codes.includes('Golfer Updates')){
				validValues = [...validValues, ...this.state.golfer_updates];
			}
			if (filter_change_codes.includes('Scores')){
				validValues = [...validValues, ...this.state.scores];
			}
			if (filter_change_codes.includes('Handicap Revisions')){
				validValues = [...validValues, ...this.state.handicap_revisions];
			}
      if (filter_change_codes.includes('Handicap Reviews')) {
        validValues = [...validValues, ...this.state.handicap_reviews];
      }
			values = values.filter(v => validValues.includes(v));
		}
		values = filter_change_codes ? values : '';
		return values;
	}

	updateFilter(params) {
		let filter_change_codes = params && params['change_codes']
		let secondary_filter = params && params['secondary_filter']
		let username = params && params['username']
		let index;

		// set start date
		let start_date = params && params['start_date']
		if (start_date != null) {
			start_date = moment(new Date(start_date)).startOf('day');
			start_date = start_date.toISOString();
		}

		// set end date
		let end_date = params && params['end_date']
		if (end_date != null) {
			end_date = moment(new Date(end_date)).endOf('day');
			end_date = end_date.toISOString();
		}
		
		filter_change_codes = filter_change_codes ? filter_change_codes.split(',') : filter_change_codes
		secondary_filter = secondary_filter ? secondary_filter.split(',') : secondary_filter
		secondary_filter = this.getSecondaryFilterValues(filter_change_codes, secondary_filter)

		let secondary_filter_array = []
		
		if (filter_change_codes && filter_change_codes.includes('Golfer Updates')){
			secondary_filter_array = [...secondary_filter_array, ...this.state.golfer_updates];
			if (secondary_filter && this.state.golfer_updates.some(r=> secondary_filter.includes(r))){
				filter_change_codes = filter_change_codes.concat(secondary_filter)
			}
			else
			{
				filter_change_codes = secondary_filter ? filter_change_codes : filter_change_codes.concat(this.state.golfer_updates);
			}
			index = filter_change_codes.indexOf('Golfer Updates');
			if (index !== -1) {
			filter_change_codes.splice(index, 1);
			}
		}

		if (filter_change_codes && filter_change_codes.includes('Scores')){
			secondary_filter_array = [...secondary_filter_array, ...this.state.scores];
			if (secondary_filter && this.state.scores.some(r=> secondary_filter.includes(r))){
				filter_change_codes = filter_change_codes.concat(secondary_filter)
			}
			else
			{
				filter_change_codes = secondary_filter ? filter_change_codes : filter_change_codes.concat(this.state.scores);
			}
			index = filter_change_codes.indexOf('Scores');
			if (index !== -1) {
			filter_change_codes.splice(index, 1);
			}
		}

		if (filter_change_codes && filter_change_codes.includes('Handicap Revisions')){
			secondary_filter_array = [...secondary_filter_array, ...this.state.handicap_revisions]; 
			if (secondary_filter && this.state.handicap_revisions.some(r=> secondary_filter.includes(r))){
				filter_change_codes = filter_change_codes.concat(secondary_filter)
			}
			else 
			{
				filter_change_codes = secondary_filter ? filter_change_codes : filter_change_codes.concat(this.state.handicap_revisions);
			}
			index = filter_change_codes.indexOf('Handicap Revisions');
			if (index !== -1) {
				filter_change_codes.splice(index, 1);
			}
		}

    if (filter_change_codes && filter_change_codes.includes('Handicap Reviews')){
      secondary_filter_array = [...secondary_filter_array, ...this.state.handicap_reviews];
      if (secondary_filter && this.state.handicap_reviews.some(r=> secondary_filter.includes(r))){
        filter_change_codes = filter_change_codes.concat(secondary_filter)
      }
      else
      {
        filter_change_codes = secondary_filter ? filter_change_codes : filter_change_codes.concat(this.state.handicap_reviews);
      }
      index = filter_change_codes.indexOf('Handicap Reviews');
      if (index !== -1) {
        filter_change_codes.splice(index, 1);
      }
    }

		this.setState({
			change_codes: filter_change_codes,
			secondary_filter: secondary_filter,
			secondary_filter_array: secondary_filter_array,
			username: username,
			start_date: start_date,
			end_date: end_date,
			page: 0
		}, () => {
			this.getLogs();
		})
	}

	getRequestParams() {
		let params = {
			page: (this.state.page + 1),
			per_page: this.state.per_page
		};

		if (this.state.change_codes){
			params['change_codes'] =  this.state.change_codes;
		}

		if (this.state.username){
			params['username'] =  this.state.username;
		}

		if (this.state.start_date){
			params['start_date'] =  this.state.start_date;
		}

		if (this.state.end_date){
			params['end_date'] =  this.state.end_date;
		}

		return params;
	}

	loadCodeChanges() {
		SharedService.getCodeChanges('golfer')
			.then(res=>{
				this.setState({
				change_codes_array: res['change_codes'],
				golfer_updates: res['secondary_filter']['Golfer Updates'],
				scores: res['secondary_filter']['Scores'],
				handicap_revisions: res['secondary_filter']['Handicap Revisions'],
        handicap_reviews: res['secondary_filter']['Handicap Reviews']
				})
			})
			.catch(err => {
				console.error(err);
			})
	}

  getLogs(initial_load = false) {
    const currentRequest = Date.now();
    this.latestRequest = currentRequest

		this.setState({
			loading: true,
			logs: []
		});

		const params = this.getRequestParams();
		GolferService.getLogs(this.props.match.params.golfer_id, params)
      .then(res => {
        // Ignore outdated requests
        if (this.latestRequest !== currentRequest) return

        if (initial_load && res.golfer_changes.length === 0) {
          this.setState({
            start_date: null,
            end_date: null,
          }, () => {
            this.getLogs()
          })
        }
        else {
          this.setState({
            loading: false,
            logs: res.golfer_changes.map((change, key) => {
              return {
                ...change,
                id: key,
                date_and_time: change.date_and_time && moment(moment.utc(change.date_and_time).toDate()).format("MM-DD-YYYY HH:mm:ss"),
                change_code: change.change_code,
                new: (change.new_details && change.new_details.length > 0) ? change.new_details.map(detail => { return detail[0] }) : (change.details.length > 0 && change.details.map(detail => { return detail[0] })),
                old: change.details.length > 0 && change.details.filter(detail => detail.length === 2).map(detail => { return detail[1] }),
                username: change.username
              }
            }),
            total: parseInt(res['total-count'], 10),
            per_page: parseInt(res['per-page'], 10),
            pages: parseInt(res['total-pages'], 10),
          });
        }
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {

		let columns = [
			{
				Header: 'Date & Time',
				accessor: 'date_and_time',
				colClassName: 'col_date_and_time'
			},
			{
				Header: 'Change Code',
				accessor: 'change_code',
				colClassName: 'col_change_code'
			},
			{
				Header: "New",
				accessor: "new",
				Cell: (props) => {
					return (
						<Fragment>
							{props.row.new.length > 0 &&
								props.row.new.map((el, key) => {
									return (
										<p key={key}>
											{el.split(/:(.+)/)[0]}
											{el.split(/:(.+)/)[1]? ":" : ""}
											<strong>{el.split(/:(.+)/)[1]}</strong>
										</p>
									);
								})}
						</Fragment>
					);
				},
			},
			{
				Header: "Old",
				accessor: "old",
				Cell: (props) => {
					return (
						<Fragment>
							{props.row.old.length > 0 &&
								props.row.old.map((el, key) => {
									return (
										<p key={key}>
											{el.split(/:(.+)/)[0]}
											{el.split(/:(.+)/)[1]? ":" : ""}
											<strong>{el.split(/:(.+)/)[1]}</strong>
										</p>
									);
								})}
						</Fragment>
					);
				},
			},
			{
				Header: 'Username',
				accessor: 'username',
				Cell: (props) => {
					return(
						<Fragment>
							<div>{props.row.username}</div>
							{props.original?.show_club_name && <div>{props.original?.club_name}</div>}
						</Fragment>
					)
				}
			},
		];
    const date_label = this.state.start_date && this.state.end_date ? `${this.state.start_date} - ${this.state.end_date}` : ''
		return (
            <Fragment>
        <TableFilter
          updateFiltered={this.updateFilter.bind(this)}
          change_codes_array={this.state.change_codes_array}
          secondary_filter_array={this.state.secondary_filter_array}
          date_label={date_label}
          preventInitialLoad
        />

				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					page={this.state.page}
					total={this.state.total}
					manual={true}
					pageSize={this.state.per_page}
					data={this.state.logs}
					hideSelect={true}
					sortable={false}
					ref={(r) => this.table = r}
					onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.getLogs())}
					onPageSizeChange={(pageSize, pageIndex) => this.setState({
						per_page: pageSize,
						page: pageIndex
					}, () => this.getLogs())}
				/>

            </Fragment>
		)
	}

}
