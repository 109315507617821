import React, {Component, Fragment} from 'react'
import AssociationService from "../../../../../../services/api/association";
import moment from "moment";
import ReactTooltip from 'react-tooltip';
import GhinTable from "../../../../../shared/GhinTable";
import CampaignsDetails from "./CampaignsDetails";
import PreviewEmailModal from "../../../../../Modals/PreviewEmailModal";

class Campaigns extends Component {
    constructor(props) {
        super(props);
		this.state = {
			exportUrl: null,
			campaigns: [],
			campaignsLoading: true,
      campaign_sorted: [{id: "date", desc: true}],
      campaign_pages: 0,
      campaign_per_page: 5,
      campaign_page: 0,
			selectedCampaignIndex: -1,
			previewEmailModalIsOpen: false,
			campaignId: null
		}
    }

	componentDidMount() {
		this.loadCampaigns();
		this.getExportUrl();
	}

  getCampaignParams(){
    let params = {
      page: this.state.campaign_page+1,
      per_page: this.state.campaign_per_page,
    }

    if (this.state.campaign_sorted.length > 0) {
      if(this.state.campaign_sorted[0].id === 'date'){
        params['sorting_criteria'] = 'date_sent'
      }
			params['order'] = this.state.campaign_sorted[0].desc ? 'DESC' : 'ASC';
		}
    return params
  }

	loadCampaigns() {
    this.setState({
      campaignsLoading: true,
      campaigns: []
    })
    let params = this.getCampaignParams()
		AssociationService.getCampaigns(this.props.associationId, params)
			.then( res => {
				this.setState({campaigns: res.campaigns.map(campaign => {
						return {
							...campaign,
							date: moment(campaign.created_at).format("MM/DD/YYYY HH:mm:ss"),
							open_percent: campaign.open_percent + '%',
              unique_opens_percent: campaign.unique_opens_percent + '%',
						}
					}),
          campaignsLoading: false,
          campaign_pages: res['total-pages'],
          campaign_per_page: res['per-page'],
          campaign_total: res['total-count']
        });
			})
			.catch( err => {
				console.log(err);
			})
	}

	getExportUrl() {
		AssociationService.exportCampaigns(this.props.associationId)
			.then(res => {
				this.setState({exportUrl: res.url});
			})
			.catch(err => {
					console.log(err)
			});
	}

	showTemplatePreview(id) {
		this.setState({
			previewEmailModalIsOpen: true,
			campaignId: id
		})
	}

	closeModal(){
		this.setState({
			previewEmailModalIsOpen: false,
			campaignId: null
		})
	}

    render(){
		let columns = [
			{
				Header: 'Date Sent',
				accessor: 'date',
				colClassName: 'col_username',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
			{
				Header: 'Template Name',
				accessor: 'name',
				colClassName: 'col_golfer-name-short',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
				Cell: props => <Fragment>
					<a href="#donothing" className="nav__link" onClick={(e) => {e.preventDefault(); this.showTemplatePreview(props.original.id)}}>{props.value}</a>
				</Fragment>,
			},
			{
				Header: '# Sent',
				accessor: 'processed',
        colClassName: 'col_status',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
			{
				Header: '# Delivered',
				accessor: 'delivered_count',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
      {
				Header: '# Not Delivered',
				accessor: 'error_count',
        colClassName: 'col_date',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
      {
				Header: <Fragment>
					Total Opens
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='total_opens'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip id='total_opens' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Total Opens </strong> data was added on 08/18/2021. Historical data prior to this date is unavailable.
              <strong> N/A</strong> will display in the <strong>Total Opens</strong> field for newsletters sent prior to 08/18/2021.
					</ReactTooltip>
				</Fragment>,
				accessor: 'total_opens',
				colClassName: 'col_golfer-name-short',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
			{
				Header: '# Unique Opens',
				accessor: 'open_count',
        colClassName: 'col_date',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
      {
				Header: 'Open Rate',
				accessor: 'unique_opens_percent',
        colClassName: 'col_date',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
			{
				Header: '',
				sortable: false,
				show: this.props.canEditAssociation,
				Cell: props => (
					<button className="btn fill green" style={{ width: "95px" }}
						onClick={() => {
							if (this.state.selectedCampaignIndex === props.index)
								this.setState({selectedCampaignIndex: -1 })
							else
								this.setState({selectedCampaignIndex: props.index}
								)
						}}
					>
						Details
					</button>
				)
			},
			{
				expander: true,
				show: false
			}
		];

		return (
			<div className="row">
				<div className="col">
					<div className="panel">
						<div className="panel__head">Sent Newsletters & Statistics</div>
						<div className="panel__body">
							<div className="row jc-c">
								<div className="col">
									<p>Click the "Template Name" to view the email sent on that specific date.</p>
									<p>Press the "Details" button to view more information about the selected email.</p>
								</div>
								<div className="col auto push-right">
									<a href={this.state.exportUrl} target="_blank" rel="noreferrer" className="btn fill green small">Export to Excel (Last 12 Months)</a>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<GhinTable
										idKey={'id'}
										columns={columns}
                    hideSelect={true}
                    pages={this.state.campaign_pages}
                    page={this.state.campaign_page}
                    pageSize={this.state.campaign_per_page}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                      campaign_per_page: pageSize,
                      campaign_pages: pageIndex,
                      campaign_page: 0
                    }, () => this.loadCampaigns())}
										loading={this.state.campaignsLoading}
                    total={this.state.campaign_total}
                    manual
                    sortable={true}
										data={this.state.campaigns}
                    onPageChange={(pageIndex) =>this.setState({campaign_page: pageIndex}, () =>  this.loadCampaigns())}
                    sorted={this.state.campaign_sorted}
                    onSortedChange={(newSorted, column, shiftKey) => {
                      this.setState({
                        campaign_sorted: newSorted,
                        selectedCampaignIndex: -1
                      }, () => {
                        this.loadCampaigns()
                      })
                    }}
                    expandedIndex={this.state.selectedCampaignIndex}
                    displayMembershipDetails={this.state.selectedCampaignIndex !== -1}
                    SubComponent={row => 
                      <CampaignsDetails
                        campaign={row.original}
                      />
                    }
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.previewEmailModalIsOpen && <PreviewEmailModal
					campaignId={this.state.campaignId}
					associationId={this.props.associationId}
					closeModal={() => {this.closeModal()}}
				/>}
			</div>
		)
    }
}


export default Campaigns;
