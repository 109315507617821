import React, { Component, Fragment } from 'react';
import ClubService from "../../../../../services/api/club";
import GhinTable from "../../../../shared/GhinTable";
import Modal from "react-modal";
import { connect } from "react-redux";
import GolferService from "../../../../../services/api/golfer";
import HandicapReviewDetailsModal from '../../../../Modals/HandicapReviewDetailsModal';
import { getPermission } from '../../../../../permissionManager';
import { NavLink } from 'react-router-dom';
import ConfirmationModal from '../../../../Modals/ConfirmationModal';

class HistoryTable extends Component {
    constructor() {
        super();

        this.state = {
            handicapReviews: [],
            handicapReviewHistories: [],
            loadingHandicapReviewsHistories: false,
            loadingPending: false,
            handicapReviewDetailsModalIsOpen: false,
            selectedHandicapReview: null,
            golferId: null,
            golfer: null,
            canEditClub: false,
            isReviewPending: false,
            selectedHandicapReviews: [],
            selectedGolfers: [],
            isAllSelected: false,
            page: 0,
            perPage: 10,
            total: 10,
            pages: 1,
            pageHistory: 0,
            totalHistory: 10,
            perPageHistory: 10,
            pagesHistory: 1,
            sorted: [{ id: "recommendation_applied", desc: true }],
            selectedHandicapReviewHistoryId: null,
            handicapReviewCreateConfirmationModalIsOpen: false,
            handicapReviewActionConfirmationModalIsOpen: false,
            handicapReviewAction: '',
            golfersCountForHandicapReview: 0,
        };

        this.openHandicapReviewDetailsModal = this.openHandicapReviewDetailsModal.bind(this);
        this.applySelectedHandicapReviews = this.applySelectedHandicapReviews.bind(this);
        this.denySelectedHandicapReviews = this.denySelectedHandicapReviews.bind(this);
    }

    componentDidMount() {
        this.reloadHandicapReviews();
        this.loadClubDetails();
    }

    componentDidUpdate(prevProps) {
        if (this.props.updateRosterList !== prevProps.updateRosterList) {
            this.reloadHandicapReviews();
        }
    }

    golferPageUrl(golferId) {
        return `${this.props.match.url.split('/').slice(0, -2).join('/')}/golfer/${golferId}`
    }

    performHandicapReview() {
        ClubService.createHandicapReviewForClub(this.props.club.id, [], true, {}).then((data) => {
            this.closeModal();
            this.reloadHandicapReviews();
            this.props.history.push(`${this.props.match.url.split('/').slice(0, -1).join('/')}/full-results/${data.handicap_review_history_id}`)
        })
    }

    onSelectRow(row) {
        let selectedHandicapReviews = [...this.state.selectedHandicapReviews];
        let isAllSelected = this.state.isAllSelected;

        let isSelected = true;

        if (selectedHandicapReviews.includes(row.id)) {
            selectedHandicapReviews.splice(selectedHandicapReviews.indexOf(row.id), 1);
            isSelected = false;
        } else {
            selectedHandicapReviews.push(row.id);
        }

        let handicapReviews = [...this.state.handicapReviews].map((i) => {
            if (i.id === row.id) {
                return {
                    ...i,
                    selected: isSelected
                }
            } else {
                return i;
            }
        });

        isAllSelected = selectedHandicapReviews.length === handicapReviews.length

        // Get all uniq golfer IDs
        let selectedGolfers = [...new Set(selectedHandicapReviews.map((hr) => hr.golfer_id))]

        this.setState({
            selectedGolfers: selectedGolfers,
            handicapReviews: handicapReviews,
            selectedHandicapReviews: selectedHandicapReviews,
            isAllSelected: isAllSelected
        });
    }

    onSelectAll() {
        const isAllSelected = !this.state.isAllSelected;
        const handicapReviews = [...this.state.handicapReviews].map((hr) => ({ ...hr, selected: isAllSelected }));

        this.setState({
            selectedHandicapReviews: [],
            selectedGolfers: [],
            handicapReviews: handicapReviews,
            isAllSelected: isAllSelected,
        })
    }

    onSelectPage() {
        let handicapReviews = [...this.state.handicapReviews];
        let selectedHandicapReviews = [...this.state.selectedHandicapReviews];
        let isAllSelected = this.state.isAllSelected;

        const golfersAlreadySelected = handicapReviews.filter((hr) => selectedHandicapReviews.includes(hr.id));

        if (golfersAlreadySelected.length === this.state.handicapReviews.length && golfersAlreadySelected.length > 0) {
            selectedHandicapReviews = selectedHandicapReviews.filter((selectedGolferId) => !handicapReviews.map((c) => c.id).includes(selectedGolferId));
        } else {
            selectedHandicapReviews = [...new Set([...this.state.selectedHandicapReviews, ...handicapReviews.map((hr) => hr.id)])];
        }

        handicapReviews = handicapReviews.map((hr) => ({ ...hr, selected: selectedHandicapReviews.includes(hr.id) }));

        isAllSelected = selectedHandicapReviews.length === handicapReviews.length

        // Get all uniq golfer IDs
        let selectedGolfers = [...new Set(selectedHandicapReviews.map((hr) => hr.golfer_id))]

        this.setState({
            selectedGolfers: selectedGolfers,
            handicapReviews: handicapReviews,
            selectedHandicapReviews: selectedHandicapReviews,
            isAllSelected: isAllSelected
        });
    }

    applySelectedHandicapReviews(golfers) {
        const selectedGolfersIds = (golfers === undefined || golfers.length === 0) ? this.getSelectedGolfersIds() : golfers

        ClubService.applyHandicapReviewRecommendationForClub(
            this.props.club.id, selectedGolfersIds, {}
        ).then(() => {
            this.closeModal()
            this.reloadHandicapReviews();
        })
    }

    denySelectedHandicapReviews(golfers) {
        const selectedGolfersIds = (golfers === undefined || golfers.length === 0) ? this.getSelectedGolfersIds() : golfers

        ClubService.denyHandicapReviewRecommendationForClub(
            this.props.club.id, selectedGolfersIds, {}
        ).then(() => {
            this.closeModal()
            this.reloadHandicapReviews();
        })
    }

    getSelectedGolfersIds() {
        return this.state.handicapReviews.map((hr) => this.state.selectedHandicapReviews.includes(hr.id) ? hr.golfer_id : null)
            .filter((golfer_id) => golfer_id !== null)
    }

    openHandicapReviewDetailsModal(handicapReview) {
        GolferService.showGolfer(this.props.club.id, handicapReview.golfer_id)
            .then(
                (golfer_data) => {
                    this.setState({
                        handicapReviewDetailsModalIsOpen: true,
                        selectedHandicapReview: handicapReview,
                        golferId: handicapReview.golfer_id,
                        golfer: golfer_data['golfers'],
                    })
                }
            )
    }

    openHandicapReviewCreateConfirmationModal() {
        this.setState({
            handicapReviewCreateConfirmationModalIsOpen: true,
        })
    }

    openHandicapReviewActionConfirmationModal(action) {
        this.setState({
            handicapReviewActionConfirmationModalIsOpen: true,
            handicapReviewAction: action
        })
    }

    closeModal(reload) {
        this.setState({
            handicapReviewDetailsModalIsOpen: false,
            handicapReviewCreateConfirmationModalIsOpen: false,
            handicapReviewActionConfirmationModalIsOpen: false,
            handicapReviewAction: ''
        })

        if (reload) this.reloadHandicapReviews();
    }

    reloadHandicapReviews(history = true, reviews = true) {
        if (reviews) {
          this.setState({
            loadingPending: true,
            handicapReviews: [],
            selectedHandicapReviews: [],
            isAllSelected: false,
          })

          let params = this.getRequestParams(false);
          params['status'] = 'pending';

          ClubService.getHandicapReviews(this.props.club.id, params).then((data) => {
            this.setState({
              loadingPending: false,
              handicapReviews: data['handicap_review'],
              isReviewPending: data['handicap_review'].length > 0,
              total: parseInt(data['total-count'], 10),
              perPage: parseInt(data['per-page'], 10),
              pages: parseInt(data['total-pages'], 10)
            });
          });
        }
        
        if (history) {
          this.setState({
            loadingHandicapReviewsHistories: true,
            handicapReviewHistories: [],
            selectedHandicapReviewHistoryId: null
          })

          let params = this.getRequestParams(true);

          ClubService.getAllHandicapReviewHistories(this.props.club.id, params).then((data) => {
            this.setState({
              loadingHandicapReviewsHistories: false,
              handicapReviewHistories: data['handicap_review_history'],
              totalHistory: parseInt(data['total-count'], 10),
              perPageHistory: parseInt(data['per-page'], 10),
              pagesHistory: parseInt(data['total-pages'], 10)
            });
          });
        }
    }

    loadClubDetails()
    {
        let params = {
			page: 1,
			per_page: 1
		};

        ClubService.getClubRoster(this.props.club.golf_association_id, this.props.club.id, params).then((data) => {
			this.setState({
                golfersCountForHandicapReview: data.meta.active_golfers_count 
            })
		});
    }

      getRequestParams(history) {
        let params = {}
      
        if (history) {
          params = {
            page: (this.state.pageHistory + 1),
            perPage: this.state.perPageHistory
          }
        } else {
          params = {
            page: (this.state.page + 1),
            perPage: this.state.perPage
          }
        }

        if (this.state.sorted.length > 0) {
        	params['sorting_criteria'] = this.state.sorted[0].id;
        	params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
        }

        return params;
    }

    formatDate(dateString) {
        const date = new Date(dateString);

        const dateOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };

        const timeOptions = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        };

        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        return `${formattedDate} ${formattedTime}`;
    }

    isAnySelected() {
        if (this.state.isAllSelected) return true
        return this.state.selectedGolfers.length !== 0
    }

    generateGolferName(row) {
        return row.golfer_last_name + (row.golfer_suffix ? ' ' + row.golfer_suffix + ', ' : ', ') + (row.golfer_prefix ? row.golfer_prefix + ' ' : '') + row.golfer_first_name + ' ' + (row.golfer_middle_name ? row.golfer_middle_name : '');
    };

    render() {
        let su = this.props.user_type === "super-admin"

        const pendingHandicapReviewColumns = [
            {
                Header: '#',
                accessor: 'id',
                colClassName: 'col_id',
                show: false
            },
            {
                Header: 'Golfer ID',
                accessor: 'golfer_id',
                show: false
            },
            {
				Header: 'golfer_suffix',
				accessor: 'golfer_suffix',
				show: false
			},
			{
				Header: 'golfer_prefix',
				accessor: 'golfer_prefix',
				show: false
			},
			{
				Header: 'golfer_middle_name',
				accessor: 'golfer_middle_name',
				show: false
			},
			{
				Header: 'golfer_first_name',
				accessor: 'golfer_first_name',
				show: false
			},
            {
                Header: 'Golfer Name',
                accessor: 'golfer_last_name',
                Cell: props => (<Fragment>
                    {(su || !this.props.club.is_trial) ?
                        <NavLink to={this.golferPageUrl(props.original.golfer_id)}>{this.generateGolferName(props.row)}</NavLink>
                    : 
                    this.generateGolferName(props.row)
                    }
                </Fragment>),
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: <div>Recommended<br />Adjustment</div>,
                accessor: 'recommended_adjustment',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        (
                            props.original.recommended_adjustment > 0 ?
                                '+ ' + props.original.recommended_adjustment :
                                props.original.recommended_adjustment < 0 ?
                                    '- ' + Math.abs(props.original.recommended_adjustment) :
                                    props.original.recommended_adjustment
                        )
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Status',
                accessor: 'recommendation_applied',
                Cell: props => (
                    props.original.status === "Running" ?
                        "Calculating..."
                        :
                        (
                            props.original.recommendation_applied == null ?
                                'Pending' :
                                props.original.recommendation_applied === true ?
                                    'Applied' : 'Denied'
                        )

                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Applied by',
                accessor: 'applied_by',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.recommendation_applied == null ?
                            '-' : props.original.applied_by
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Date Applied',
                accessor: 'updated_at',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.recommendation_applied == null ?
                            '-' : this.formatDate(props.original.updated_at)
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: 'Expiration Date',
                accessor: 'expiration_date',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.expiration_date
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: 'Scores Posted',
                accessor: 'number_of_scores',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.number_of_scores
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'High Flags',
                accessor: 'number_of_flags_high',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.number_of_flags_high + ' (' + props.original.percentage_high.toFixed(1) + '%)'
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'Low Flags',
                accessor: 'number_of_flags_low',
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.number_of_flags_low + ' (' + props.original.percentage_low.toFixed(1) + '%)'
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'View Details',
                Cell: props => (
                    props.original.status === "Running" ?
                        <Fragment></Fragment>
                        :
                        props.original.can_show_details_button &&
                        <span className='col_edit-delete'>
                            <button
                                className={'btn lnk'}
                                onClick={() => {
                                    this.openHandicapReviewDetailsModal(props.original)
                                }}
                            >
                                Details
                            </button>
                        </span>),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
                sortable: false
            },
        ];

        const handicapReviewHistoryColumns = [
            {
                Header: 'Date & Time',
                Cell: props => (
                    this.formatDate(props.original.created_at)
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-3-of-6',
            },
            {
                Header: <div>Number of Golfers<br />Reviewed</div>,
                Cell: props => (
                    props.original.number_of_reviewed_golfers
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: <div>Golfers Ineligible for<br />Review</div>,
                Cell: props => (
                    props.original.number_of_ineligible_golfers
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: <div>Total Adjustments<br />Recommended</div>,
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.total_adjustments_recommended
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: <div>Pending<br />Adjustments</div>,
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.total_adjustments_pending
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: <div>Applied<br />Adjustments</div>,
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.total_adjustments_applied
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: <div>Denied<br />Adjustments</div>,
                Cell: props => (
                    props.original.status === "Running" ?
                        "-"
                        :
                        props.original.total_adjustments_denied
                ),
                className: 'table-align-center',
                thClassName: 'table-align-center is-2-of-6',
            },
            {
                Header: 'View Full Results',
                Cell: props => (
                    <span className='col_edit-delete'>
                        <NavLink className="btn lnk" to={`${this.props.match.url.split('/').slice(0, -1).join('/')}/full-results/${props.original.id}`}>
                            View Full Results
                        </NavLink>
                    </span>),
                className: 'table-align-center',
                thClassName: 'table-align-center is-3-of-6',
            },
        ];

        return (
            <Fragment>
                <div style={{ "margin": "10px" }}>
                    {getPermission(this.props.user_type, 'edit-club') &&
                        <div className="row">
                            <div className="col auto push-left">
                                <button
                                    disabled={this.state.isReviewPending || 
                                      this.props.club.status !== 'Active' || 
                                      this.state.golfersCountForHandicapReview === 0 || 
                                      this.props.club.authorized === false
                                    }
                                    onClick={() => this.openHandicapReviewCreateConfirmationModal()}
                                    className={this.state.isReviewPending ? "btn fill gray" : "btn fill green"}>
                                    New Handicap Review
                                </button>
                            </div>
                            <div className="col validation__message is-error" style={{ justifyContent: 'space-around' }}>
                                {
                                    this.state.isReviewPending &&
                                    <div>
                                        You must Apply or Deny all Pending Adjustments before running a new club-wide Handicap Review.
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="panel">
                        <div className="panel__head">
                            {"Pending Adjustments (" + this.state.total + ")"}
                        </div>
                        <div className="panel__body">
                            <div className="pre__table">
                                <div className="row">
                                    <div className="col auto">
                                        {getPermission(this.props.user_type, 'edit-club') &&
                                            <ul className="action__list push-left">
                                                <li>
                                                    <button className="btn fill green"
                                                        disabled={!this.isAnySelected() || this.state.handicapReviews.length === 0}
                                                        onClick={() => this.openHandicapReviewActionConfirmationModal('Apply')}>
                                                        Apply
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="btn fill green"
                                                        disabled={!this.isAnySelected() || this.state.handicapReviews.length === 0}
                                                        onClick={() => this.openHandicapReviewActionConfirmationModal('Deny')}>
                                                        Deny
                                                    </button>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                            <GhinTable
                                loading={this.state.loadingPending}
                                columns={pendingHandicapReviewColumns}
                                pages={this.state.pages}
                                page={this.state.page}
                                total={this.state.total}
                                pageSize={this.state.perPage}
                                isAllSelected={this.state.isAllSelected}
                                data={this.state.handicapReviews}
                                ref={(r) => this.table = r}
                                manual // - if true, all table functions like pagination, sorting, and filtering are handled manually (often server-side)
                                // hideSelect // - if present, hides the selection box 
                                onRowSelect={this.onSelectRow.bind(this)}
                                onSelectAll={this.onSelectAll.bind(this)}
                                onSelectPage={this.onSelectPage.bind(this)}
                                onPageChange={(pageIndex) => this.setState({ page: pageIndex }, () => this.reloadHandicapReviews(false, true))}
                                onSortedChange={(newSorted, column, shiftKey) => this.setState({sorted: newSorted}, () => this.reloadHandicapReviews(false, true))}
                                onPageSizeChange={(pageSize, pageIndex) => this.setState({
                                    perPage: pageSize,
                                    page: pageIndex
                                }, () => this.reloadHandicapReviews(false, true))}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ "margin": "10px" }}>
                    <div className="panel">
                        <div className="panel__head">
                            Handicap Review History
                        </div>
                        <div className="panel__body">
                            <GhinTable
                                loading={this.state.loadingHandicapReviewsHistories}
                                columns={handicapReviewHistoryColumns}
                                pages={this.state.pagesHistory}
                                page={this.state.pageHistory}
                                total={this.state.totalHistory}
                                pageSize={this.state.perPageHistory}
                                data={this.state.handicapReviewHistories}
                                ref={(r) => this.table = r}
                                manual // - if true, all table functions like pagination, sorting, and filtering are handled manually (often server-side)
                                hideSelect // - if present, hides the selection box 
                                onPageChange={(pageIndex) => this.setState({ pageHistory: pageIndex }, () => this.reloadHandicapReviews(true, false))}
                                onPageSizeChange={(pageSize, pageIndex) => this.setState({
                                    perPageHistory: pageSize,
                                    pageHistory: pageIndex
                                }, () => this.reloadHandicapReviews(true, false))}
                                sortable={false}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.handicapReviewDetailsModalIsOpen}
                    onRequestClose={() => { this.closeModal() }}
                    contentLabel="Modal"
                    portalClassName="modal"
                    overlayClassName="modal__overlay"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={false}
                >
                    <HandicapReviewDetailsModal
                        review={this.state.selectedHandicapReview}
                        golfer={this.state.golfer}
                        applyHandicapReview={() => { this.applySelectedHandicapReviews([this.state.golferId]) }}
                        denyHandicapReview={() => { this.denySelectedHandicapReviews([this.state.golferId]) }}
                        closeModal={() => { this.closeModal() }}
                        canEditClub={getPermission(this.props.user_type, 'edit-club')}
                        url={`${this.golferPageUrl(this.state.golferId)}/handicap-management`}
                    />
                </Modal>
                <ConfirmationModal
					openModal={this.state.handicapReviewCreateConfirmationModalIsOpen}
					onConfirmAction={() => this.performHandicapReview()}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={'Run Handicap Review'}
          bodyQuestion =
            <div style={{ textAlign: 'center', color: 'black' }}>
              Are you sure you want to run a club-wide<br /> Handicap Review on <strong>{this.state.golfersCountForHandicapReview.toLocaleString()}</strong> golfers?
            </div>
					cancelLabel={"Cancel"}
					confirmLabel={"Confirm"}
				/>
                <ConfirmationModal
					openModal={this.state.handicapReviewActionConfirmationModalIsOpen}
					onConfirmAction={() => {
                        this.state.handicapReviewAction === 'Apply' ? this.applySelectedHandicapReviews() : this.denySelectedHandicapReviews()
                    }}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={this.state.handicapReviewAction + ' Adjustment'}
          bodyQuestion =
            <div style={{ textAlign: 'center', color: 'black' }}>
              Are you sure you want to {this.state.handicapReviewAction.toLowerCase()} the recommended<br />
              adjustment for the selected golfer(s)?                        
            </div>
					cancelLabel={"Cancel"}
					confirmLabel={"Confirm"}
				/>
            </Fragment>
        );
    }

    // TODO: Download
    // handicapReviewDownload(url) {
    // 	return ClubService.getSpecialUpdateCsv(this.props.club.id, url).then(response => {
    // 		SharedService.downloadFile(response, "SpecialUpdates_" + moment().format('MM/DD/YYYY') + ".csv");
    // 		this.setState({
    // 			isHandicapReviewOpened: false
    // 		});
    // 	});
    // }

    // handleDownload(selectionType) {
    // 	this.setState({
    // 		isExportDataOpened: false
    // 	});
    // 	let params = {
    // 		...this.getRequestParams(),
    // 	};
    // 	delete params.page;
    // 	delete params.perPage;

    // 	if (selectionType === "selected") {
    // 		if (this.state.isAllSelected) {
    // 			params['select_all'] = true;
    // 			params['unchecked_items'] = this.state.unselectedHandicapReviews.join(",");
    // 		} else {
    // 			params['golfer_ids'] = this.state.selectedHandicapReviews.join(",");
    // 		}
    // 	}

    // 	return ClubService.getClubRosterExport(this.props.match.params.association_id, this.props.club.id, params).then(response => {
    // 		window.open(response.url, "_blank")
    // 	});

    // }
}

function mapStateToProps(state) {
    return {
        user_type: state.user.access.user_type
    };
}

export default connect(mapStateToProps, null)(HistoryTable);
