import React, { Component } from 'react';
import Modal from "react-modal";

class ClubWideHandicapReviewModal extends Component {

    constructor(props) {
        super(props);
        this.onConfirm = this.onConfirm.bind(this);
    }

    onConfirm() {
        this.props.onConfirmAction();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.openModal}
                contentLabel="Modal"
                portalClassName="modal"
                overlayClassName="modal__overlay"
                className="modal__content"
                bodyOpenClassName="modal--is-open"
                htmlOpenClassName="prevent-scroll"
                shouldCloseOnOverlayClick={true}
                shouldFocusAfterRender={false}
            >
                <div className="modal__container success narrow">
                    <div className="modal__head">
                        <div className={"success-icon"}>

                            <i className={`material-icons-outlined ${this.props.warning ? 'warning' : ''}`}>{this.props.warning ? 'find_in_page' : 'check'}</i>
                        </div>
                        <button className="modal__close" onClick={() => this.props.closeModal()}><i className="material-icons-outlined">clear</i></button>
                    </div>

                    <div className="modal__body">
                        <h2 className="modal__title">{this.props.warning ? this.props.warningTitle : 'Success!'}</h2>
                        {this.props.golfer && <>
                            <p>{this.props.golfer.first_name} {this.props.golfer.middle_name} {this.props.golfer.last_name} has been added successfully.</p>
                            <p style={{ 'margin-top': '10px' }}>GHIN Number: <span style={{ fontWeight: '700' }}>{this.props.golfer.id}</span></p>
                        </>}
                        {this.props.message && typeof this.props.message === "object" &&
                            <>
                                {this.props.message}
                                <br></br>
                                {this.props.showExportMessage && <p>To export these records for review, use the button below.</p>}

                            </>
                        }
                        <div className="actions" style={this.props.golfer ? { 'margin-top': '20px' } : {}}>
                            <div className="action">
                                <button type="button" onClick={(e) => { e.preventDefault(); window.location.href = `${this.props.resultsUrl}/handicap-review/history` }} className="btn fill blue">View results</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ClubWideHandicapReviewModal;
