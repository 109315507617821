import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import GolferService from "../../services/api/golfer";
import GhinTable from "../shared/GhinTable";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import HandicapReviewDetailsModal from '../Modals/HandicapReviewDetailsModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import moment from 'moment'
import { getAllPermissions } from '../../permissionManager';
import ClubService from '../../services/api/club';

class HandicapReview extends Component {
    constructor(props) {
		super(props);

		this.state = {
      golferId: null,
      golfer: this.props.golfer,
      loading: true,
      url: this.props.props.match.url,
      mostRecentReviewAvailable: false,
      mostRecentReviewRecommendedAdjustment: 0,
      mostRecentReview: null,
      selectedHandicapReview: null,
			handicapReviews: [],
      handicapReviewDetailsModalIsOpen: false,
      handicapReviewModalIsOpen: false,
      applyHandicapReviewModalIsOpen: false,
      isReviewPending: false,
      pendingReviewClubId: null, 
      allowedClubs: getAllPermissions(this.props.props.rawAccess).clubs,
      allowedAssociations: getAllPermissions(this.props.props.rawAccess).associations,
      page: 0,
      perPage: 25,
      pages: null,
      total: null,
      sorted: null,
      authorized_club: true,
		};

    this.openHandicapReviewDetailsModal = this.openHandicapReviewDetailsModal.bind(this);
    this.openHandicapReviewModal = this.openHandicapReviewModal.bind(this);
    this.openApplyHandicapReviewModal = this.openApplyHandicapReviewModal.bind(this);
	}

  isSuperUser()
  {
    return this.props.props.rawAccess.super_user === "true"
  }

	componentDidMount() {
		Modal.setAppElement('body');
		this.loadHandicapReviews();

    ClubService.getClub(this.props.golfer.association_id, this.props.golfer.club_id).then(
      data => this.setState({authorized_club: data.authorized})
    )
	}

	loadHandicapReviews() {
    this.setState({loading: true})

    let {golfer_id} = this.props.props.match.params;
		if(!golfer_id.match(/^[0-9]*$/)) {
			golfer_id = window.atob(golfer_id);
		}

    this.setState({golferId: golfer_id})

    const params = []
    params['per_page'] = this.state.perPage
    params['page'] = this.state.page + 1

    if (this.state.sorted?.length > 0) {
			params['sorting_criteria'] = this.state.sorted[0].id;
			params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		}

		GolferService.getAllHandicapReviews(golfer_id, params).then(res => {
			this.setState({
				handicapReviews: res.handicap_review.map(
          obj => ({...obj, most_recent: obj.id === res['recently_applied']})
        ),
        loading: false,
        total: parseInt(res['total-count'], 10),
        perPage: parseInt(res['per-page'], 10),
        pages: parseInt(res['total-pages'], 10),
      });
      
      this.saveLastReviewAvailable();
      this.saveReviewPending();
		}).catch(error => {
      console.error(error)
		});
	}

	createNewHandicapReview(golferId, clubId) {
    return GolferService.createHandicapReview(golferId, clubId).then(() => {
      this.closeModal()
      this.props.updateGolfer()
    }).catch(() => {
      this.closeModal()
    }).finally(() => {
      this.setState({loading: false})
    });
	}

  applyHandicapReview(golferId, clubId) {
    return GolferService.applyHandicapReview(golferId, clubId).then(() => {
      this.closeModal();
      this.props.updateGolfer();
    }).finally(() => {
      this.setState({loading: false})
    });
	}

  denyHandicapReview(golferId, clubId) {
    return GolferService.denyHandicapReview(golferId, clubId).then(() => {
      this.closeModal();
      this.props.updateGolfer();
    }).finally(() => {
      this.setState({loading: false})
    });
	}

  openHandicapReviewDetailsModal() {
    this.setState({handicapReviewDetailsModalIsOpen: true});
  }

  openHandicapReviewModal() {
    this.setState({handicapReviewModalIsOpen: true});
  }

  openApplyHandicapReviewModal() {
    this.setState({applyHandicapReviewModalIsOpen: true});
  }

	closeModal(reload) {
		this.setState({
      handicapReviewDetailsModalIsOpen: false,
      handicapReviewModalIsOpen: false,
      applyHandicapReviewModalIsOpen: false,
    });

    if(reload) this.loadHandicapReviews();
	}

  saveReviewPending()
  {
    let pendingReview = this.state.handicapReviews.filter((hr) => hr.recommendation_applied === null)[0]

    this.setState({
      isReviewPending: pendingReview != null,
      pendingReviewClubId: pendingReview != null ? pendingReview.club_id : null
    })
  }

  saveLastReviewAvailable()
  {
    // The reviews must be sorted desc by date
    let review = this.state.handicapReviews.filter(review => review.recommendation_applied === null)[0];

    // recommendation_applied:
    // null => not applied / denied
    // true => applied
    // false => denied
    this.setState({
      mostRecentReview: review,
      mostRecentReviewAvailable: review?.recommendation_applied === null,
      mostRecentReviewRecommendedAdjustment: review?.recommended_adjustment
    });
  }

  parseReviewStatus(status)
  {
    if(status === null) return "-";
    return status ? 'Yes' : 'No'
  }

	render() {
    const columnsPending = [
      // {
      //   Header: 'Club ID',
			// 	accessor: 'club_id',
      //   Cell: props => props.original.club_id ? props.original.club_id : '-',
			// 	className: 'table-align-center',
			// 	thClassName: 'table-align-center is-3-of-7',
      // },
			// {
      //   Header: 'Club ID',
			// 	accessor: 'club_id',
      //   Cell: props => props.original.club_id ? props.original.club_id : '-',
			// 	className: 'table-align-center',
			// 	thClassName: 'table-align-center is-3-of-7',
      // },
			{
				Header: 'Date & Time',
				accessor: 'review_date',
        Cell: props => (
          moment(props.original.review_date).format('MM/DD/YYYY HH:mm:ss')
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-3-of-7'
			},
			{
				Header: 'Scores Posted',
				accessor: 'number_of_scores',
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6'
			},
			{
				Header: 'Total High Flags',
				accessor: 'number_of_flags_high',
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6'
			},
      {
				Header: 'Total Low Flags',
				accessor: 'number_of_flags_low',
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6'
			},
      {
				Header: '% High Flags',
				accessor: 'percentage_high',
        Cell: props => props.original.percentage_high.toFixed(1) + "%",
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6'
			},
      {
				Header: '% Low Flags',
				accessor: 'percentage_low',
        Cell: props => props.original.percentage_low.toFixed(1) + "%",
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6'
			},
      {
				Header: 'Adjustment',
				accessor: 'recommended_adjustment',
        Cell: props => (
          props.original.recommended_adjustment > 0 ?
            '+ ' + props.original.recommended_adjustment :
            props.original.recommended_adjustment < 0 ?
              '- ' + Math.abs(props.original.recommended_adjustment) :
              props.original.recommended_adjustment
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6'
			},
      {
				Header: 'Details',
				Cell: props => (
        props.original.can_show_details_button &&
        <span className='col_edit-delete'>
          <button
            className={'btn lnk'}
            onClick={() => {
              this.setState({
                handicapReviewDetailsModalIsOpen: true,
                selectedHandicapReview: props.original
              })
            }}
          >
            Details
          </button>
				</span>),
				thClassName: 'table-align-center is-2-of-5'
			}
		];

		const columnsHistory = [
      {
				accessor: 'most_recent',
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-7',
        show: false
			},
			{
				Header: 'Date & Time',
        sortable: true,
				accessor: 'review_date',
        Cell: props => (
          moment(props.original.review_date).format('MM/DD/YYYY HH:mm:ss')
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-4-of-7',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
			{
				Header: 'Scores Posted',
        sortable: false,
				accessor: 'number_of_scores',
        Cell: props => (
          props.original.number_of_scores === null ? '-' : props.original.number_of_scores
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
			{
				Header: 'Total High Flags',
        sortable: false,
				accessor: 'number_of_flags_high',
        Cell: props => (
          props.original.number_of_flags_high === null ? '-' : props.original.number_of_flags_high
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: 'Total Low Flags',
        sortable: false,
				accessor: 'number_of_flags_low',
        Cell: props => (
          props.original.number_of_flags_low === null ? '-' : props.original.number_of_flags_low
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: '% High Flags',
        sortable: false,
				accessor: 'percentage_high',
        Cell: props => props.original.percentage_high.toFixed(1) + "%",
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: '% Low Flags',
        sortable: false,
				accessor: 'percentage_low',
        Cell: props => props.original.percentage_low.toFixed(1) + "%",
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: 'Adjustment',
        sortable: false,
				accessor: 'recommended_adjustment',
        Cell: props => (
          props.original.recommended_adjustment > 0 ?
            '+ ' + props.original.recommended_adjustment :
            props.original.recommended_adjustment < 0 ?
              '- ' + Math.abs(props.original.recommended_adjustment) :
              props.original.recommended_adjustment
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-6',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: 'Applied',
        sortable: false,
				accessor: 'recommendation_applied',
				Cell: props => this.parseReviewStatus(props.original.recommendation_applied),
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-5',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: 'Date & Time Applied',
        sortable: true,
				accessor: 'updated_at',
        Cell: props => (
          moment(props.original.updated_at).format('MM/DD/YYYY HH:mm:ss')
        ),
				className: 'table-align-center',
				thClassName: 'table-align-center is-3-of-5',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			},
      {
				Header: '',
        sortable: false,
				Cell: props => (
        props.original.can_show_details_button &&
        <span className='col_edit-delete'>
          <button
            className={'btn lnk'}
            onClick={() => {
              this.setState({
                handicapReviewDetailsModalIsOpen: true,
                selectedHandicapReview: props.original
              })
            }}
          >
            Details
          </button>
				</span>),
				thClassName: 'table-align-center is-1-of-5',
        highlightColumn: true,
        highlightConditionKey: "most_recent"
			}
		];

    const displayCondition = (process.env.REACT_APP_TYPE !== "hv" && !this.props.publicView) && 
      !this.props.archived && 
      this.props.canEditClub &&
      this.props.golfer?.status === "Active" &&
      this.state.authorized_club === true

		return (
      <Fragment>
        <div className="panel">
          <div className="panel__head">
            Most Recent Handicap Review
          </div>
          <div className="panel__body">
              <div className="row">
                <div className="col">
                  To initiate a new handicap review for this golfer, press the button below.
                </div>
              </div>
            <div className="row">
              <div className="col is-full">
                  <div className="row">
                    <div className="col auto push-left">
                    <button
                        disabled={this.state.isReviewPending || !displayCondition}
                        onClick={() => this.openHandicapReviewModal()}
                        className={this.state.isReviewPending ? "btn fill gray" : "btn fill green"}>
                        New Handicap Review
                      </button>
                    </div>
                    <div className="col validation__message is-error" style={{justifyContent: 'space-around'}}>
                      {
                        displayCondition && this.state.mostRecentReviewAvailable &&
                        <div>
                          You must Apply or Deny the pending recommended adjustments prior to initiating a new Handicap Review.
                        </div>
                      }
                    </div>
                  </div>
                <div className="row">
                  <div className="col">
                    {
                      displayCondition && this.state.mostRecentReviewAvailable && 
                      <GhinTable
                        loading={this.state.loading}
                        columns={columnsPending}
                        ref={(r) => this.table = r}
                        hideSelect
                        hidePagination={true}
                        data={this.state.handicapReviews.filter((hr) => hr.recommendation_applied === null)}
                        sortable={false}
                      />
                    }
                  </div>
                </div>
                {displayCondition && this.state.mostRecentReviewAvailable &&
                  <div className="row">
                    <div className="col auto push-right">
                      <button
                        onClick={this.openApplyHandicapReviewModal}
                        className={this.state.isReviewPending ? "btn fill green" : "btn fill gray"}
                        disabled={!this.state.isReviewPending || (!this.isSuperUser() && !this.state.allowedClubs?.includes(this.state.pendingReviewClubId) && !this.state.allowedAssociations?.includes(this.props.golfer.association_id))}
                      >
                        Apply
                      </button>
                    </div>
                    <div className="col auto">
                      <button
                        onClick={() => this.denyHandicapReview(this.state.golferId, this.state.golfer.club_id)}
                        className={this.state.isReviewPending ? "btn fill green" : "btn fill gray"}
                        disabled={!this.state.isReviewPending || (!this.isSuperUser() && !this.state.allowedClubs?.includes(this.state.pendingReviewClubId) && !this.state.allowedAssociations?.includes(this.props.golfer.association_id))}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="panel">
          <div className="panel__head">
            Handicap Review History
          </div>
          <div className="panel__body">
            <div className="row">
              <div className="col is-full">
                <div className="row">
                  <div className="col push-left">
                    <div>
                      To view the details of a specific Handicap Review, press the "Details" link.
                    </div>
                  </div>
                  <div className="col auto push-right">
                  <ul className="table__legend">
                    <li><span className="legend-item yellow_highlight"></span>Most Recently Applied Adjustment</li>
                  </ul>
                  </div>
                </div>
								<div className="row">
									<div className="col">
										<GhinTable
											loading={this.state.loading}
											columns={columnsHistory}
											ref={(r) => this.table = r}
											hideSelect
                      pages={this.state.pages}
                      page={this.state.page}
                      total={this.state.total}
                      pageSize={this.state.perPage}
                      manual
                      sortable={true}
                      onSortedChange={(newSorted, column, shiftKey) => this.setState({sorted: newSorted}, () => this.loadHandicapReviews())}
                      onPageChange={(pageIndex) => this.setState({
                        page: pageIndex
                      }, () => this.loadHandicapReviews())}
                      onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        perPage: pageSize,
                        page: pageIndex
                      }, () => this.loadHandicapReviews())}
											data={
                        this.state.handicapReviews.filter(
                          (hr) => hr.recommendation_applied != null
                        )
                      }
										/>
									</div>
								</div>
              </div>
            </div>
          </div>
        </div>
				<Modal
					isOpen={this.state.handicapReviewDetailsModalIsOpen}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<HandicapReviewDetailsModal
            review={this.state.selectedHandicapReview}
            golfer={this.state.golfer}
            applyHandicapReview={() => {this.applyHandicapReview(this.state.golferId, this.state.golfer.club_id)}}
            denyHandicapReview={() => {this.denyHandicapReview(this.state.golferId, this.state.golfer.club_id)}}
            closeModal={() => {this.closeModal()}}
            allowedClubs={this.isSuperUser() ? null :this.state.allowedClubs}
            canEditClub={this.props.canEditClub}
            url={`${this.state.url}/handicap-management`}
          />
				</Modal>
        <ConfirmationModal
            openModal={this.state.handicapReviewModalIsOpen}
            onConfirmAction={() => this.createNewHandicapReview(this.state.golfer.id, this.state.golfer.club_id)}
            onCancelAction={() => {this.closeModal()}}
            closeModal={() => {this.closeModal()}}
            question={'Are You Sure?'}
            bodyQuestion={`Are you sure you want to perform a new Handicap Review for ${this.props.golfer.player_name}?`}
            cancelLabel={"Cancel"}
            confirmLabel={"Confirm"}
        />
        <ConfirmationModal
            openModal={this.state.applyHandicapReviewModalIsOpen}
            onConfirmAction={() => this.applyHandicapReview(this.state.golfer.id, this.state.golfer.club_id)}
            onCancelAction={() => {this.closeModal()}}
            closeModal={() => {this.closeModal()}}
            question={'Are You Sure?'}
            bodyQuestion={`Are you sure you want to apply this Handicap Review for ${this.props.golfer.player_name}?`}
            cancelLabel={"Cancel"}
            confirmLabel={"Confirm"}
        />
      </Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(null, mapDispatchToProps)(HandicapReview);
