import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import TableFilter from './TableFilter';
import GhinTable from "../../../shared/GhinTable";
import ExportDataModal from "../../../Modals/ExportDataModal";
import moment from "moment";
import AssociationService from "../../../../services/api/association";
import SharedService from "../../../../services/api/shared";
import EditClubStatusModal from "../../../Modals/EditClubStatusModal";
import Modal from "react-modal";
import {connect} from "react-redux";
import ChangeClubsMembershipTypeModal from "../../../Modals/ChangeClubsMembershipTypeModal";
import {AddClubObservable} from "../../../../services/observables/AddClubObservable";
import SpecialUpdateModal from "../../../Modals/SpecialUpdateModal";
import ClubService from "../../../../services/api/club";
import TableService from "../../../../services/tableService";
import JoinAndRenewServices from '../../../../services/api/joinAndRenew';
import {formatPhoneNumber} from "../../../utils";

class Table extends Component {

    constructor() {
        super();

        this.state = {
            tableFilterIsHidden: false,
            associations: [],
            test_assoc: undefined,
						trial_assoc: undefined,
            join_and_renew_enabled: false,
            filtered: [],
            sorted: [{id: "club_name", desc: false}],
            isAllSelected: false,
            clubs: [],
			membershipTypes: [],
            club_groups: [],
            page: 0,
            per_page: 25,
            isExportDataOpened: false,
            isSpecialUpdateOpened: false,
            selectedClubs: [],
			unselectedClubs: [],
            tableInternalData: [], //used to get filtered array from the table
			activateModalOpened: false,
			membershipModalOpened: false
        };
        this.handleDownload = this.handleDownload.bind(this);
	}

    toggleTableFilter() {
        this.setState({
            tableFilterIsHidden: !this.state.tableFilterIsHidden
        })
    }

    getClubRequestParams() {
        let params = {
            page: (this.state.page) + 1,
            per_page: this.state.per_page
        };

        if (this.state.sorted.length > 0) {
            params['sorting_criteria'] = this.state.sorted[0].id;
            params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
        }

        params = {
            ...params,
            ...TableService.filtersArrayToParams(this.state.filtered)
        };
        return params;
    }

    isTestAssoc() {
      AssociationService.getAssociation(this.props.match.params.id)
      .then((data) => {
        this.setState({
          test_assoc: data.is_test,
					trial_assoc: data.is_trial
        });
      })
    }
    
    jrSetting() {
      JoinAndRenewServices
        .getSettings(this.props.match.params.id).then((res) => {
          const settings = res.settings
          this.setState({
            join_and_renew_enabled: settings.join_and_renew_enabled,
          })
        })
    }

    loadClubList() {
        this.setState({
            loading: true,
			clubs: []
        });

        const params = this.getClubRequestParams();
        AssociationService.getClubs(this.props.match.params.id, params)
			.then((data) => {
				let selectedClubs = this.state.selectedClubs;
				let clubs = data.clubs.map((i) => {

					    let selected = false;
					    if (this.state.isAllSelected) {
					    	selected = !this.state.unselectedClubs.includes(i.id);
						} else {
					    	selected = this.state.selectedClubs.includes(i.id);
						}
						return {
							...i,
							selected,
							date: moment(i.status_date).format("MM/DD/YYYY"),
							phone: formatPhoneNumber(i.phone)
						}
					});
				this.setState({
					loading: false,
					clubs,
					selectedClubs: [...new Set(selectedClubs)],
					total: parseInt(data['total-count'], 10),
					per_page: parseInt(data['per-page'], 10),
					pages: parseInt(data['total-pages'], 10),
					inactive: parseInt(data['total-count'], 10) - parseInt(data.meta['active_clubs_count'], 10),
					active: parseInt(data.meta['active_clubs_count'], 10),
				})
				})
			.catch(err => {
				console.error(err);
			});
    }

    loadClubGroups() {
        AssociationService.getClubGroups(this.props.match.params.id, {include_clubs: 'false'}).then((data) => {
            this.setState({
                club_groups: data.club_groups.map((item) => {
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                })
            })
        })
    }

    loadMembershipTypes() {
		AssociationService.getMembershipTypes(this.props.match.params.id, {include_used_membership: 'false'})
			.then((res=>{
				this.setState({
				membershipTypes: res.membership_types.map(membershipType => {
					return {label: membershipType.code + ' - ' + membershipType.description, value: membershipType.id};
					}).sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
				})
			}))
			.catch(err => {
				console.error(err);
			})
	}

    componentDidMount() {
    	this.loadMembershipTypes();
      this.loadClubGroups();
      // this.loadClubList(); no need to load
      this.isTestAssoc();
      this.jrSetting()
      this.setState({
        AddClubSubscription: AddClubObservable.get().subscribe(this.processNewClub.bind(this))
      })
    }

	processNewClub = function(associationId) {
		if (associationId?.toString() === this.props.match.params.id) {
			this.loadClubList()
		}
	};

    componentWillUnmount() {
    	this.state.AddClubSubscription.unsubscribe();
	}

	showExportModal  = () => {
        const filteredData = this.table.getFilteredData();
        this.setState({
            tableInternalData: filteredData,
            isExportDataOpened: true
        })
    };

    showEditStatusModal = (type) => {

    	this.setState({
			activateModalOpened: type
		});
	};

	updateSpecialUpdateModalState = (state) => {
    	this.setState({ isSpecialUpdateOpened: state });
	};

	performSpecialUpdate() {

		let params = {
			...this.getClubRequestParams(),
		};
		delete params.page;
		delete params.per_page;

		let clubIds = this.state.selectedClubs;
		if (this.state.isAllSelected) {
			clubIds = this.state.unselectedClubs;
		} else {
			params = {};
		}

		this.setState({
			selectedClubs: [],
			isAllSelected: false,
			unselectedClubs: []
		});

		this.loadClubList();
		if(this.state.isAllSelected) {
			return ClubService.specialUpdate(this.props.match.params.id, clubIds, true, params);
		} else {
			return ClubService.specialUpdate(this.props.match.params.id, clubIds, false, params);
		}

	}

	showChangeMembershipModal(type) {
		this.setState({
			membershipModalOpened: type
		});
	}

    closeModal(data){
		this.setState({
			activateModalOpened: false,
			membershipModalOpened: false,
			selectedClubs: data ? [] : this.state.selectedClubs
		});
    	if(data) {
			this.loadClubList();
		}
	}

    updateFilter(filtered) {
        this.setState({
            filtered,
            page: 0
        }, () => {
            this.loadClubList();
        });
    };

    onRowSelect(row) {

        let selectedClubs = [...this.state.selectedClubs];
        let unselectedClubs = [...this.state.unselectedClubs];
		const isAllSelected = this.state.isAllSelected;
		let isSelected = true;

		if (isAllSelected) {
			if (unselectedClubs.includes(row.id)) {
				unselectedClubs.splice(unselectedClubs.indexOf(row.id), 1);
			} else {
				isSelected = false;
				unselectedClubs.push(row.id);
			}
		} else {
			if (selectedClubs.includes(row.id)) {
				selectedClubs.splice(selectedClubs.indexOf(row.id), 1);
				isSelected = false;
			} else {
				selectedClubs.push(row.id);
			}
		}

        let clubs = [...this.state.clubs].map((i) => {
            if (i.id === row.id) {
                return {
                    ...i,
                    selected: isSelected
                }
            } else {
                return i;
            }
        });

        this.setState({
            clubs,
            selectedClubs,
			unselectedClubs
        });
    }

    render() {
        const columns = [
            {
                accessor: 'club_category',
                show: false

            },
            {
                accessor: 'club_type',
                show: false
            },
			{
				accessor: 'technology_provider',
				show: false
			},
			{
				accessor: 'membership_code',
				show: false
			},
			{
				accessor: 'id',
				show: false
			},
            {
                Header: '#',
                accessor: 'id',
                colClassName: 'col_id'
            },
            {
                Header: 'Club Name',
                accessor: 'club_name',
                filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
                Cell: props => <Fragment>
                    <NavLink to={`/manage/association/${this.props.match.params.id}/club/${props.row.id}`}>{props.value}</NavLink>
                </Fragment>,
                colClassName: 'col_club-name'
            },
            {
                Header: 'Status',
                accessor: 'status',
                colClassName: 'col_status'
            },
            {
                Header: 'Date',
                accessor: 'date',
                colClassName: 'col_date'
            },
            {
                Header: 'Type',
                accessor: 'club_type',
                colClassName: 'col_type'
            },
            {
                Header: 'City',
                accessor: 'city',
                colClassName: 'col_city'
            },
            {
                Header: 'State',
                accessor: 'state',
                colClassName: 'col_city'
            },
            {
                Header: 'Email',
                accessor: 'email',
                colClassName: 'col_email',
				Cell: row => <Fragment>
					<a href={`mailto: ${row.value}`}>{row.value}</a>
				</Fragment>,
				sortable: false
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                sortable: false,
                colClassName: 'col_country'
            }
        ];

        let disableSelectedExport = true;

        if (this.state.isAllSelected) {
        	if (this.state.unselectedClubs.length > 0) {
        		disableSelectedExport = false;
			} else {
        		disableSelectedExport = true;
			}
		} else {
        	if (this.state.selectedClubs.length > 0) {
        		disableSelectedExport = false;
			} else {
        		disableSelectedExport = true;
			}
		}
        return (
            <Fragment>

                { this.state.test_assoc !== undefined && <TableFilter updateFiltered={this.updateFilter.bind(this)}
							 hideFilters={this.state.tableFilterIsHidden}
							 membershipTypes={this.state.membershipTypes}
							 club_groups={this.state.club_groups}
               test_assoc={this.state.test_assoc}
							 trial_assoc={this.state.trial_assoc}
							 join_renew_enabled={this.state.join_and_renew_enabled}/> }

                <div className="pre__table">
                    <div className="row">

                        <div className="col auto">
                            <ul className="action__list push-left">
                                <li>
									<button className="btn fill green"
											onClick={(event) => {event.stopPropagation();this.showExportModal();}}>Export to Excel</button>
                                </li>
								{((this.props.canEditAssociation && process.env.REACT_APP_TYPE !== "hv") || (this.props.access.super_user)) &&
									<Fragment>
										<li>
											<button className="btn fill green"
													disabled={!this.state.selectedClubs.length && !this.state.isAllSelected}
													onClick={() => this.showEditStatusModal('activate')}>Activate</button>
										</li>
										<li>
											<button className="btn fill green"
													disabled={!this.state.selectedClubs.length && !this.state.isAllSelected}
													onClick={() => this.showEditStatusModal('inactivate')}>Inactivate</button>
										</li>
										<li>
											<button className="btn fill green"
													disabled={!this.state.selectedClubs.length && !this.state.isAllSelected}
													onClick={() => this.showChangeMembershipModal('apply')}>Apply Membership Types</button>
										</li>
										<li>
											<button className="btn fill green"
													disabled={!this.state.selectedClubs.length && !this.state.isAllSelected}
													onClick={() => this.showChangeMembershipModal('remove')}>Remove Membership Types</button>
										</li>
                                        <li>
    										<button className="btn fill green"
    											// disabled={!this.state.selectedClubs.length}
    											onClick={() => this.updateSpecialUpdateModalState(true)}>Special Update</button>
    								    </li>
									</Fragment>
								}
                            </ul>
                        </div>

                        <div className="col auto push-right">
                            <div className="row">
                                <div className="col">
                                    <ul className="association__status">
                                        <li>Active = <strong>{this.state.active}</strong></li>
                                        <li>Inactive = <strong>{this.state.inactive}</strong></li>
                                        <li>Total = <strong>{this.state.total}</strong></li>
                                    </ul>
                                </div>
                                <div className="col auto">
                                    {!this.state.tableFilterIsHidden &&
                                    <button onClick={this.toggleTableFilter.bind(this)} className="btn fill gray">Hide
                                        Filters <i className="material-icons-outlined">remove</i></button>}
                                    {this.state.tableFilterIsHidden &&
                                    <button onClick={this.toggleTableFilter.bind(this)} className="btn fill gray">Show
                                        Filters <i className="material-icons-outlined">add</i></button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<div className={'row'}><div className={'col'}><span className={"bold"}> <span className={"red"}>Important:</span>  When using the "Select All" checkbox, any action being performed will be taken on all records on all pages, not just the current page.</span></div></div>
                <GhinTable
                    loading={this.state.loading}
                    columns={columns}
                    pages={this.state.pages}
					page={this.state.page}
                    manual
                    total={this.state.total}
                    pageSize={this.state.per_page}
                    isAllSelected={this.state.isAllSelected}
                    data={this.state.clubs}
                    ref={(r) => this.table = r}
                    onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadClubList())}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        per_page: pageSize,
                        page: pageIndex
                    }, () => this.loadClubList())}
					onSelectPage={() => {
						let clubs = [...this.state.clubs];
						let selectedClubs = [...this.state.selectedClubs];
						/**
						 * if all the clubs on the page are already selected, then remove them
						 */
						const clubsAlreadySelected = clubs.filter((club) => selectedClubs.includes(club.id));
						if (clubsAlreadySelected.length === this.state.clubs.length && clubsAlreadySelected.length > 0 ) {
							//remove club from selected items
							selectedClubs = selectedClubs.filter((selectedClub) => !clubs.map((c) => c.id).includes(selectedClub));
						} else {
							//mark all clubs as selected, even the ones that were not selected
							selectedClubs = [...new Set([...this.state.selectedClubs, ...clubs.map((club) => club.id)])];
						}

						clubs = clubs.map((club) => ({...club, selected: selectedClubs.includes(club.id)}));
						const unselectedClubs = [];
						this.setState({
							unselectedClubs,
							selectedClubs,
							clubs
						});
					}}

                    onSelectAll={() => {
                    	const isAllSelected = !this.state.isAllSelected;
                    	const unselectedClubs = [];
                    	const selectedClubs = [];
                        const clubs = [...this.state.clubs].map((i) => ({...i, selected: isAllSelected}));


                        this.setState({
                            selectedClubs,
							isAllSelected,
							unselectedClubs,
							clubs
                        })

                    }}
                    sorted={this.state.sorted}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        this.setState({
                            sorted: newSorted,
							page: 0
                        }, () => {
                            this.loadClubList()
                        })
                    }}
                    onRowSelect={this.onRowSelect.bind(this)}
                />


                <ExportDataModal
                    filename={"export.csv"}
                    columns={columns}
                    isOpen={this.state.isExportDataOpened}
                    onRequestClose={() => this.setState({
                        isExportDataOpened: false
                    })}
					isAllSelected={this.state.isAllSelected}
                    data={this.state.tableInternalData}
					disableSelected={disableSelectedExport}
					onDownloadClick={(data) => this.handleDownload(data)}
                />
				<Modal
					isOpen={this.state.activateModalOpened ? true : false}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
                	<EditClubStatusModal closeModal={(data)=>{this.closeModal(data)}}
										 clubs={this.state.isAllSelected ? this.state.unselectedClubs : this.state.selectedClubs}
										 filters={this.getClubRequestParams()}
										 isAllSelected={this.state.isAllSelected}
										 assoc={this.props.match.params.id}
										 type={this.state.activateModalOpened}/>
				</Modal>
				<Modal
					isOpen={this.state.isSpecialUpdateOpened}
					onRequestClose={() => this.updateSpecialUpdateModalState(false)}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<SpecialUpdateModal closeModal={()=>{this.updateSpecialUpdateModalState(false)}}
										confirmAction={() => this.performSpecialUpdate()}
										onDownloadClick={(url) => this.specialUpdateDownload(url)}
										title={`Special Update ${this.state.selectedClubs.length > 0 ? this.state.selectedClubs.length + " clubs" : "all clubs"}`}
										description={ this.state.selectedClubs.length > 0 ? "You are about to perform a Special Update for ALL ACTIVE GOLFERS in the SELECTED CLUBS within the association." : "You are about to perform a Special Update for ALL ACTIVE GOLFERS in ALL ACTIVE CLUBS within the association."}
					/>
				</Modal>

				<Modal
					isOpen={this.state.membershipModalOpened ? true : false}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ChangeClubsMembershipTypeModal closeModal={(data)=>{this.closeModal(data)}}
													type={this.state.membershipModalOpened}
													filters={this.getClubRequestParams()}
													isAllSelected={this.state.isAllSelected}
													clubs={this.state.isAllSelected ? this.state.unselectedClubs : this.state.selectedClubs}
													association={this.props.association}/>
				</Modal>
            </Fragment>
        );
    }


	specialUpdateDownload(url) {
		return AssociationService.getSpecialUpdateCsv(this.props.match.params.id, url).then(response => {
				SharedService.downloadFile(response, "SpecialUpdates_" + moment().format('MM/DD/YYYY') + ".csv");
				this.setState({
					isSpecialUpdateOpened: false
				});
			});
	}

	handleDownload(selectionType) {
		this.setState({
			isExportDataOpened: false
		});
		let params = {
			...this.getClubRequestParams(),
			download: true,
		};
		delete params.page;
		delete params.per_page;

		if (selectionType === "selected") {
			if (this.state.isAllSelected) {
				params['select_all'] = true;
				params['unchecked_items'] = this.state.unselectedClubs.join(",");
			} else {
				params['clubs_ids'] = this.state.selectedClubs.join(",");
			}
		}

		return AssociationService.getClubsCsv(this.props.match.params.id, params).then(response => {
				SharedService.downloadFile(response, "ClubsList_" + moment().format('MM/DD/YYYY') + ".csv");
			});

	}
}


function mapStateToProps(state) {
	return {
		access: state.user.access
	};
}

export default connect(mapStateToProps, null)(Table);
