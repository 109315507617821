import React, { Component, Fragment } from 'react';
import { NavLink, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import Header from '../../shared/Header';
import Navigation from '../../shared/Navigation';
import BreadCrumbsNav from '../../shared/BreadCrumbsNav';
import Footer from '../../shared/Footer';

import Roster from './Roster';
import AssociationService from "../../../services/api/association";
import ClubService from "../../../services/api/club";

import Account from './Account';
import GolferGroups from './GolferGroups';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateClub } from "./Account/Actions";
import { getPermission, getAllAccesses, checkAccess } from "../../../permissionManager";
import PageHead from "../../shared/PageHead";
import AuditLog from "./AuditLog/";
import NotFound from "../../NotFound/NotFound";
import Loader from "../../NotFound/Loader";
import JoinAndRenew from './JoinAndRenew';
import GolferProducts from './GolferProducts';
import JoinAndRenewServices from '../../../services/api/joinAndRenew';
import ReactTooltip from 'react-tooltip';
import HandicapReview from './HandicapReview';

class Club extends Component {
  constructor(props) {
    super(props);

        this.state = {
          association: null,
          associationJoinRenewEnabled: false,
          club: null,
          AddGolferModalIsOpen: false,
          AddClubModalIsOpen: false,
          AddAssocModalIsOpen: false,
          updateRosterList: false,
          joinClubSettings: null,
          shouldLoadClubSetting: true,
          loadingClubSettings: false,
          pendingGolfersCount: null,
          shouldLoadPendingGolfersCount: true,
          isLoading: true,
          challenge_name: null,
        };

        this.getPendingGolfersCount = this.getPendingGolfersCount.bind(this);
        this.openGolferAddModal = this.openGolferAddModal.bind(this);
        this.openClubAddModal = this.openClubAddModal.bind(this);
        this.openAssocAddModal = this.openAssocAddModal.bind(this);
        this.updateChallenge = this.updateChallenge.bind(this);

        this.closeModal = this.closeModal.bind(this);

    }

  openGolferAddModal() {
    this.setState({ AddGolferModalIsOpen: true });
  }

  openClubAddModal() {
    this.setState({ AddClubModalIsOpen: true });
  }

  openAssocAddModal() {
    this.setState({ AddAssocModalIsOpen: true });
  }

  closeModal(data) {
    if (data && data.type === "golfer") {
      this.props.history.push(`/manage/association/${data.golfer.association_id}/club/${data.golfer.club_id}/golfer/${data.golfer.id}`);
    }
    if (data && data.type === "club") {
      this.props.updateClub(data.club);
      this.props.history.push(`/manage/association/${data.club.club.golf_association_id}/club/${data.club.club.id}/account`);
    }
    this.setState({
      AddGolferModalIsOpen: false,
      AddClubModalIsOpen: false,
      AddAssocModalIsOpen: false
    });
  }

  async getJoinClubSettings() {
    return new Promise((resolve, reject) => {
      this.setState({ loadingClubSettings: true })
      JoinAndRenewServices.getJoinClubSettings(this.props.match.params.id)
        .then(res => {
          this.setState({
            joinClubSettings: res.join_club_settings,
            shouldLoadClubSetting: false,
            loadingClubSettings: false
          });
          resolve();
        })
        .catch(err => {
          this.setState({ loadingClubSettings: false, shouldLoadClubSetting: false })
          reject(err);
        })
    })
  }

  async getPendingGolfersCount() {
    return new Promise((resolve, reject) => {
      JoinAndRenewServices.getPendingMembers(this.props.match.params.id, { count: true })
        .then(res => {
          if (this.state.pendingGolfersCount !== res.count)
            this.setState({
              pendingGolfersCount: res.count,
              shouldLoadPendingGolfersCount: false
            })
          resolve();
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  async componentDidMount() {
    Modal.setAppElement('body');
    let hasExternalAccess = false;
    let clubs = this.props.rawAccess.clubs.filter(club => parseInt(club.user_access.association_id) === parseInt(this.props.match.params.association_id) && parseInt(club.club.id) === parseInt(this.props.match.params.id));
    if (clubs.length > 0) {
      hasExternalAccess = true
    }
    if (hasExternalAccess) {
      this.setState({
        association: {
          name: clubs[0].user_access.association_name,
          id: this.props.match.params.association_id
        }
      });
    } else {
      if (this.props.user_type === "association-read-only" || this.props.user_type === "association-admin" || this.props.user_type === "super-admin") {
        AssociationService
          .getAssociation(this.props.match.params.association_id)
          .then((association) => {
            this.setState({
              association: association,
              associationJoinRenewEnabled: association.join_renew_enabled
            })
          })
          .catch(err => {
            console.log(err);
            this.setState({ isLoading: false });
          })
      } else {
        this.setState({
          association: {
            name: this.props.association_name,
            id: this.props.match.params.association_id
          }
        });
      }
    }

    ClubService
      .getClub(this.props.match.params.association_id, this.props.match.params.id)
      .then((club) => {
        this.setState({
          club: club,
          associationJoinRenewEnabled: club.association_jr_enabled,
          isLoading: false
        });
        this.props.updateClub(club);
      })
      .catch(err => { console.error(err); this.setState({ isLoading: false }); });
    try {
      if (this.props.assoc_join_renew_enabled || this.state.associationJoinRenewEnabled)
        await this.getJoinClubSettings();
      if (this.state.joinClubSettings.approval_needed === true)
        await this.getPendingGolfersCount();
    }
    catch (e) {
    }
  }

    reloadClub(data)
    {
      ClubService
        .getClub(data.club.club.golf_association_id, data.club.club.id)
        .then((club) => {
            this.setState({
                club,
				isLoading: false
			});
            this.props.updateClub(club);
          }).catch(err => {console.error(err); this.setState({isLoading: false});});
    }

    updateChallenge(name) {
      if (!this.state.challenge_name || name != this.state.challenge_name)
        this.setState({ challenge_name: name });
    }

  async componentDidUpdate() {
    if (this.props.assoc_join_renew_enabled || this.state.associationJoinRenewEnabled) {
      if (this.state.shouldLoadClubSetting !== false && this.state.loadingClubSettings === false)
        await this.getJoinClubSettings();
      if (this.state.shouldLoadPendingGolfersCount !== false)
        await this.getPendingGolfersCount();
    }
  }

  render() {
    if (this.state.isLoading || this.state.loadingClubSettings) return <Loader />
    if (!this.state.club) {
      return <NotFound />
    }

    if (this.props.user_type !== "club-admin" && this.props.user_type !== 'club-read-only' && !this.state.association) return null;

        const user_access = getAllAccesses(this.props.rawAccess);
        const canSeeAssociationName = (checkAccess(user_access, this.state.association.id, null) || this.props.user_type === 'super-admin');
        const canSeeClubName = (checkAccess(user_access, null, this.state.club.id) || this.props.user_type === 'super-admin');
		    return (
            <Fragment>
                <Header />
                <Navigation />
				        <BreadCrumbsNav canSeeAssociationName={canSeeAssociationName}
										canSeeClubName={canSeeClubName}
                    association={this.state.association}
                    challenge_name={this.state.challenge_name}
										club={this.state.club}/>

				        <main>
                    <div className="container">
						<PageHead title={this.props.clubName} updateRosterList={() => this.setState({updateRosterList: !this.state.updateRosterList})} reloadClub={(data) => this.reloadClub(data)} association={this.state.association} club={this.state.club} />

                        <ul className="page__tabs">
                            <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/list`} onClick={async () => await this.getPendingGolfersCount()}>Roster</NavLink></li>
							{process.env.REACT_APP_TYPE !== "hv" && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/group`} onClick={async () => await this.getPendingGolfersCount()}>Golfer Group</NavLink></li>}
                            <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/account`} onClick={async () => await this.getPendingGolfersCount()}>Account</NavLink></li>
              {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/golfer-products`}>Golfer Products</NavLink></li>}
              {
                process.env.REACT_APP_TYPE !== "hv" &&
                this.state.joinClubSettings &&
                this.state.club.is_aga === false &&
                (this.state.joinClubSettings.enable_online_join ||
                  this.state.joinClubSettings.enable_online_renewal ||
                  getPermission(this.props.user_type, 'edit-association')
                ) &&
                <li>
                  <div style={{ position: 'relative' }}>
                    {
                      (this.state.joinClubSettings.enable_online_join || this.state.joinClubSettings.enable_online_renewal) &&
                      this.state.joinClubSettings.approval_needed &&
                      this.state.pendingGolfersCount > 0 &&
                      <Fragment>
                        <div data-tip data-for="pending" data-event='click' className="pending-count-circle top">{this.state.pendingGolfersCount}</div>
                        <ReactTooltip
                          id="pending"
                          globalEventOff='click'
                          clickable={true}
                          ref={el => this.tooltip = el}
                          place='top'
                          effect='float'
                        >
                          {
                            this.state.pendingGolfersCount > 1 ?
                              `There are ${this.state.pendingGolfersCount} pending golfers waiting for review.`
                              :
                              'There is 1 pending golfer waiting for review.'
                          }
                          <button
                            type="button"
                            onClick={() => { this.tooltip.tooltipRef = null; ReactTooltip.hide(); }}
                            className="exit-button"
                            style={{ marginLeft: '20px' }}
                          >
                            <span className="exit-icon">X</span>
                          </button>
                        </ReactTooltip>
                      </Fragment>
                    }
                    <NavLink
                      className="page__tab"
                      activeClassName="is-active"
                      to={`${this.props.match.url}/join-and-renew`}
                      onClick={async () => await this.getPendingGolfersCount()}>
                      Join & Renew
                    </NavLink>
                  </div>
                </li>
              }
              {
                process.env.REACT_APP_TYPE !== "hv" &&
                getPermission(this.props.user_type, 'edit-club') &&
                <li>
                  <NavLink
                    className="page__tab"
                    activeClassName="is-active"
                    to={`${this.props.match.url}/handicap-review`}
                    onClick={async () => await this.getPendingGolfersCount()}>
                    Handicap Review
                  </NavLink>
                </li>
              }
              {
                process.env.REACT_APP_TYPE !== "hv" &&
                getPermission(this.props.user_type, 'edit-association') &&
                <li>
                  <NavLink
                    className="page__tab"
                    activeClassName="is-active"
                    to={`${this.props.match.url}/audit-log`}
                    onClick={async () => await this.getPendingGolfersCount()}>
                    Audit Log
                  </NavLink>
                </li>
              }
            </ul>
            <section className="page__tab-panel">
              <Switch>
                <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/list`} />
                <Route
                  path={`${this.props.match.url}/list`}
                  render={() =>
                    <Roster
                      {...this.props}
                      updateRosterList={this.state.updateRosterList}
                      canEditClub={getPermission(this.props.user_type, 'edit-club')}
                      canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                      club={this.state.club} />
                  }
                />
                {
                  process.env.REACT_APP_TYPE !== "hv" &&
                  <Route path={`${this.props.match.url}/group`} render={() =>
                    <GolferGroups canEditClub={getPermission(this.props.user_type, 'edit-club')}
                      {...this.props} />}
                  />
                }
                <Route path={`${this.props.match.path}/account`} render={() =>
                  <Account canEditClub={getPermission(this.props.user_type, 'edit-club')}
                    canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                    clubName={this.state.club.club_name}
                    club={this.state.club}
                    association={this.state.association}
                    associationId={parseInt(this.props.match.params.association_id)}
                    updateJoinClubSettings={() => this.getJoinClubSettings()} />} />
								  {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin")&&<Route path={`${this.props.match.url}/golfer-products`} render={() =>
										<GolferProducts associationId={parseInt(this.props.match.params.association_id)}
                                clubId={parseInt(this.props.match.params.id)}
																association={this.state.association}
                                canEditClub={getPermission(this.props.user_type, 'edit-club')}
																canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
																updateChallenge={this.updateChallenge}
										/>}
									/>}
                <Route path={`${this.props.match.path}/join-and-renew`} render={() =>
                  <JoinAndRenew
                    canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                    canEditClub={getPermission(this.props.user_type, 'edit-club')}
                    joinClubSettings={this.state.joinClubSettings}
                    pendingGolfersCount={this.state.pendingGolfersCount}
                    updateSettings={() => this.getJoinClubSettings()}
                    reloadGolfersCount={() => this.getPendingGolfersCount()}
                    club={this.state.club}
                    association={this.state.association} />}
                />
                {process.env.REACT_APP_TYPE !== "hv" && getPermission(this.props.user_type, 'edit-association') && <Route path={`${this.props.match.path}/audit-log`} render={() => <AuditLog {...this.props} />} />}
                {
                  process.env.REACT_APP_TYPE !== "hv" &&
                  getPermission(this.props.user_type, 'edit-club') &&
                    <Route
                      path={`${this.props.match.path}/handicap-review`}
                      render={
                        () => <HandicapReview {...this.props} club={this.state.club}/>
                      }
                    />
                }
              </Switch>
            </section>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    rawAccess: state.user.rawAccess,
    user_type: state.user.access.user_type,
    association_name: state.user.access.default_association_name,
    assoc_join_renew_enabled: state.association.profile ? state.association.profile.join_renew_enabled : state.association.join_renew_enabled,
    clubName: state.club.club_name
  };
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ updateClub }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Club));
