import React, {Component, Fragment} from 'react';

import EditTotalScoreForm from '../Forms/EditTotalScoreForm';
import EditManualScoreForm from '../Forms/EditManualScoreForm';
import EditHBHScoreForm from '../Forms/EditHBHScoreForm';
import ScoreService from "../../services/api/score";
import moment from "moment";
import ClubService from "../../services/api/club";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import flatten from "lodash.flatten";
import {orderTees} from "../../services/shared/teesFilter";

export default class EditScoreModal extends Component {


	constructor(props) {
		super(props);
		this.state = {
			scores: [],
			homeCourses: [],
			selectedScoreIndex: 0,
			forcedManual: false,
			emptyCombined: false,
			selectedCourse: false,
			selectedAwayCourse: false,
			selectedAwayTee: false,
			loading: true,
			loadingStatus: false,
			overriden: false,
			overrided: false
		}
	}

	async componentDidMount() {
		let {selectedScore} = this.props;
		if(selectedScore.exceptional) {
			this.getStatus(selectedScore.id);
		}
		if (selectedScore.score_type === 'C') {
			return ScoreService.getChildScores(selectedScore.id, {golfer_id: this.props.selectedScore.golfer_id})
				.then(response => {
					let {scores} = response;
					if (scores.length > 1) {

						if (scores[0].played_at !== scores[1].played_at) {
							scores.sort((a, b) => moment().valueOf(a.played_at) - moment().valueOf(b.played_at))
						} else {
							scores.sort((a, b) => a.score_day_order - b.score_day_order);
						}
						if (selectedScore.exceptional) {
							scores = scores.map((score) => {
								return {
									...score,
									exceptional: true
								}
							})
						}
						this.setState({
							scores
						}, () => this.changeCombined(0))
					} else {
						this.findCourse(selectedScore).then(() => {
							this.setState({forcedManual: true, loading: false, emptyCombined: true})
						});

					}

				})
				.catch(err => {
					console.log(err);
				})
		} else {
			if (selectedScore.is_manual) {
				// form type = manual
				await this.findCourse(selectedScore);
				this.setState({loading: false});
			} else {
				await this.getData(selectedScore);
			}
		}
	}

	getStatus(scoreId) {
		this.setState({loadingStatus: true});
		ScoreService.getOverrideStatus(scoreId)
			.then(res => {
				if(res.Score === "exceptional score - not overriden") {
					this.setState({
						overriden: false,
						loadingStatus: false,
					})
				}
				if(res.Score === "exceptional score - overriden") {
					this.setState({
						overriden: true,
						loadingStatus: false,
					})
				}
				if(res.Score === "not exceptional") {
					this.setState({
						overriden: true,
						loadingStatus: false,
					})
				}
			})
			.catch(err => {
				console.log(err);
			})
	}

	async getData(selectedScore) {
		ClubService.getClubHomeCourses(null, this.props.clubId)
			.then(async response => {
				if (response.facilities.length > 0) {
					let courses = await Promise.all(response.facilities.map(async hc => {
						let courses = await Promise.all(hc.home_courses.map(home_course => {
							let courseName = hc.name === home_course.name ? home_course.name : hc.name + ' ' + home_course.name;
							return {
								req: CourseRatingSystemService.getCourseDetails(home_course.course_id),
								courseName: courseName,
								courseId: home_course.course_id
							};
						}).map(async r => {
							let response = await r.req;
							let filteredTS = this.parseCourse(response, r.courseName);

							return filteredTS;
						}));

						return flatten(courses);

					}));
					this.setState({
						homeCourses: flatten(courses)
					}, async () => {
						//check if the course belongs to home courses
						let course = this.state.homeCourses.filter(c => parseInt(c.teeSetID) === parseInt(selectedScore.tee_set_id) && (c.tee_set_side === selectedScore.tee_set_side || (!selectedScore.tee_set_side && c.tee_set_side === "All18")))[0];
						if (course) {
							// display home preselected course and tee
							if (selectedScore.is_manual) {
								this.setState({selectedAwayCourse: course, forcedManual: false, loading:false});
							} else {
								this.setState({selectedHomeCourse: course, forcedManual: false, loading:false});
							}
						} else {
							//check if the course and tee still exists
							await this.findCourse(selectedScore);
							if (!selectedScore.is_manual) {
								if (this.state.selectedAwayCourse && this.state.selectedAwayTee) {
									this.setState({
										forcedManual: false,
										loading: false
									});
									// display away preselected course and tee
								} else {
									this.setState({
										forcedManual: true,
										loading: false
									});
									// form type = manual (if just the tee doesn't exist show async select and manual
								}
							} else {
								this.setState({
									forcedManual: false,
									loading: false
								});
							}
						}
					});
				} else {
					// no home courses....
					await this.findCourse(selectedScore);
					if (this.state.selectedAwayCourse && this.state.selectedAwayTee) {
						this.setState({forcedManual: false, loading: false});
						// display away preselected course and tee
					} else {
						if (selectedScore.is_manual) {
							this.setState({forcedManual: false, loading: false});
						} else {
							this.setState({forcedManual: true, loading: false});
						}
						// form type = manual (if just the tee doesn't exist show async select and manual
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	async findCourse(selectedScore) {
		return CourseRatingSystemService.getCourseDetails(selectedScore.course_id)
			.then(response => {
				let filteredTS = this.parseCourse(response);

				this.setState({
					teeSetsOptions: filteredTS
				}, () => {
						let selectedAwayCourse = {value: selectedScore.course_id, label: selectedScore.course_display_value};
						let selectedAwayTee = filteredTS.filter(f => parseInt(f.value) === parseInt(selectedScore.tee_set_id) && ((f.tee_set_side === selectedScore.tee_set_side) || (!selectedScore.tee_set_side && f.tee_set_side === "All18")))[0];
						if(selectedAwayTee){
							// selectedTee
							this.setState({selectedAwayCourse, selectedAwayTee})
						} else {
							this.setState({selectedAwayCourse});
							// tee don't exist -> manual
						}

				})
			})
			.catch(err => {
				// manual without course...
				console.log(err);
			})
	}

	overrideScore() {
		ScoreService.overrideScore(this.props.selectedScore.id)
			.then(res => {
				this.setState({overrided: true},() =>
					this.getStatus(this.props.selectedScore.id))
			})
			.catch(err => {
				console.log(err);
			})
	}

	parseCourse(response, courseName) {
		let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer.gender).map(ts => {
			let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
			let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
			let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
			return {label: courseName ? courseName + ' ' + ts.TeeSetRatingName : ts.TeeSetRatingName,
				holes: ts.Holes,
				value: ts.TeeSetRatingId,
				courseId: response.CourseId,
				ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
				ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
				ratingBack: back &&`${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
				totalCourseRating: total && total.CourseRating,
				frontCourseRating: front && front.CourseRating,
				backCourseRating:  back && back.CourseRating,
				totalSlopeRating: total && total.SlopeRating,
				frontSlopeRating: front && front.SlopeRating,
				backSlopeRating:  back && back.SlopeRating,
				teeName: ts.TeeSetRatingName,
				ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1)
			}
		});
		orderTees(filteredTS);
		filteredTS = filteredTS.reduce((res, current) => {
			let frontPar = current.holes.reduce((acc, h, index) => {
				if (index < 9) return acc + h.Par;
				return acc + 0;
			}, 0);
			let backPar = current.holes.reduce((acc, h, index) => {
				if (index >= 9) return acc + h.Par;
				return acc + 0;
			}, 0);
			let totalPar = current.holes.reduce((acc, h, index) => acc + h.Par, 0);
			let label = ''
			let front = {}
			if (current.ratingFront !== undefined) {
				label = current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`;
				front = {
					label: label,
					unparsedLabel: current.label + ' F9',
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'F9',
					course_rating: current.frontCourseRating,
					slope_rating: current.frontSlopeRating,
					teeName: current.teeName,
					par: frontPar,
				};
			}

			let back = {}
			if (current.ratingBack !== undefined) {
				label = current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`;
				back = {
					label: label,
					unparsedLabel: current.label + ' B9',
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'B9',
					course_rating: current.backCourseRating,
					slope_rating: current.backSlopeRating,
					teeName: current.teeName,
					par: backPar,
				};
			}
			let total = {}
			if (current.ratingTotal !== undefined) {
				label = current.label + "\n" + `(${current.ratingTotal} / ${totalPar})`;
				total = {
					label: label,
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'All18',
					course_rating: current.frontCourseRating,
					slope_rating: current.frontSlopeRating,
					teeName: current.teeName,
					par: totalPar,
				};
			}

			return res.concat([total, front, back])
		}, []);
		return filteredTS;
	}

	changeCombined(nr){
		this.setState({selectedScoreIndex: nr, selectedAwayCourse: null, selectedAwayTee: null, selectedHomeCourse:null, loading: true}, ()=>{
			this.getData(this.state.scores[this.state.selectedScoreIndex])
		})
	}

	render() {
		return (
            <Fragment>

                <div className="modal__container wide">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Score</h2>
                        <button className="modal__close" onClick={() => {this.state.overrided ? this.props.closeModal(true) : this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
						{this.props.selectedScore && this.props.selectedScore.score_type === 'C' && (!this.state.emptyCombined) &&
							<div className={'row'}>
								<div className={'col is-3-of-4 jc-c'}>You are editing a
									combined score. Please choose which score you
									wish to edit.
								</div>
								<div className={'col is-1-of-4'}>
									<div className={'custom-radio__container'}>
										<input onChange={(e) => {this.changeCombined(0)}} type={'radio'} id={'score1'} name={'score1'} checked={this.state.selectedScoreIndex === 0}/>
										<label htmlFor="score1">Edit Score 1</label>
										<input onChange={(e) => {this.changeCombined(1)}} type={'radio'} id={'score2'} name={'score2'} checked={this.state.selectedScoreIndex === 1}/>
										<label htmlFor="score2">Edit Score 2</label>
									</div>
								</div>
							</div>
						}
						{this.state.loading || this.state.loadingStatus ?
							<table style={{width: '100%'}}>
								<tbody >
								<tr>
									<td style={{textAlign: 'center'}}>
										<span className="data-table__loader"></span>
									</td>
								</tr>
								</tbody>
							</table>
							: <Fragment>
								{(this.props.selectedScore.is_manual || this.state.forcedManual) ?
                  !(this.state.scores.length === 0 && this.props.selectedScore.score_type_display_short === 'N' && this.props.selectedScore.scaled_up_differential === null) ?
									<EditManualScoreForm score={(this.props.selectedScore.score_type === 'C' && !this.state.emptyCombined) ? (this.state.scores.length > 0 && this.state.scores[this.state.selectedScoreIndex]) : this.props.selectedScore}
														 closeModal={(data, showConfirmationModal) =>{ if(this.state.overrided){this.props.closeModal(true, showConfirmationModal);} else {this.props.closeModal(data, showConfirmationModal)}}}
														 emptyCombined={this.state.emptyCombined}
														 forcedManual={this.state.forcedManual}
														 overriden={this.state.overriden}
														 overrideScore={() => {this.overrideScore()}}
														 combinedScore={this.props.selectedScore.score_type === 'C'}
														 combined_score_id={this.props.selectedScore.score_type === 'C' && this.props.selectedScore.id}
														 combined_9_hole_score={this.state.scores.length > 0 ? (this.state.selectedScoreIndex === 1 ? this.state.scores[0].id : this.state.scores[1].id): null}
														 golfer={this.props.golfer}
														 selectedTee={this.state.selectedAwayTee}
														 selectedAwayCourse={this.state.selectedAwayCourse}
                             setOutdatedScoreModalOpen={this.props.setOutdatedScoreModalOpen} />
									:
                    <EditTotalScoreForm
                      score={(this.props.selectedScore.score_type === 'C' && !this.state.emptyCombined) ? (this.state.scores.length > 0 && this.state.scores[this.state.selectedScoreIndex]) : this.props.selectedScore}
                      closeModal={(data, showConfirmationModal) => { if (this.state.overrided) { this.props.closeModal(true, showConfirmationModal); } else { this.props.closeModal(data, showConfirmationModal) } }}
                      combinedScore={this.props.selectedScore.score_type === 'C'}
                      golfer={this.props.golfer}
                      overrideScore={() => { this.overrideScore() }}
                      overriden={this.state.overriden}
                      homeCourses={this.state.homeCourses}
                      teeSetsOptions={this.state.selectedAwayTee ? this.state.teeSetsOptions : []}
                      selectedCourse={this.state.selectedAwayCourse || this.state.selectedHomeCourse}
                      selectedTee={this.state.selectedAwayTee}
                      selectedScoreIndex={this.props.selectedScore.score_type === 'C' && (this.state.selectedScoreIndex + 1)}
                      combined_9_hole_score={this.state.scores.length > 0 ? (this.state.selectedScoreIndex === 1 ? this.state.scores[0].id : this.state.scores[1].id) : null}
                      combined_score_id={this.props.selectedScore.score_type === 'C' && this.props.selectedScore.id}
                      setOutdatedScoreModalOpen={this.props.setOutdatedScoreModalOpen}
                      migrated_combined={true}
                    />
                  :
									(((this.props.selectedScore.score_type === 'C' && !this.state.emptyCombined && this.state.scores[this.state.selectedScoreIndex].hole_details.length !== 0) || (this.props.selectedScore.hole_details.length !== 0 && !(this.props.selectedScore.is_manual || this.state.forcedManual))) ?
										<EditHBHScoreForm score={(this.props.selectedScore.score_type === 'C' && !this.state.emptyCombined) ? (this.state.scores.length > 0 && this.state.scores[this.state.selectedScoreIndex]) : this.props.selectedScore}
														  closeModal={(data, showConfirmationModal) =>{if(this.state.overrided) {this.props.closeModal(true, showConfirmationModal);} else {this.props.closeModal(data, showConfirmationModal)}}}
														  combinedScore={this.props.selectedScore.score_type === 'C'}
														  golfer={this.props.golfer}
														  overriden={this.state.overriden}
														  homeCourses={this.state.homeCourses}
														  overrideScore={() => {this.overrideScore()}}
														  teeSetsOptions={this.state.selectedAwayTee ? this.state.teeSetsOptions : []}
														  selectedCourse={this.state.selectedAwayCourse || this.state.selectedHomeCourse}
														  selectedTee={this.state.selectedAwayTee}
														  combined_score_id={this.props.selectedScore.score_type === 'C' && this.props.selectedScore.id}
														  selectedScoreIndex={this.props.selectedScore.score_type === 'C' && (this.state.selectedScoreIndex + 1)}
														  combined_9_hole_score={this.state.scores.length > 0 ? (this.state.selectedScoreIndex === 1 ? this.state.scores[0].id : this.state.scores[1].id): null}
                              setOutdatedScoreModalOpen={this.props.setOutdatedScoreModalOpen} />
										:
										<EditTotalScoreForm
											score={(this.props.selectedScore.score_type === 'C' && !this.state.emptyCombined) ? (this.state.scores.length > 0 && this.state.scores[this.state.selectedScoreIndex]) : this.props.selectedScore}
											closeModal={(data, showConfirmationModal) =>{if(this.state.overrided) {this.props.closeModal(true, showConfirmationModal);} else {this.props.closeModal(data, showConfirmationModal)}}}
											combinedScore={this.props.selectedScore.score_type === 'C'}
											golfer={this.props.golfer}
											overrideScore={() => {this.overrideScore()}}
											overriden={this.state.overriden}
											homeCourses={this.state.homeCourses}
											teeSetsOptions={this.state.selectedAwayTee ? this.state.teeSetsOptions : []}
											selectedCourse={this.state.selectedAwayCourse || this.state.selectedHomeCourse}
											selectedTee={this.state.selectedAwayTee}
											selectedScoreIndex={this.props.selectedScore.score_type === 'C' && (this.state.selectedScoreIndex + 1)}
											combined_9_hole_score={this.state.scores.length > 0 ? (this.state.selectedScoreIndex === 1 ? this.state.scores[0].id : this.state.scores[1].id): null}
											combined_score_id={this.props.selectedScore.score_type === 'C' && this.props.selectedScore.id}
                      setOutdatedScoreModalOpen={this.props.setOutdatedScoreModalOpen}
										/>)
								}
							</Fragment>
						}
                    </div>

                </div>

            </Fragment>
		);
	}
}
