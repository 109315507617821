import React, {Component, Fragment} from 'react';
import SpecialUpdateForm from '../Forms/SpecialUpdateForm';
import SuccessHandicapReviewModal from './SuccessHandicapReviewModal';

export default class HandicapReviewModal extends Component {
	constructor(props){
		super(props);
		this.state = {
			success: false,
		}
	}

	render() {
		let handicapReview = this.state.handicapReview;
        
		return (
			<Fragment>
				<div className="modal__container narrow">
					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">warning</i></div>
						<h2 className="modal__title">{this.props.title}</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>
					<div className="modal__body">
						<div className="row">
							<div className="col is-1-of-1" style={{alignItems: "center"}}>
								<span className="label">{this.props.description}</span>
								<span className="label"><strong>{this.props.selected_golfers_count}?</strong></span>
							</div>
						</div>
						{this.state.success ?
							<SuccessHandicapReviewModal
								openModal={this.state.success}
								onMainAction={() => this.props.closeModal("success")}
								warning={handicapReview.inactive_golfers > 0}
								warningTitle={"Success!"}
								message={
									<Fragment>
										<p style={{"fontSize":"24px", "marginBottom":"10px"}}><strong>Handicap Review was run on {handicapReview.total_golfers} golfers</strong></p>
										<p><strong>{handicapReview.ineligible_golfers}</strong> Ineligible for Review</p>
									</Fragment>
								}
								closeModal={() => {this.props.closeModal("success")}}
								resultsUrl={this.props.resultsUrl}
							/>
							:
                            // TODO: SpecialUpdateForm - is just a modal with 2 buttons - change name
							<SpecialUpdateForm loading={this.state.loading} closeModal={this.props.closeModal} confirmAction={()=> this.performHandicapReview()}/>}
					</div>
				</div>
			</Fragment>
		);
	}

	performHandicapReview() {
		this.setState({
			loading: true
		});
		this.props.confirmAction().then((data) => {
			this.setState({
				success: true,
				loading: false,
				handicapReview: data
			});
		});
	}
}
