import React, {Fragment, useEffect, useRef, useState} from 'react';
import ScoreService from '../../services/api/score';
import GhinTable from '../shared/GhinTable';
import {LineChart, Line, ResponsiveContainer, PieChart, Pie, Cell, YAxis, Legend, BarChart, Bar, XAxis, LabelList} from 'recharts';
import { Collapse } from 'react-collapse';
import { getCleanName } from '../utils'
import moment from 'moment'
import ExportToPdfButton from '../shared/ExportToPdfButton';
import ReactTooltip from 'react-tooltip';

// Add + / - to value
function signValue(val) {
  return val > 0 ? '+' + val : val
}

function isEmptyValue(val)
{
  return !val || val === 0
}

function parseScoreFlagged(score)
{
  if(score.flag_type === 'low')
  {
    return(
      <div className={'bubble low_bubble'}>▼ Low</div>    
    );
  }
  else if(score.flag_type === 'high')
  {
    return(
      <div className={'bubble high_bubble'}>▲ High</div>    
    );
  }
}

function calculateDaysAgo(d) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const date_today = new Date();
  const date = new Date(d);

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date_today.getFullYear(), date_today.getMonth(), date_today.getDate());
  const utc2 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

  return Math.floor((utc1 - utc2) / MS_PER_DAY);
}

function setReviewStatus(apply, applyAction, denyAction, setLoading) {
  if(apply) {
    setLoading(true)
    applyAction();
  } else {
    denyAction();
  }
}

// The input handicap index value must be a string
function displayHandicapIndex(value) {
  return parseFloat(value) < 0 ? '+' + value.split('-')[1] : value
}

function hiDuringReviewGraph(review, handicapHistory)
{
  const min_handicap_index = parseHandicapIndex(review.min_handicap_index)
  const max_handicap_index = parseHandicapIndex(review.max_handicap_index)

  const data = handicapHistory.map(value => {
      return { name: '', hi: value === 999 ? null : value}
    }
  ).reverse();

  const CustomizedDot = (props) => {
    const { cx, cy, value, index} = props;

    if(value === null || (index !== 0 && index !== data.length - 1)) return <></>;

    return (
      <svg x={cx - 50} y={cy - 50} width={100} height={100} >
        <circle r='4' cx='50' cy='50' fill="#0086C9" />
        <foreignObject x={index === 0 ? '30' : '40'} y='10' width='200' height='200'>
          <div style={{'fontSize': '14px', 'color': '#535353'}}>{index === 0 ? 'Start' : 'End'}</div>
          <div style={{'fontSize': '18px', 'fontWeight': 'bold'}}>{displayHandicapIndex(value.toFixed(1))}</div>
        </foreignObject>
      </svg>
    );
  };

  return(
    <div style={{flex:'5', height:'100%', display:'flex', flexDirection:'column'}}>
      <div className='main_card_section_title' style={{flex:'1'}}>H.I. During Review</div>
      <div style={{flex:'6'}}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{ top: 50, right: 50, left: 50, bottom: 50 }}
          >
            <YAxis
              domain={['dataMin - 7', 'dataMax + 7']}
              hide={true}
            />
            <Line
              type="monotone"
              dataKey="hi"
              stroke="#0086C9"
              strokeWidth={2}
              dot={<CustomizedDot />}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{flex:'3', 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center', alignItems:'center', gap: '8px'}}>
        <div className='small_card' style={{'--width':'57px', '--height':'62px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.soft_cap_count ?? '-'}</div>
          <div className='small_card_details'>Soft caps</div>
        </div>
        <div className='small_card' style={{'--width':'57px', '--height':'62px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.hard_cap_count ?? '-'}</div>
          <div className='small_card_details'>Hard caps</div>
        </div>
        <div className='small_card' style={{'--width':'57px', '--height':'62px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.esr_count ?? '-'}</div>
          <div className='small_card_details'>ESRs</div>
        </div>
        <div className='small_card' style={{'--width':'57px', '--height':'62px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{min_handicap_index ?? '-'}</div>
          <div className='small_card_details'>Min H.I.</div>
        </div>
        <div className='small_card' style={{'--width':'57px', '--height':'62px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{max_handicap_index ?? '-'}</div>
          <div className='small_card_details'>Max H.I.</div>
        </div>
      </div>
    </div>
  )
}

function flaggedScoresGraph(review)
{
  const COLORS = ['#013660', '#0089D1', '#EAECF0']

  const low_flags = review.number_of_flags_low ?? 0;
  const low_flags_percent = review.number_of_scores === 0 ? 0 : (review.number_of_flags_low / review.number_of_scores) * 100;

  const high_flags = review.number_of_flags_high ?? 0;
  const high_flags_percent = review.number_of_scores === 0 ? 0 : (review.number_of_flags_high / review.number_of_scores) * 100;

  const unflagged = review.number_of_scores === 0 ? 1 : review.number_of_scores - (review.number_of_flags_low + review.number_of_flags_high);

  const data = [
    // 'show' - used to exclude elements from Legend 
    { show: true, name: `${low_flags} low flags (${low_flags_percent.toFixed(0)}%)`, value: low_flags },
    { show: true, name: `${high_flags} high flags (${high_flags_percent.toFixed(0)}%)`, value: high_flags },
    { show: false, name: 'Not flagged', value: unflagged }
  ];

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul style={{position:'relative', left:'-30px'}}>
        {
          payload.filter((entry) => entry.payload.show).map((entry, index) => (
            <li key={`item-${index}`}>
              <div style={{display:'flex', flexDirection:'row', marginBottom:'10px', alignItems:'center', fontSize: '18px', fontWeight: 500, lineHeight: '21.6px', textAlign: 'left', color: '#535353'}}>
                <div style={{width:'11px', height:'11px', borderRadius:'50%', background:COLORS[index], marginRight:'5px'}}></div>{entry.value}
              </div>
            </li>
          ))
        }
      </ul>
    );
  }

  return(
    <div style={{flex:'5',  height:'100%', display:'flex', flexDirection:'column'}}>
      <div className='main_card_section_title' style={{flex:'1'}}>Flagged Scores</div>
      <div style={{flex:'9'}}>
        <ResponsiveContainer>
          <PieChart>
             <Pie
               data={data}
               cx="50%"
               cy="50%"
               labelLine={false}
               outerRadius={65}
               innerRadius={40}
               fill="#EAECF0"
               dataKey="value"
               isAnimationActive={false}
               // Start at the top, move clockwise
               startAngle={450}
               endAngle={90}
             >
               {data.map((entry, index) => (
                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend
                verticalAlign='middle'
                align='right'
                layout='vertical'
                content={renderLegend}
              />
            </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

function verticalDivider() {
  return <div style={{ marginLeft: '25px', marginRight: '25px', width: '1px', borderLeft: '1px solid #B6C1CD', height: '80%' }} />;
}

function factorsImpactingRecommendation(review, handicapHistory)
{
  return(
    <div className="handicap_review_details_main_card" style={{'--width': '832px', '--height': '304px', display:'flex', flexDirection:'column'}}>
      <div className='main_card_title' style={{flex:'1'}}>FACTORS IMPACTING USGA RECOMMENDATION</div>
      <div style={{display:'flex', flexDirection:'row', flex:'9', alignItems:'center'}}>
        {hiDuringReviewGraph(review, handicapHistory)}
        {verticalDivider()}
        {flaggedScoresGraph(review)}
      </div>
    </div>
  )
}

function golferName(golfer)
{
  return golfer.first_name + ' ' + 
         (golfer.middle_name !== null ? golfer.middle_name : '') + ' ' +
         golfer.last_name
}

function parseHandicapIndex(hi) {
  return hi >= 0 ? hi?.toFixed(1) : '+' + Math.abs(hi?.toFixed(1)).toString()
}

function golferDetails(golfer, review) {
  const current_handicap_index = review.current_handicap_index_display ?? parseHandicapIndex(review.current_handicap_index)
  const low_handicap_index = review.low_handicap_index_display ?? parseHandicapIndex(review.low_handicap_index)

  return(
    <div className='handicap_review_details_main_card invisible_card' style={{'--height':'123px', display: 'flex', justifyContent:'space-between'}}>
        <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', flex: '5', gap:'10px'}}>
          <div className='golfer_name'>{golferName(golfer)}</div>
          <div className='ghin_number_title'>GHIN Number: <span className='ghin_number_details'>{golfer.id}</span></div>
        </div>
        <div className='handicap_review_details_main_card' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: '3', 'gap': '5px'}}>
          <div className='golfer_card_title'>Handicap Review Performed</div>
          <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className='golfer_card_details'>Review Run</div>
            <div className='golfer_card_details'>{moment(review.created_at).format('MM/DD/YYYY HH:mm:ss')}</div>
          </div>
          <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className='golfer_card_details'>Handicap Index®</div>
            <div className='golfer_card_details'>{current_handicap_index ?? '-'}</div>
          </div>
          <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className='golfer_card_details'>Low H.I.™</div>
            <div className='golfer_card_details'>{low_handicap_index ?? '-'}</div>
          </div>
        </div>
    </div>
  );
}

function getReviewType(review) {
  if(review.is_expired) return 'expired';
  if(review.recommendation_applied === null) return 'pending';
  if((review.recommendation_applied === true || review.recommendation_applied === false) && review.recommended_adjustment === 0 && review.reasons.length === 0) return 'no_adjustment';
  if(review.recommendation_applied === true && review.recommended_adjustment < 0) return 'down';
  if(review.recommendation_applied === true && review.recommended_adjustment > 0) return 'up';
  if(review.recommendation_applied === false && review.reasons.length > 0) return 'ineligible';
  if(review.recommendation_applied === false) return 'denied';
}

function reviewStatusDetails(review, setLoading, props)
{

  const approvalMenu = (applyAction, denyAction, setLoading) =>
  {
    return (
      props.canEditClub && (
        <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', gap:'10px', marginRight:'20px'}}>
          <button
            className='btn fill green'
            disabled={props.allowedClubs && !props.allowedClubs?.includes(review.club_id)}
            onClick={() => setReviewStatus(true, applyAction, denyAction, setLoading)}>
              Apply
          </button>
          <button
            className='btn deny_button'
            disabled={props.allowedClubs && !props.allowedClubs?.includes(review.club_id)}
            onClick={() => setReviewStatus(false, applyAction, denyAction, setLoading)}>
              Deny
          </button>
        </div>
      )
    )
  }

  const reviewSymbol = (type) => {
    if(type === 'expired') return <span className='recommended_adjustment_symbol-expired material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>schedule</span>;
    if(type === 'pending') return <span className='recommended_adjustment_symbol-pending material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>warning</span>;
    if(type === 'no_adjustment') return <span className='recommended_adjustment_symbol-zero material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>task_alt</span>;
    if(type === 'down') return <span className='recommended_adjustment_symbol-applied material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>south</span>;
    if(type === 'up') return <span className='recommended_adjustment_symbol-applied material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>north</span>;
    if(type === 'ineligible') return <span className='recommended_adjustment_symbol-ineligible material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>block</span>;
    if(type === 'denied') return <span className='recommended_adjustment_symbol-denied material-icons-outlined v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>cancel</span>;
  }
  
  const title = (type, can_show_details = false, reasons) => {
    const reason = reasons.length > 0 ? reasons[0] : ""

    if(type === 'expired') return <div className='recommended_adjustment_title'>Recommended adjustment expired</div>
    if(type === 'pending') return <div className='recommended_adjustment_title'>USGA recommended adjustment: {signValue(review.recommended_adjustment)}</div>
    if(type === 'no_adjustment') return <div className='recommended_adjustment_title'>No adjustment recommended</div>
    if(type === 'down') return <div className='recommended_adjustment_title'>Handicap Index was adjusted by {signValue(review.recommended_adjustment)}</div>
    if(type === 'up') return <div className='recommended_adjustment_title'>Handicap Index was adjusted by {signValue(review.recommended_adjustment)}</div>
    if(type === 'ineligible' && (!can_show_details || reason === "Pending recommended adjustment.")) return <div className='recommended_adjustment_title'>Golfer excluded from Handicap Review</div>   
    if(type === 'ineligible' && can_show_details) return <div className='recommended_adjustment_title'>Golfer is ineligible for a recommended Handicap Index adjustment</div>
    if(type === 'denied') return <div className='recommended_adjustment_title'>Recommended adjustment was denied</div>
  }

  const processReason = (reason) => {
    const reasonMap = {
      'Ineligible (Not enough scores)': 'Fewer than 20 scores in scoring record',
      'Ineligible (Not home club)': 'Not home club',
      'Ineligible (Modified HI)': 'Modified Handicap Index',
      'Ineligible (NH)': 'No Handicap Index',
      'Ineligible (WD)': 'Withdrawn Handicap Index',
      'Ineligible (Unapplied handicap reviews)': 'Pending recommended adjustment',
      'Ineligible (Inactive)': 'Inactive golfer',
      'Ineligible (Archived)': 'Archived golfer',
      'Ineligible (Deceased)': 'Deceased golfer',
      'Ineligible (Trial)': 'Trial golfer'
    }

    return reasonMap[reason] || ''
  }

  const subtitle = (type) => {
    const current_handicap_index = parseHandicapIndex(review.current_handicap_index)

    if(type === 'expired') return <></>;
    if(type === 'pending') return <div className='recommended_adjustment_subtitle_pending'>To apply a different adjustment, use the <a href={props.url}>Handicap Management tab</a></div>
    if(type === 'no_adjustment') return <div className='recommended_adjustment_subtitle'>Keep Handicap Index at {current_handicap_index}</div>
    if(type === 'down') return <></>;
    if(type === 'up') return <></>;
    if(type === 'ineligible') return <div className='recommended_adjustment_subtitle'>{processReason(review.reasons[0])}</div>
    if(type === 'denied') return <></>;
  }

  const tooltip = (type) => {
    let tooltipMessage = '';

    if(type === 'expired') tooltipMessage = '';
    if(type === 'pending') tooltipMessage = '';
    if(type === 'no_adjustment') tooltipMessage = '';
    if(type === 'down') tooltipMessage = 'The adjusted Handicap Index may be slightly different than this value due to the steps involved in the Handicap Index calculation (see Rule 5 of the Rules of Handicapping)';
    if(type === 'up') tooltipMessage = 'The adjusted Handicap Index may be slightly different than this value due to the steps involved in the Handicap Index calculation (see Rule 5 of the Rules of Handicapping)';
    if(type === 'ineligible') tooltipMessage = '';
    if(type === 'denied') tooltipMessage = '';

    if(tooltipMessage === '') return<Fragment/>;

    return(
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
        <span onClick={(e) => {e.preventDefault()}} data-tip data-for='adjustment_info'>
          <i className="material-icons-outlined v-mid m-left small">help</i>
        </span>
        <ReactTooltip 
          id='adjustment_info' 
          className='table-head__tooltip' 
          type='dark' 
          place='top' 
          effect={'float'}
          overridePosition={({ left, _ }) => ({ top: 290, left })}
        >
          {tooltipMessage}
        </ReactTooltip>
      </div>
    )
  }

  const date = (type) => {
    if(type === 'expired') return <div className='details_date' style={{textAlign:'right'}}>Expired on {moment(review.expiration_date).format('MM/DD/YYYY')}</div>
    if(type === 'pending') return <></>;
    if(type === 'no_adjustment') return <></>;
    if(type === 'down') return <div className='details_date' style={{textAlign:'right'}}>Applied on {moment(review.updated_at).format('MM/DD/YYYY [at] hh:mm a')}</div>
    if(type === 'up') return <div className='details_date' style={{textAlign:'right'}}>Applied on {moment(review.updated_at).format('MM/DD/YYYY [at] hh:mm a')}</div>
    if(type === 'ineligible') return <></>;
    if(type === 'denied') return <div className='details_date' style={{textAlign:'right'}}>Denied on {moment(review.updated_at).format('MM/DD/YYYY [at] hh:mm a')}</div>
  }

  return(
    <div className="handicap_review_details_main_card" style={{display:'flex', flexDirection:'row', '--width':'832px', '--height':'86px'}}>
      <div style={{flex:'1', display:'flex', justifyContent:'center', alignItems:'center'}}>
        {reviewSymbol(getReviewType(review))}
      </div>
      <div style={{flex: getReviewType(review) === 'ineligible' && review.can_show_details ? '8' : '6', display:'flex', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'row', paddingTop:'15px'}}>{title(getReviewType(review), review.can_show_details, review.reasons)}{tooltip(getReviewType(review))}</div>
        <div>{subtitle(getReviewType(review))}</div>
      </div>
      <div style={{flex: getReviewType(review) === 'ineligible' && review.can_show_details ? '1' : '3', paddingTop:'20px'}}>
        {
          getReviewType(review) === 'pending' ? approvalMenu(
            () => props.applyHandicapReview(),
            () => props.denyHandicapReview(),
            setLoading
          ) : date(getReviewType(review))
        }
      </div>
    </div>
  )
}

function scoresInReviewDetails(review)
{
  return(
    <div className='handicap_review_details_main_card invisible_card' style={{display: 'flex', flexDirection: 'column', gap:'10px', justifyContent: 'center', alignItems: 'center'}}>
      <div className='scores_in_review_title'>{review.number_of_scores} Scores in Review Period</div>
      <div className='scores_in_review_date'>{moment(review.review_start_date).format('MM/DD/YYYY')} - {moment(review.review_end_date).format('MM/DD/YYYY')}</div>
    </div>
  )
}

function playedToHIDetails(review)
{
  const data = [
    {
      name: 'expected',
      percentage: review.score_differential_expected
    },
    {
      name: 'actual',
      percentage: review.score_differential_actual
    }
  ];

  const renderCustomBarLabel = ({ props }) => {
    return (
      <text
        x={props.x}
        y={props.y}
        fill="#000000"
        textAnchor="start"
        dy={-props.height - 5}
      >
        <tspan fontWeight={"800"} fontSize={"18px"} color={'#000000'}>{props.value != null ? props.value + '%' : '-'}</tspan>{' '}
        <tspan fontWeight={"500"} fontSize={"18px"} color={'#535353'}>{props.name}</tspan>
      </text>
    );
  };

  return(
    <div className='handicap_review_details_main_card' style={{'--width': '403px', '--height': '253px', display:'flex', flexDirection:'column', paddingLeft:'30px', paddingRight:'30px', paddingTop:'20px', paddingBottom:'20px'}}>
      <Fragment>
        <div className='main_card_section_title' style={{flex:'1'}}>
          Played to Handicap Index
          <span onClick={(e) => {e.preventDefault()}} data-tip data-for='played_to_hi_info'>
            <i className="material-icons-outlined v-mid m-left small">help</i>
          </span>
        </div>
        <ReactTooltip 
          id='played_to_hi_info' 
          className="table-head__tooltip" 
          type='dark' 
          place='top' 
          effect={'float'}
          overridePosition={({ left, _ }) => ({ top: 720, left })}
        >
          <div>
            <div className='tooltip_text'>Played to Handicap is defined as a score differential equal to or better than the Handicap Index.</div>
            <br/>
            <div className='tooltip_text'>Over the entire Handicap Index range, the Played to Handicap average is about 20-25% of the time. For this review, the expected value listed is specific to all scores posted for players with a similar Handicap Index to the one in the review. The fewer scores used in the review period will increase variability in the actual vs. expected value.</div>
          </div>
        </ReactTooltip>
      </Fragment>
      <div style={{flex:'6', marginTop:'10px'}}>
        <ResponsiveContainer>
          <BarChart
            width={110}
            height={110}
            data={data}
            layout="vertical"
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 0
            }}
            barSize={10}
          >
            <XAxis type="number" domain={[0, 100]} hide={true} />
            <YAxis dataKey="name" type="category" hide={true} />
            <Bar
              dataKey="percentage"
              minPointSize={2}
              radius={20}
              background={{ fill: '#D9D9D9', radius: 20 }}
            >
              {
                data.map((entry, index) => {
                  const color = entry.name === 'expected' ? '#9ECDE4' : '#0089CE';
                  return <Cell key={`cell-${index}`} fill={color}/>;
                })
              }
              <LabelList
                dataKey="percentage"
                position="left"
                content={(props) =>
                  renderCustomBarLabel({ props })
                }
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{flex:'3', 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'start', alignItems:'center', gap: '8px'}}>
        <div className='details_date'>Most recent: {review.last_play_to_handicap_count} rounds / {calculateDaysAgo(review.last_play_to_handicap_date)} days ago</div>
      </div>
    </div>
  )
}

function generalRoundsDetails(review)
{
  return(
    <div className='handicap_review_details_main_card' style={{'--width': '403px', '--height': '253px', display:'flex', flexDirection:'column', gap:'7px', paddingLeft:'30px', paddingRight:'30px', paddingTop:'20px', paddingBottom:'20px'}}>
      <div className='main_card_section_title' style={{flex:'1'}}>General Rounds</div>
      <div style={{flex:'4', display:'flex', flexDirection:'row', gap:'5px'}}>
        <div className='small_card' style={{'--width':'84px', '--height':'60px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.general_play_count ?? '-'}</div>
          <div className='small_card_details'>Total</div>
        </div>
        <div className='small_card' style={{'--width':'84px', '--height':'60px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.general_play_mean?.toFixed(1) ?? '-'}</div>
          <div className='small_card_details'>Avg. Diff</div>
        </div>
      </div>
      <div className='main_card_section_title' style={{flex:'1'}}>Competition Rounds</div>
      <div style={{flex:'4', display:'flex', flexDirection:'row', gap:'5px'}}>
        <div className='small_card' style={{'--width':'84px', '--height':'60px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.competition_play_count ?? '-'}</div>
          <div className='small_card_details'>Total</div>
        </div>
        <div className='small_card' style={{'--width':'84px', '--height':'60px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div className='small_card_number'>{review.competition_play_mean?.toFixed(1) ?? '-'}</div>
          <div className='small_card_details'>Avg. Diff</div>
        </div>
        <div className='small_card' style={{'--width':'84px', '--height':'60px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <Fragment>
            <div className='small_card_number'>{review.competition_factor?.toFixed(1) ?? '-'}
              <span onClick={(e) => {e.preventDefault()}} data-tip data-for='competition_factor_info'>
                <i className="material-icons-outlined v-mid m-left small">help</i>
              </span>
            </div>
            <ReactTooltip 
              id='competition_factor_info' 
              className="table-head__tooltip" 
              type='dark'
              place='top' 
              effect={'float'}
              overridePosition={({ left, _ }) => ({ top: 920, left })}
            >
              <div>
                <div className='tooltip_text'>Indicates the average difference between how the player performs in casual rounds versus rounds posted as competition scores.</div>
                <br/>
                <div className='tooltip_text'>(+) means the player performs better in casual rounds</div>
                <div className='tooltip_text'>(–) means the player performs better in competition rounds</div>
              </div>
            </ReactTooltip>
          </Fragment>
          <div className='small_card_details'>Comp. Factor</div>
        </div>
      </div>
    </div>
  )
}

export default function HandicapReviewDetailsModal(props) {
  const columnsReviewScores = [
    {
      Header: 'Type',
      accessor: 'score_type_display_full',
      className: 'table-align-center',
      thClassName: 'table-align-center is-1-of-14',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Date Played',
      accessor: 'played_at',
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-14',
      highlightColumn: true,
      highlightConditionKey: 'flag_type',
      Cell: props => moment(props.original.played_at).format('MM/DD/YYYY')
    },
    {
      Header: 'Score',
      accessor: 'adjusted_gross_score',
      Cell: props => 
      <Fragment>
        {props.original.adjusted_gross_score} {props.original.scaled_up_differential !== null && props.original.number_of_holes !== 9 && <a className='score-number_of_played_holes'>{" ("}{props.original.number_of_played_holes}{")"}</a>}
      </Fragment>,
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-14',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'C.R./Slope',
      accessor: 'course_and_slope_rating',
      Cell: props => <Fragment>{parseFloat(props.original.course_rating).toFixed(1)} / {props.original.slope_rating}</Fragment>,
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-14',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Diff.',
      Cell: props => props.original.scaled_up_differential === null ? parseFloat(props.original.differential).toFixed(1) : parseFloat(props.original.scaled_up_differential).toFixed(1),
      accessor: 'differential',
      className: 'table-align-center',
      thClassName: 'table-align-center is-1-of-14',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Course Name / Tee',
      accessor: 'name',
      thClassName: 'table-align-center is-3-of-14',
      Cell: props => <Fragment>{getCleanName(props.original)}</Fragment>,
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Date Posted',
      accessor: 'posted_at',
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-14',
      highlightColumn: true,
      highlightConditionKey: 'flag_type',
      Cell: props => moment(props.original.posted_at).format('MM/DD/YYYY')
    },
    {
      Header: 'Flagged',
      accessor: 'flag_type',
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-14',
      Cell: props => <div style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'width': '100%'}} className='bubble'> 
        {parseScoreFlagged(props.original)}
      </div>,
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
  ];

  const [reviewScores, setReviewScores] = useState([]);
  const [reviewScoresBeforePrint, setReviewScoresBeforePrint] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingScores, setLoadingScores] = useState(true)
  const [review, setReview] = useState(props.review);
  const [golfer, setGolfer] = useState(props.golfer);
  const [handicapHistory, setHandicapHistory] = useState(props.review.handicap_history);
  const [scoresOpen, setScoresOpen] = useState(false)
  const [scoresOpenBeforePrint, setScoresOpenBeforePrint] = useState(false)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [sorted, setSorted] = useState(null);

  // The PDF export is split into 2 pages
  const refPage1 = useRef();
  const refPage2 = useRef();

  useEffect(() => {
    setLoadingScores(true)
    let isMounted = true;

    let params = [];
    params['page'] = page + 1;
    params['per_page'] = perPage;

    if (sorted?.length > 0) {
			params['sorting_criteria'] = sorted[0].id;
			params['order'] = sorted[0].desc ? 'desc' : 'asc';
		}

    ScoreService.getHandicapReviewScores(review.id, params).then(res => {
      if (isMounted) {
        setReviewScores(res.scores)
        setReviewScoresBeforePrint(res.scores)
        setTotal(parseInt(res["total-count"], 10));
        setPerPage(parseInt(res["per-page"], 10));
        setPages(parseInt(res["total-pages"], 10));
        setLoadingScores(false)
      }
    }).catch(err => {
      console.error(err)
    });
  }, [page, perPage, sorted]);

  // Filter the score list to keep only flagged scores
  const filterScoreList = () => {
    setReviewScores(reviewScores.filter((score) => score.flag_type != null))
  }

  // Restore the score list to the state before printing
  const restoreScoresList = () => {
    setReviewScores(reviewScoresBeforePrint)
  }

  // Perform a series of actions BEFORE the print action
  const openScoresListBeforePrint = () => {
		return new Promise((resolve) => {
      filterScoreList(); // Keep only flagged scores
      setScoresOpenBeforePrint(scoresOpen) // Remember the collapsible list state before print
      console.log("Before: " + scoresOpenBeforePrint + " / " + scoresOpen)
			setScoresOpen(true); // Open the collapsible list
			setTimeout(() => {
				resolve();
			}, 0);
		});
	};

  // Perform a series of actions AFTER the print action
  const resetScoresListAfterPrint = () => {
		return new Promise((resolve) => {
      restoreScoresList(); // Restore the scores list to the state before the print
      console.log("After: " + scoresOpenBeforePrint)
			setScoresOpen(scoresOpenBeforePrint); // Restore the scores list collapse to the state before the print
			setTimeout(() => {
				resolve();
			}, 0);
		});
	};
  
  return (
    <Fragment>
      <div className="modal__container">
        <div className="modal__head">
          <div className="" style={{width: '100%'}}>
            <div className='handicap_review_modal_title'>
              <div className='handicap_review_modal_title_item'>Handicap Review Details</div>
              <div className='handicap_review_modal_title'>
                <button
                  className="modal__close"
                  onClick={() => props.closeModal()}>
                  <i className="material-icons-outlined">clear</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__body'>
          {loading ?
            <table style={{width: '100%'}}>
              <tbody>
              <tr>
                <td style={{textAlign: 'center'}}>
                  <span className="data-table__loader"></span>
                </td>
              </tr>
              </tbody>
            </table> : (
            <div className='' style={{display: 'flex', flexDirection: 'column', alignContent: 'space-around'}}>
              <div className="printable" style={{display:'flex', flexDirection:'column', gap:'16px'}} ref={refPage1}>
                {golferDetails(golfer, review)}
                {reviewStatusDetails(review, setLoading, props)}
                {review.can_show_details && <Fragment>
                  {scoresInReviewDetails(review)}
                  {factorsImpactingRecommendation(review, handicapHistory)}
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    {playedToHIDetails(review)}
                    {generalRoundsDetails(review)}
                  </div>
                </Fragment>}
              </div>
              {review.can_show_details && <Fragment>
              {/* <div className='printable' style={{"paddingTop": "0"}} ref={refPage2}> */}
                <div className='handicap_review_details_main_card' style={{'--width': '832px', '--height': scoresOpen ? '100%' : '64px', marginTop: '16px'}}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginBottom:'21px'}}>
                    <div className='main_card_section_title'>Scores in Review</div>
                    <label style={{cursor: "pointer"}} onClick={
                      () => {
                        setScoresOpen(!scoresOpen);
                        setSorted(scoresOpen ? sorted : null);
                      }
                    }>
                      <span className='material-icons-outlined collapse-arrow' style={{ top: '6px'}}>
                        {scoresOpen ? 'expand_less' : 'expand_more'}
                      </span>
                    </label>
                  </div>
                  <Collapse isOpened={scoresOpen}>
                    <GhinTable
                      loading={loadingScores}
                      columns={columnsReviewScores}
                      hideSelect
                      data={reviewScores}
                      pages={pages}
                      page={page}
                      total={total}
                      pageSize={perPage}
                      manual
                      onSortedChange={(newSorted, column, shiftKey) => setSorted(newSorted)}
                      onPageChange={(index) => setPage(index)}
                      onPageSizeChange={(size, index) => {
                        setPerPage(size);
                        setPage(index);
                      }}
                    />
                  </Collapse>
                </div>
                {/* <div data-html2canvas-ignore={true}>
                  <ExportToPdfButton
                    title={`Handicap Review Details - ${golferName(golfer)}`}
                    componentRefs={[refPage1, refPage2]}
                    scale={2}
                    beforePrint={openScoresListBeforePrint}
                    afterPrint={resetScoresListAfterPrint}
                  />
                </div> */}
              {/* </div> */}
              </Fragment>}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
