import React, { Component, Fragment } from 'react';
import FullResultsTable from './FullResults/index'
import HistoryTable from './History/index';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class HandicapReview extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/history`} />
                    <Route
                        path={`${this.props.match.path}/history`}
                        render={(routeProps) => (
                            <HistoryTable
                            {...{ ...this.props, ...routeProps }} 
                            />
                        )}
                    />
                    <Route
                        path={`${this.props.match.path}/full-results/:handicap_review_history_id`}
                        render={(routeProps) => (
                            <FullResultsTable
                            {...{ ...this.props, ...routeProps }} 
                            />
                        )}
                    />
                </Switch>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user_type: state.user.access.user_type
    };
}

export default connect(mapStateToProps, null)(withRouter(HandicapReview));
