import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const pxTomm = (px) => Math.ceil((px * 25.4) / 96);

export default function ExportToPdfButton({ componentRefs, scale = 1, title = "Document", beforePrint, afterPrint }) {
  const canvasesRef = useRef([]);
  const maxDimensionsRef = useRef({ maxW: 0, maxH: 0 });
  const [isExporting, setIsExporting] = useState(false);

  const exportToPdf = async () => {
	try {
	  setIsExporting(true);
	  canvasesRef.current = []; // Reset canvases for a new export
	  maxDimensionsRef.current = { maxW: 0, maxH: 0 };
  
	  // Generate canvases for each component reference
	  for (const ref of componentRefs) {
		// Retrieve the component from the input ref
		const component = ref.current;
		if (!component) continue;
  
		const canvas = await html2canvas(component, {
		  // scale: the scale to use for rendering,
		  // defaults to the browsers device pixel ratio.
		  // When set, it will ensures the good quality of the screenshot and
		  // also that the browser zoom level will not affect the screenshot size
		  scale: scale,
		  // onclone: callback function which is called when the component
	      // has been cloned for rendering. Used here in order to change the
		  // cloned component's font family.
		  onclone: (clonedComponent) => {
			const clonedDiv = clonedComponent.getElementsByClassName(component.className)[0];
			if (clonedDiv) clonedDiv.style.fontFamily = 'Arial, sans-serif';
		  },
		});
  
		// Retrieve the image's width and height
	    // and convert them from pixels to millimeters
		const imgWidth = pxTomm(canvas.width);
		const imgHeight = pxTomm(canvas.height);
  
		maxDimensionsRef.current.maxW = Math.max(maxDimensionsRef.current.maxW, imgWidth);
		maxDimensionsRef.current.maxH = Math.max(maxDimensionsRef.current.maxH, imgHeight);
  
		// Save the generated canvas
		canvasesRef.current.push(canvas);
	  }
  
	  const { maxW, maxH } = maxDimensionsRef.current;
	  // Set the default PDF based on page size.
	  // This will prevent image cutting.
	  const orientation = maxH > 700 ? 'p' : 'l';

	  // Create a new PDF file using the jsPDF library
	  const pdf = new jsPDF(orientation, 'mm', [maxW, maxH]);
  
	  // Set the PDF title
	  pdf.setProperties({ title });
  
	  // Add each canvas to a new PDF page
	  canvasesRef.current.forEach((canvas, index) => {
		const imgWidth = pxTomm(canvas.width);
		const imgHeight = pxTomm(canvas.height);
		const dataImage = canvas.toDataURL('image/jpeg');
  
		if (index > 0) pdf.addPage(); // Add a new page for every image after the first one
		pdf.addImage(dataImage, 'JPEG', 0, 0, imgWidth, imgHeight);
	  });
  
	  // Open PDF in a new tab
	  window.open(pdf.output('bloburl'), '_blank');
	} catch (error) {
	  console.error('Error exporting to PDF:', error);
	} finally {
	  setIsExporting(false);
	}
  };
  

  return (
    <button
      id="exportButton"
      style={{ width: 'auto' }}
      className="btn fill green"
      onClick={async () => {
        try {
		  // Execute all callbacks in order
          if (beforePrint) await beforePrint();
          await exportToPdf();
          if (afterPrint) await afterPrint();
        } catch (error) {
          console.error('Error during export:', error);
        }
      }}
	  // Disable the button while exporting the PDF file to prevent
	  // opening multiple pages
      disabled={isExporting}
    >
      {isExporting ? 'Exporting...' : 'Export to PDF'}
    </button>
  );
}
