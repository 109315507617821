import React, {Component, Fragment} from 'react';
import {Picky} from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import Select from 'react-select';

export default class GhinFilterGroupedSelect extends Component {
    handleSelectAll = () => {
        const { value, onChange } = this.props;
        
        let allValues = this.props.options.map((group) => 
            group.items
            .filter((item) => item.value !== 'select_all')
            .map((item) => {return { label: item.label, value: item.value }})
        )
        .flat();

        if(value.length === allValues.length)
        {
            onChange([]);
            return;
        }
        else
        {
            onChange(allValues);
            return;
        }
    }

    isSelectAll = () => {
        const { value } = this.props;
        
        let allValues = this.props.options.map((group) => 
            group.items
            .filter((item) => item.value !== 'select_all')
            .map((item) => item.value)
        )
        .flat();

        return value.length === allValues.length;
    }

    handleGroupSelect = (groupItems) => {
        const { value, onChange } = this.props;

        if(groupItems.map((item) => item.value).includes('select_all'))
        {
            this.handleSelectAll()
            return
        }

        const allSelected = groupItems.every(item => value.map(val => val.value).includes(item.value))

        const newValue = allSelected
            ? value.filter((v) => !groupItems.map(item => item.value).includes(v.value))
            : [...new Set([...value, ...groupItems.map((item) => { return { label: item.label, value: item.value }})])];

        onChange(newValue);
    };

    handleOptionChange = (optionValue, isSelected) => {
        const { value, onChange } = this.props;
        const newValue = isSelected
            ? [...value, optionValue]
            : value.filter((val) => val.value !== optionValue.value);

        onChange(newValue);
    };

    areAllOptionsSelected = (groupItems) => {
        const { value } = this.props;
    
        if (groupItems.some(item => item.value === 'select_all')) {
            return this.isSelectAll();
        }
    
        return groupItems.every(item => 
            value.some(val => val.value === item.value)
        );
    };
    
    renderGroupedOptions = ({ style, isSelected, item, selectValue, labelKey, valueKey, multiple }) => {
        let groupName = item.group
        let groupItems = item.items

        const { value } = this.props;

        return(
            <div key={`group-${groupName}`} style={{paddingLeft:'5px', display:'flex', flexDirection:'column', rowGap:'0', borderBottom: "1px solid #CCCCCC"}}>
                <input
                    type="checkbox"
                    disabled={false}
                    checked={this.areAllOptionsSelected(groupItems)}
                    onChange={(e) =>
                        this.handleGroupSelect(groupItems)
                    }
                    name={`checkbox-${groupName}`}
                    id={`checkbox-${groupName}`}
                />
                <label
                    style={{color: 'black'}}
                    htmlFor={`checkbox-${groupName}`}
                >
                    {groupName}
                </label>
                {groupItems.map((item) => (
                    !item.hidden &&
                    <div key={`item-${item.label}`} style={{paddingLeft:'35px'}}>
                        <input
                            type="checkbox"
                            disabled={false}
                            checked={value.map(val => val.value).includes(item.value)}
                            onChange={(e) =>
                                this.handleOptionChange({ label: item.label, value: item.value }, e.target.checked)
                            }
                            name={`checkbox-${groupName}-${item.label}`}
                            id={`checkbox-${groupName}-${item.label}`}
                        />
                        <label
                            style={{color: 'black'}}
                            htmlFor={`checkbox-${groupName}-${item.label}`}
                        >
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
        )
    };

    getOptionsForSingleChoice = () => {
        return this.props.options.map((group) => 
            group.items
            .filter((item) => item.value !== 'select_all')
            .map((item) => {return {label: item.label, value: item.value}})
        )
        .flat();
    }

    render() {
        const {hasSelectAll, options, value, onChange, label, singleChoice, disabled, isSearchable, hideSelectAllOption, numberDisplayed, includeFilter} = this.props;

        if(hasSelectAll)
        {
            // Use 'unshift' to add Select All at the beginning
            options.unshift({
                group: 'Select All',
                items: [
                    { label: 'Select all', value: 'select_all', hidden: true },
                ]
            })
        }

        let computedOptions = options.map((item) => {
            if (typeof item === 'string') {
                return {
                label: item,
                value: item,
                };
            } else if (item.group && Array.isArray(item.items)) {
                return item;
            } else {
                return item;
            }
        });

        if (singleChoice) {
            computedOptions = this.getOptionsForSingleChoice();

            return (
                <Fragment>
                    <label>{label}</label>
                    <Select
                        value={value}
                        onChange={onChange}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={
                            hideSelectAllOption ?
								computedOptions
								:
								[{label: "Select All", value: undefined}, ...computedOptions]

                        }
						isDisabled={disabled}
                        isSearchable={isSearchable === true}
                        isMulti={false}
                    />
                </Fragment>
            );
        }


        return (
           <Fragment>
               <label>{label}</label>
                <Picky
                    numberDisplayed={numberDisplayed}
                    className={this.props.widthAuto === false ? 'width__100' : ''}
                    options={computedOptions}
                    value={value || []}
                    multiple={true}
                    includeSelectAll={false}
                    includeFilter={includeFilter ? includeFilter : false}
                    onChange={onChange}
                    dropdownHeight={600}
                    disabled={disabled}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select..."
                    render={
                        (
                            { style, isSelected, item, selectValue, labelKey, valueKey, multiple }) => this.renderGroupedOptions(
                            { style, isSelected, item, selectValue, labelKey, valueKey, multiple }
                        )
                    }
                />
           </Fragment>
        );
    }
}
