import React, { Component, Fragment } from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css';
import debounce from "debounce-promise";
import GhinFilterGroupedSelect from '../../../shared/GhinFilterGroupedSelect';

export default class TableFilter extends Component {
	defaultValues = { name: "", gender: "", status: [] };
	clearValues = { name: "", status: [], gender: "" };

	constructor(props) {
		super(props);

		this.state = { ...this.defaultValues };
		this.setFilters = debounce(this.setFiltersD, 1000);
	}

	updateFilter = (name, value) => {
		this.setState({ [name]: value }, () => this.sendFilters(name === "merged" ? "merged" : null));
	};

	setFiltersD(filteredArray) {
		this.props.updateFiltered(filteredArray);
	}

	sendFilters() {
		let filteredArray = [];

		Object.keys(this.state).map((key) => {
			filteredArray.push({
				id: key,
				value: this.state[key]
			})

			return null;
		});

		this.setFilters(filteredArray);
	}

	resetFilters() {
		this.setState({ ...this.defaultValues }, () => this.sendFilters());
	}

	render() {
		if (this.props.hideFilters) return null;
		return (
			<Fragment>
				<div className="filters">
					<div className="row">
						<div className="filters__inputs col">
							<div className="row">
								<div className={'col'}>
									<div className="row">
										<div className="col is-3-of-20">
											<label htmlFor="golfer-name">Golfer Name</label>
											<input type="text"
												autoComplete="off"
												value={this.state.name}
												name="golfer-name"
												id="golfer-name"
												disabled={this.state.isGroupFiltered}
												onChange={(e) => {
													this.updateFilter('name', e.target.value)
												}} />
										</div>
										<div className="col is-3-of-20">
											<GhinFilterSelect
												singleChoice={true}
												options={[{ label: 'Male', value: "M" }, { label: 'Female', value: "F" }]}
												value={this.state.gender}
												disabled={this.state.isGroupFiltered}
												onChange={value => this.updateFilter('gender', value)}
												label={'Gender'}
											/>
										</div>
										<div className="col is-3-of-20">
											<GhinFilterGroupedSelect
												singleChoice={false} // The single choice option does not support grouping
												hasSelectAll={true}
												options={
													[									
														{
															group: 'Adjustment Recommended',
															items: [
																{ label: 'Applied', value: 'applied', hidden: false },
																{ label: 'Denied', value: 'denied', hidden: false },
																{ label: 'Expired', value: 'expired', hidden: false },
																{ label: 'Pending', value: 'pending', hidden: false },
															],
														},
														{
															group: 'No Adjustment Recommended',
															items: [
																{ label: 'No Adjustment Recommended', value: 'no_adjustment', hidden: true },
															],
														},
														{
															group: 'Ineligible',
															items: [
																{ label: '< 20 Scores', value: 'not_enough_scores', hidden: false },
																{ label: 'Not Home Club', value: 'not_home_club', hidden: false },
																{ label: '< 3 Scores (NH)', value: 'NH', hidden: false },
																{ label: 'Withdrawn H.I.', value: 'WD', hidden: false },
																{ label: 'Existing Pending Review', value: 'unapplied_review', hidden: false },
																{ label: 'Existing Modified Golfer', value: 'modified_hi', hidden: false },
															],
														},
													]
												}
												value={this.state.status}
												disabled={this.state.isGroupFiltered}
												onChange={value => this.updateFilter('status', value)}
												label={'Status'}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="filters__controls col is-1-of-14">
							<button className="btn fill gray top" onClick={() => this.resetFilters()}>Reset</button>
						</div>
					</div>
				</div>

			</Fragment>
		);
	}
}
